import { PAGINATED_OBJECTS_SUCCESS, SEARCH_SUCCESS, CLEAR_OBJECTS } from "./types"
import { CALL_API } from "middleware/api"
import { apiCall } from "utils/rawApi"

const getLocations = (page=1, search) => {

  let options, params = {}

  if(page) params = { ...params, page: page }
  if(search) params = { ...params, q: search}
  options = { ...options, params: params}

  const successType = search && page === 1 ? SEARCH_SUCCESS : PAGINATED_OBJECTS_SUCCESS

  return {
    [CALL_API]: {
      endpoint: "/locations",
      method: "get",
      options,
      successType
    },
  }
}

const checkLocation = (locationId, time) => {

  const options = {}
  options.data = { time: time }

  return dispatch => {
    return apiCall("post", `/locations/${locationId}/check`, options).then(response => {
      return response
    }).catch(error => {
      throw error
    })
  }
}

const clearLocations = () => {
  return dispatch => {
    dispatch({ type: CLEAR_OBJECTS })
  }
}

export { getLocations, checkLocation, clearLocations }
