import { CALL_API } from "middleware/api"

const getTechnicianProfile = technicianProfileId => ({
  [CALL_API]: {
    endpoint: `/technician_profiles/${technicianProfileId}`,
    method: "get",
  },
})

export { getTechnicianProfile }
