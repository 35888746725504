import React from "react"
import PropTypes from "prop-types"

import Modal from "components/Modal"
import Button from "components/forms/Button"
import TextInput from "components/forms/TextInput";
import styles from "./Notes.module.scss"

class NotesModal extends React.Component {
  constructor(props) {
    super(props)
    const { account } = props
    this.state = { notes: account.notes || "" }
  }

  onSubmit = () => {
    const { updateAccount, handleClose } = this.props
    const { notes } = this.state
    updateAccount({ notes }).then(handleClose)
  }

  render() {
    const { handleClose } = this.props
    return (
      <Modal
        className={styles.modal}
        title="Edit Account Notes"
        handleClose={handleClose}
      >
        <div className={styles.notesModal}>
          <TextInput
            textarea
            className={styles.textArea}
            placeholder="Account notes"
            value={this.state.notes}
            onChange={e => this.setState({ notes: e.target.value })}
            rows={6}
          />
          <Button
            type="submit"
            className="btn--block btn--large"
            onClick={this.onSubmit}
          >
            Update Notes
          </Button>
        </div>
      </Modal>
    )
  }
}

NotesModal.propTypes = {
  account: PropTypes.shape({ notes: PropTypes.string }).isRequired,
  handleClose: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

export default NotesModal
