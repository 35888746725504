import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import classNames from "classnames/bind"

import { signoutUser } from "actions"
import Header from "components/Header"
import Content from "components/Content"
import Button from "components/forms/Button"

import styles from "./Profile.module.scss"
const cx = classNames.bind(styles)

class ProfileRoute extends Component {
  handleLogout = () => {
    this.props.signoutUser()
  }

  render = () => (
    <>
      <Header>
        <h1>Profile</h1>
        <div className={styles.headerButtons}>
          <Button kind="primary" onClick={this.handleLogout}>
            Log Out
            <i className={cx("material-icons", styles.icon)}>exit_to_app</i>
          </Button>
        </div>
      </Header>

      <Content>
      </Content>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ signoutUser }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(ProfileRoute)
