import React from "react"
import moment from "moment"
import { fullName } from "utils/users"
import "react-table/react-table.css"
import { Badge, COLORS } from "components/Badge"
import Table from "components/Table"
import "./List.scss"

const UsersList = ({ users, loading, handleRowClick }) => {

  const columns = [{
    id: "userName",
    Header: <span>Name</span>,
    accessor: d => fullName(d),
  }, {
    Header: <span>Email</span>,
    accessor: "email"
  }, {
    Header: <span>Role</span>,
    accessor: "role",
    Cell: ({ row, original }) => {
      return (
        <>
          <span className="content">{original.role}</span>
          {original.discardedAt
            ? <Badge color={COLORS.red}>Deactivated</Badge>
            : null
          }
        </>
      )
    },
    className: "role"
  }, {
    id: "lastLogin",
    Header: <span>Last Login</span>,
    Cell: ({ row, original }) => {
      return (
        original.lastSignInAt
          ? moment(original.lastSignInAt).format("L LTS")
          : "--"
      )
    },
    className: "lastLogin",
    maxWidth: 200,
  }]

  return (
    <Table
      data={users}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
    />
  )
}

export default UsersList
