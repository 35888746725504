import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./Button.scss"

const Button = ({
  children,
  className,
  disabled,
  small,
  large,
  kind,
  href,
  tabIndex,
  type,
  ...other
}) => {
  const buttonClasses = classNames(className, {
    [`btn`]: true,
    [`btn--sm`]: small,
    [`btn--large`]: large,
    [`btn--success`]: kind === "success",
    [`btn--primary`]: kind === "primary",
    [`btn--danger`]: kind === "danger",
    [`btn--link`]: kind === "link",
    [`btn--gray`]: kind === "gray",
  })

  const commonProps = {
    tabIndex,
    className: buttonClasses,
  }

  let component = "button"
  let otherProps = {
    disabled,
    type,
  }

  return React.createElement(
    component,
    {
      ...other,
      ...commonProps,
      ...otherProps,
    },
    children,
  )
}

Button.propTypes = {
  /**
   * Specify the content of your Button
   */
  children: PropTypes.node,

  /**
   * Specify an optional className to be added to your Button
   */
  className: PropTypes.string,

  /**
   * Specify whether the Button should be disabled, or not
   */
  disabled: PropTypes.bool,

  /**
   * Specify whether the Button should be a small variant
   */
  small: PropTypes.bool,

  /**
   * Optionally specify an href for your Button to become an <a> element
   */
  href: PropTypes.string,

  /**
   * Optional prop to specify the tabIndex of the Button
   */
  tabIndex: PropTypes.number,

  /**
   * Optional prop to specify the type of the Button
   */
  type: PropTypes.oneOf(["button", "reset", "submit"]),
}

Button.defaultProps = {
  tabIndex: 0,
  type: "button",
  disabled: false,
  small: false,
  kind: "primary",
}

export default Button
