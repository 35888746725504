import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import { generatePath } from "react-router"
import { withRouter } from "react-router-dom"
import { Calendar, momentLocalizer } from "react-big-calendar"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import queue from "utils/snackbarQueue"
import moment from "moment"
import pick from "lodash/pick"

import {
  getCalendarAppointments,
  getCalendarTimeBlocks,
  getTechnicians,
  checkLocation,
  rescheduleAppointment,
  optimisticUpdateAppointment,
  setSelectedTechnicians,
  setOptimizeDate,
  setOnDayView,
} from "actions"
import TechnicianFilter from "./calendar/TechnicianFilter"
import Event from "./calendar/Event"
import EventWrapper from "./calendar/EventWrapper"
import ResourceHeader from "./calendar/ResourceHeader"
import CalendarContext from "./calendar/Context"
import SubNav from "components/SubNav"
import Content from "components/Content"
import classNames from "classnames"
import "react-big-calendar/lib/sass/styles.scss"
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss"
import "./Calendar.scss"

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const dateFormat = "YYYY-MM-DD"

class AppointmentCalendar extends Component {
  static contextType = CalendarContext

  constructor(props) {
    super(props)
    this.state = {
      currentView: "day",
      currentDate: moment().format(dateFormat),
      calendarStart: moment().format(dateFormat),
    }
  }

  componentDidMount() {
    const date = this.getDateFromParams()
    this.setState({ currentDate: date, calendarStart: date }, () => this.fetchEvents())
    this.props.setOptimizeDate(date)
    this.props.setOnDayView(true)
    this.props.getTechnicians()
    this.selectAllTechnicians()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.technicians.length !== this.props.technicians.length){
      this.selectAllTechnicians()
    }
  }

  getDateFromParams = () => {
    const params = this.props.match.params
    if(params.year && params.month && params.day) {
      const date = `${params.year}-${("0" + params.month).slice(-2)}-${("0" + params.day).slice(-2)}`
      return moment(date).format(dateFormat)
    }
    return moment().format(dateFormat)
  }

  fetchEvents = () => {
    const { calendarStart, calendarEnd } = this.state
    this.props.getCalendarAppointments(calendarStart, calendarEnd)
    this.props.getCalendarTimeBlocks(calendarStart, calendarEnd)
  }

  selectAllTechnicians = () => {
    const { setSelectedTechnicians } = this.props
    const technicians = this.props.technicians.map(t => String(t.id))
    setSelectedTechnicians(technicians)
  }

  handleTechnicianSelect = (checked, id, event) => {
    const { selectedTechnicians, setSelectedTechnicians } = this.props
    const technicianId = event.target.value
    let newSelectedTechnicians
    if (checked) {
      newSelectedTechnicians = [...selectedTechnicians, technicianId]
    } else {
      newSelectedTechnicians = selectedTechnicians.filter(
        technician => technicianId !== technician
      )
    }

    setSelectedTechnicians(newSelectedTechnicians)
  }

  serializerAppointments = appointments => {
    appointments.forEach(a => {
      a["start"] = new Date(a.windowStart)
      a["end"] = new Date(a.windowEnd)
      a["resourceId"] = String(a.resourceId)
    })

    // white list
    return appointments.map(a => pick(a, ["id", "start", "end", "title", "resourceId", "color", "fixed"]))
  }

  serializeTimeBlocks = timeBlocks => {
    let blocks = []
    timeBlocks.forEach(tb => {
      tb.technicianIds.forEach(resourceId => {
        blocks.push({
          id: tb.id,
          title: tb.title,
          start: new Date(tb.startsAt),
          end: new Date(tb.endsAt),
          resourceId: String(resourceId),
          block: true
        })
      })
    })

    // white list
    return blocks.map(a => pick(a, ["id", "start", "end", "title", "resourceId", "color", "block"]))
  }

  handleNagivate = date => {
    const { setOptimizeDate } = this.props
    const momentDate = moment(date)
    const year = momentDate.format("YYYY")
    const month = momentDate.format("M")
    const day = momentDate.format("D")
    this.props.history.push({
      pathname: generatePath(this.props.match.path, {year: year, month: month, day: day})
    })
    const formattedDate = momentDate.format("YYYY-MM-DD")
    this.setState({ currentDate: formattedDate })
    setOptimizeDate(formattedDate)
  }

  hanldeSelectSlot = info => {
    const { history } = this.props
    const { setClicked } = this.context
    setClicked(info)
    history.push("/appointments/new", { modal: true })
  }

  handleViewChange = view => {
    const { setOnDayView } = this.props
    this.setState({ currentView: view })
    setOnDayView(view === "day")
  }

  handleRangeChange = range => {
    let calendarStart, calendarEnd
    if(Array.isArray(range)){
      calendarStart = range[0]
      if(range.length === 1) {
        calendarEnd = calendarStart
      } else {
        calendarEnd = range[range.length - 1]
      }
    } else {
      calendarStart = range.start
      calendarEnd = range.end
    }

    this.setState({
      calendarStart: moment(calendarStart).format(dateFormat),
      calendarEnd: moment(calendarEnd).format(dateFormat) }, () => {
      this.fetchEvents()
    })
  }

  /** @returns {Boolean} true if within preferred times */
  checkLocationRestrictions = async (locationId, newStart) => (
    this.props.checkLocation(locationId, newStart)
      .then(response => {
        // response.data === true if within preferred times
        return response.data ? true : window.confirm(
          "The selected time falls outside of the preferred slots " +
          "for this location. Are you sure you want to reschedule " +
          "the appointment?"
        )
      })
      .catch(err => console.error(
        `Error checking restrictions for location #${locationId}`, err
      ))
  )

  handleMove = ({ event, start, end, allDay }) => {
    if(!event.block) {
      // get original appointment
      const appointment = this.props.appointments.find(a => a.id === event.id)
      const newAppointment = { ...appointment }
      const appointmentId = appointment.id
      const calculatedStart = moment(start).format()
      const calculatedEnd = moment(start).add(appointment.estimatedDuration, "minutes").format()
      newAppointment.windowStart = calculatedStart
      newAppointment.windowEnd = calculatedEnd

      this.props.optimisticUpdateAppointment(appointmentId, newAppointment)

      this.checkLocationRestrictions(appointment.locationId, calculatedStart)
        .then(confirmed => {
          if (confirmed) {
            this.props.rescheduleAppointment(appointmentId, calculatedStart)
              .then(() => {
                queue.notify({
                  body: "Appointment rescheduled",
                  timeout: 3000,
                  leading: true,
                })
              })
          } else {
            // reset to original
            this.props.optimisticUpdateAppointment(appointmentId, appointment)
          }
        }).catch(() => {
          // reset to original
          this.props.optimisticUpdateAppointment(appointmentId, appointment)
          queue.notify({
            body: "Appointment cannot be rescheduled to that time",
            timeout: 3000,
            leading: true,
          })
        })
    }
  }

  render() {
    const {
      appointments,
      timeBlocks,
      technicians,
      selectedTechnicians,
      setSelectedTechnicians,
      handleEventClick,
      subNavOptions,
      isOptimizing,
    } = this.props
    const { currentView, currentDate } = this.state

    // events
    const filteredAppointments = appointments.filter(a =>
      selectedTechnicians.includes(String(a.resourceId)) && a.state !== "cancelled"
    )

    // resources
    let techniciansMap = technicians.filter(technician => (
      selectedTechnicians.includes(technician.id)
    ))

    if(currentView !== "day") {
      techniciansMap = null
    }

    // big calendar
    const formats = {
      dayHeaderFormat: "dddd MMMM D, YYYY",
      timeGutterFormat: "h:mma",
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        localizer.format(start, "h:mm", culture) + ' — ' +
        localizer.format(end, "h:mma", culture)
    }

    // date
    const calendarDate = moment(currentDate).toDate()

    const serializedEvents = this.serializerAppointments(filteredAppointments).concat(this.serializeTimeBlocks(timeBlocks))
    return (
      <>
        <SubNav {...subNavOptions} />

        <Content>
          <div className={classNames("calendarWrapper", { isOptimizing })}>
            <TechnicianFilter
              technicians={technicians}
              selectedTechnicians={selectedTechnicians}
              handleClick={this.handleTechnicianSelect}
              handleSet={setSelectedTechnicians}
            />
            <DragAndDropCalendar
              localizer={localizer}
              defaultView="day"
              date={calendarDate}
              views={["day", "week", "month"]}
              min={moment('6:00am', 'h:mma').toDate()}
              step={15}
              timeslots={4}
              events={serializedEvents}
              resources={techniciansMap}
              resourceIdAccessor="id"
              resourceTitleAccessor={"firstName"}
              showMultiDayTimes={true}
              formats={formats}
              onSelectEvent={event => handleEventClick(event)}
              draggableAccessor={event => !event.block}
              selectable
              onSelectSlot={this.hanldeSelectSlot}
              onNavigate={this.handleNagivate}
              onView={this.handleViewChange}
              onRangeChange={this.handleRangeChange}
              onEventDrop={this.handleMove}
              resizableAccessor={() => false}
              components={{
                event: Event,
                eventWrapper: EventWrapper,
                resourceHeader: ResourceHeader,
              }}
            />
          </div>
        </Content>
      </>

    )
  }
}

AppointmentCalendar.propTypes = {
  appointments: PropTypes.array.isRequired,
  technicians: PropTypes.array.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  let appointments, timeBlocks, technicians, appointmentsLoading, timeBlocksLoading, techniciansLoading
  const techniciansUrl = `/technicians`
  if(state.data.meta[techniciansUrl]) {
    technicians = (state.data.meta[techniciansUrl].data || []).map(object => build(state.data, 'technician', object.id))
    techniciansLoading = state.data.meta[techniciansUrl].loading
  }

  const appointmentsUrl = `/appointments/calendar`
  if(state.data.meta[appointmentsUrl]) {
    appointments = (state.data.meta[appointmentsUrl].data || []).map(object => build(state.data, 'appointment', object.id))
    appointmentsLoading = state.data.meta[appointmentsUrl].loading
  }

  const timeBlocksUrl = `/time_blocks/calendar`
  if(state.data.meta[timeBlocksUrl]) {
    timeBlocks = (state.data.meta[timeBlocksUrl].data || []).map(object => build(state.data, 'timeBlock', object.id))
    timeBlocksLoading = state.data.meta[timeBlocksUrl].loading
  }

  return {
    appointments: appointments || [],
    timeBlocks: timeBlocks || [],
    technicians: technicians || [],
    techniciansLoading: techniciansLoading || true,
    appointmentsLoading : appointmentsLoading || true,
    timeBlocksLoading : timeBlocksLoading || true,
    isOptimizing: state.CSR_GLOBALS.isOptimizing,
    selectedTechnicians: state.CSR_GLOBALS.selectedTechnicians,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getCalendarAppointments,
      getCalendarTimeBlocks,
      getTechnicians,
      checkLocation,
      rescheduleAppointment,
      optimisticUpdateAppointment,
      setSelectedTechnicians,
      setOptimizeDate,
      setOnDayView,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppointmentCalendar))
