import { CALL_API } from "middleware/api"
import { UPDATE_OBJECT_SUCCESS } from "actions/types"

/**
 * @param data: {
 *  start_date,
 *  end_date,
 *  ( technician_id | commission_source_id | account_id )
 * }
 */
const getCommissionReports = data => ({
  [CALL_API]: {
    endpoint: "/commission_reports",
    method: "post",
    options: { data },
    successType: UPDATE_OBJECT_SUCCESS,
  },
})

export { getCommissionReports }
