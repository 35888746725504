import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import PropTypes from "prop-types"

import { createAccount, getOrganizations, getCommissionSources } from "actions"
import SideModal from "components/SideModal"
import NewAccountForm from "./NewForm"

class NewAccount extends React.Component {
  componentDidMount() {
    this.props.getOrganizations()
    this.props.getCommissionSources()
  }

  handleSubmit = values => {
    const { createAccount, openAccountDetails } = this.props

    const toSend = {
      hoa_name: values.hoa_name,
      organization_id: values.organization,
      account_contacts: [{
        contact: values.contact,
        billing: true,
        primary: true,
      }],
      account_locations: [{ location: values.billing, billing: true }],
      notes: values.notes,
      monthly_invoicing: values.monthlyInvoicing,
      lead_source_id: values.leadSourceId,
    }

    // Primary service address same as billing address?
    if (values.sameAddress) {
      toSend.account_locations.push({
        location: values.billing,
        primary: true,
      })
    } else {
      toSend.account_locations.push({
        location: values.serviceAddr,
        primary: true,
      })
    }

    return createAccount(toSend).then(res => {
      const newAccountId = res.response.meta["/accounts"].data[0].id
      openAccountDetails(newAccountId)
    })
  }

  render() {
    const { organizations, commissionSources, handleClose } = this.props
    return (
      <SideModal title="New Account" handleClose={handleClose}>
        <NewAccountForm
          handleSubmit={this.handleSubmit}
          organizations={organizations}
          commissionSources={commissionSources}
        />
      </SideModal>
    )
  }
}

NewAccount.propTypes = {
  handleClose: PropTypes.func.isRequired,
  openAccountDetails: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  let organizations = []
  let commissionSources = []
  let loading = true

  if (state.data.meta["/organizations"]) {
    organizations = (state.data.meta["/organizations"].data || [])
      .map(object => build(state.data, "organization", object.id))
    loading = state.data.meta["/organizations"].loading
  }

  if (state.data.meta["/commission_sources"]) {
    commissionSources = (state.data.meta["/commission_sources"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
    loading = state.data.meta["/commission_sources"].loading
  }

  // Add default option for "Misc / Accurate" (value = a blank string)
  commissionSources.unshift({
    id: "",
    sales: false,
    name: "Accurate",
    category: "Miscellaneous",
    type: "commissionSource",
  })

  return { organizations, commissionSources, loading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ createAccount, getOrganizations, getCommissionSources }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAccount)
