import React, { useEffect } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import "react-table/react-table.css"
import "components/Table.scss"

const Table = ({ data, columns, loading, handleRowClick, handleNextPage, defaultSorted, className }) => {

  useEffect(() => {
    if (typeof handleNextPage === "function") {
      const body = document.getElementsByClassName("rt-tbody")[0]

      const onScroll = () => {
        if(body.scrollTop + body.offsetHeight === body.scrollHeight) {
          handleNextPage()
        }
      }

      body.addEventListener("scroll", onScroll)
      return () => {
        body.removeEventListener("scroll", onScroll)
      }
    }
  }, [handleNextPage])

  const pageSize = data.length

  return (
    <ReactTable
      data={data}
      columns={columns}
      loading={loading}
      showPagination={false}
      pageSize={pageSize}
      defaultSorted={defaultSorted}
      className={className}
      getTrProps={(state, rowInfo, column, instance) => ({
        className: rowInfo && rowInfo.original.discardedAt ? "discarded" : null,
        onClick: e => handleRowClick(e, rowInfo)
      })}
    />
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleRowClick: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func,
}

export default Table
