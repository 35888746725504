import React from "react"
import build from "redux-object"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { FieldArray, Field, Form } from "formik"
import get from "lodash/get"

import { getProducts } from "actions"
import Button from "components/forms/Button"
import FormikSelect from "components/forms/FormikSelect"
import FormikTextInput from "components/forms/FormikTextInput"
import styles from "./EditProducts.module.scss"

class EditProducts extends React.Component {
  componentDidMount() {
    this.props.getProducts()
  }

  getProductUnit = productId => {
    const { products } = this.props
    const product = products.find(p => p.id === String(productId))
    return product ? product.unit : ""
  }

  render() {
    const {
      loading,
      products,
      values,
      errors,
      isSubmitting,
      handleRemoveExisting,
      ProductPlaceholder,
    } = this.props

    const productOptions = products.map(p => ({
      label: p.name,
      value: String(p.id),
    }))

    return (
      <Form className={styles.productsForm}>
        <FieldArray
          name="appointment_products"
          validateOnChange={false}
          render={({ push, remove }) => (
            <>
              {values.appointment_products.map((ap, index) => (
                <div
                  key={`${ap.product_id || Math.random()} + ${index}`}
                  className={styles.productRow}
                >
                  <Button
                    kind="link"
                    className={styles.removeProductButton}
                    onClick={() => {
                      // If this existed on the server already, we need to
                      // push it up again with { id, _destroy: true }
                      if (ap.id) handleRemoveExisting(ap.id)
                      remove(index)
                    }}
                    disabled={isSubmitting || loading}
                  >
                    <i className="material-icons">remove_circle</i>
                  </Button>
                  <Field
                    name={`appointment_products.${index}.product_id`}
                    placeholder="Select a product..."
                    component={FormikSelect}
                    className={styles.select}
                    options={productOptions}
                    invalid={typeof get(errors, `appointment_products.${index}.product_id`) !== "undefined"}
                    disabled={isSubmitting || loading}
                  />
                  <Field
                    component={FormikTextInput}
                    type="text"
                    name={`appointment_products.${index}.notes`}
                    label="Location Applied"
                    invalid={typeof get(errors, `appointment_products.${index}.notes`) !== "undefined"}
                    disabled={isSubmitting || loading}
                  />
                  <Field
                    component={FormikTextInput}
                    type="number"
                    name={`appointment_products.${index}.amount`}
                    label="Amount"
                    invalid={typeof get(errors, `appointment_products[${index}].amount`) !== "undefined"}
                    disabled={isSubmitting || loading}
                  />
                  <span>{this.getProductUnit(ap.product_id)}</span>
                </div>
              ))}
              <Button
                kind="link"
                className={styles.addProductButton}
                onClick={() => push(new ProductPlaceholder())}
                disabled={isSubmitting || loading}
              >
                Add Product
                <i className="material-icons">add</i>
              </Button>
            </>
          )}
        />
      </Form>
    )
  }
}

const mapStateToProps = state => {
  let loading = true
  let products = []

  if (state.data.meta["/products"]) {
    products = (state.data.meta["/products"].data || [])
      .map(object => build(state.data, "product", object.id))
    loading = state.data.meta["/products"].loading
  }

  return { products, loading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getProducts,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProducts)
