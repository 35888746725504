import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { resetPassword } from "actions/passwords"
import AuthLayout from "components/AuthLayout"
import Button from "components/forms/Button"
import Form from "components/forms/Form"
import FormItem from "components/forms/FormItem"
import TextInput from "components/forms/TextInput"
import ErrorMessage from "components/ErrorMessage"
import styles from "./ForgotPassword.module.scss"

class ForgotPasswordRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      reset: false,
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.resetPassword(this.state.email).then(() => {
      this.setState({ reset: true })
    })
  }

  onChange = e => {
    const target = e.target
    this.setState({
      [target.name]: target.value
    })
  }

  render() {

    const { errorMessage } = this.props
    const { email, reset } = this.state

    return (
      <AuthLayout>
        {errorMessage && <ErrorMessage text={errorMessage} />}
        <br />
        {reset
          ? <div className={styles.instructions}>
              <h3>Instructions Sent!</h3>
              <p>
                {`Instructions for resetting your password have been sent to `}
                <strong>{email}</strong>.
              </p>

              <p>
                You’ll receive this email within 5 minutes. Be sure to check
                your spam folder, too.
              </p>
            </div>
          : <Form onSubmit={this.handleSubmit}>
              <h3> Forgot Your Password?</h3>
              <FormItem>
                <TextInput
                  className={styles.input}
                  id="email"
                  name="email"
                  value={email}
                  type="text"
                  onChange={this.onChange}
                  label="Email"
                />
              </FormItem>
              <Button
                className="btn--block btn--large"
                type="submit"
                kind="primary"
                disabled={this.props.isLoading}
                large
              >
                Send Me Instructions
              </Button>
            </Form>
        }
       </AuthLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.passwords.isLoading,
    errorMessage: state.passwords.errorMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      resetPassword,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordRoute)
