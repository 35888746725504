import React, { Fragment } from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import Main from "components/Main"
import Sidebar from "components/Sidebar"

const PrivateRoute = ({ component: Component, authenticated, currentUser, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => (
      authenticated === true ?
      <Fragment>
        <Sidebar currentUser={currentUser} {...routeProps} />
        <Main>
          <Component {...routeProps} {...rest} />
        </Main>
      </Fragment>
      :
      <Redirect to={{
        pathname: "/login",
        state: { from: routeProps.location }
      }} />
    )} />
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    currentUser: state.currentUser && state.currentUser.data && state.currentUser.data.attributes
  }
}

export default connect(
  mapStateToProps,
  null
)(PrivateRoute)
