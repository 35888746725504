import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getAccounts, clearAccounts } from "actions"
import build from "redux-object"
import debounce from "lodash/debounce"

import Header from "components/Header"
import SubNav from "components/SubNav"
import SearchInput from "components/forms/SearchInput"
import Content from "components/Content"
import AccountsList from "components/accounts/List"
import NewAccount from "components/accounts/New"
import Button from "components/forms/Button"
import Icon from "components/Icon"

class CustomerAccountsRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      newModalOpen: false,
      searchString: "",
    }
  }

  componentDidMount() {
    this.props.clearAccounts()
    this.fetchAccounts()
  }

  fetchAccounts(page=1) {
    const { searchString } = this.state
    this.props.getAccounts(page, searchString).then(() => this.setState({ loading: false }))
  }

  handleSearchChange = e => {
    const value = e.target.value
    this.setState({ searchString: value }, () => {
      if(this.state.searchString.length === 0) {
        this.props.clearAccounts()
      }
      this.updateSearch()
    })
  }

  updateSearch = debounce(() => {
    this.fetchAccounts()
  }, 500)

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const accountId = rowInfo.original.id
    this.props.history.push(`/accounts/${accountId}`, { modal: true })
  }

  handleNextPage = () => {
    const { currentPage, totalPages } = this.props
    if(currentPage < totalPages) {
      const page = currentPage + 1
      this.fetchAccounts(page)
    }
  }

  openAccountDetails = accountId => {
    this.props.history.push(`/accounts/${accountId}`, { modal: true })
  }

  handleRowClick = (event, rowInfo) => {
    const accountId = rowInfo.original.id
    this.openAccountDetails(accountId)
  }

  closeModals = () => {
    this.setState({ newModalOpen: false })
  }

  render() {
    const { accounts, subNavOptions } = this.props
    const { loading, newModalOpen } = this.state

    return (
      <>
        <Header>
          <h1>Customers</h1>
          <Button
            kind="primary"
            onClick={() => this.setState({ newModalOpen: true })}
          >
            New Account
            <Icon name="plus" width="12" height="12" />
          </Button>
        </Header>

        <SubNav {...subNavOptions}>
          <SearchInput
            onChange={this.handleSearchChange}
            placeholder="Search Accounts"
          />
        </SubNav>

        <Content>
          <AccountsList
            accounts={accounts}
            loading={loading}
            handleRowClick={this.handleRowClick}
            handleNextPage={this.handleNextPage}
          />
        </Content>

        {newModalOpen &&
          <NewAccount
            openAccountDetails={accountId => {
              this.setState({ newModalOpen: false })
              this.openAccountDetails(accountId)
            }}
            handleClose={() => this.setState({ newModalOpen: false })}
          />
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const url = `/accounts`
  if(state.data.meta[url]) {
    const metaUrl = state.data.meta[url]
    const currentPage = metaUrl.meta && metaUrl.meta.currentPage
    const totalPages = metaUrl.meta && metaUrl.meta.totalPages
    const accounts = (metaUrl.data || []).map(object => build(state.data, 'account', object.id))
    return { accounts, currentPage, totalPages }
  }

  return { accounts: [] }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getAccounts,
      clearAccounts,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAccountsRoute)
