import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import "./FormItem.scss"

const FormItem = ({ className, withButton, children, ...other }) => {
  const classNames = classnames(`form-item`, className, {
    "with-button": withButton
  })

  return (
    <div className={classNames} {...other}>
      {children}
    </div>
  )
}

FormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default FormItem