import { CREATE_OBJECT_SUCCESS, PAGINATED_OBJECTS_SUCCESS, SEARCH_SUCCESS, CLEAR_OBJECTS } from "./types"
import { CALL_API } from "middleware/api"

const getAccounts = (page=1, search) => {

  let options, params = {}

  if(page) params = { ...params, page: page }
  if(search) params = { ...params, q: search}
  options = { ...options, params: params}

  const successType = search && page === 1 ? SEARCH_SUCCESS : PAGINATED_OBJECTS_SUCCESS

  return {
    [CALL_API]: {
      endpoint: "/accounts",
      method: "get",
      options,
      successType
    },
  }
}

const getAccount = accountId => ({
  [CALL_API]: {
    endpoint: `/accounts/${accountId}?include=account_contacts.contact,account_locations.location,recurring_services,appointments,appointments.appointment_services,lead_source,organization`,
    method: "get",
  },
})

const createAccount = data => {
  const options = {}
  options.data = { account: data }
  options.params = { include: "account_contacts.contact,account_locations.location" }

  return {
    [CALL_API]: {
      endpoint: "/accounts",
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateAccount = (accountId, data) => {
  const options = {}
  options.data = { account: data }
  options.params = { include: "account_contacts.contact,account_locations.location,lead_source" }

  return {
    [CALL_API]: {
      endpoint: `/accounts/${accountId}`,
      method: "patch",
      options,
    },
  }
}

const clearAccounts = () => dispatch => dispatch({
  type: CLEAR_OBJECTS,
  endpoint: "/accounts",
})

export { getAccounts, getAccount, createAccount, updateAccount, clearAccounts }
