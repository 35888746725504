import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@rmwc/theme"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./Modal.module.scss"
const cx = classNames.bind(styles)

const modalRoot = document.getElementById("modal-root")

const Modal = ({ title, className, children, handleClose }) => ReactDOM.createPortal(
  <ThemeProvider options={{ primary: "#3670D0", secondary: "#D82F22" }}>
    <div className={styles.overlay}>
      <div className={cx(styles.content, className)}>
        <header>
          <h3>{title}</h3>
          <div className={styles.closeButton} onClick={handleClose}>
            &times;
          </div>
        </header>
        { children }
      </div>
    </div>
  </ThemeProvider>,
  modalRoot
)

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default Modal
