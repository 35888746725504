import React from "react"
import moment from "moment"
import "react-table/react-table.css"

import Table from "components/Table"
import { formatCurrency } from "utils/format"

const ReportTable = ({
  reports,
  loading,
  handleRowClick,
  className,
  hiddenColumns
}) => {
  const columns = [{
    Header: <span>Customer</span>,
    columns: [
      {
        id: "serviceDate",
        Header: "Date",
        accessor: d => moment(d.serviceDate).format("YYYY-MM-DD"),
        width: 105,
      },
      {
        Header: "Account",
        accessor: "account",
      },
      {
        Header: "Location",
        accessor: "location",
      },
    ],
  }, {
    Header: <span>Service</span>,
    columns: [
      {
        Header: "Name",
        accessor: "service",
        show: !hiddenColumns.includes("service"),
      },
      {
        id: "serviceAmount",
        Header: "Amount",
        accessor: d => formatCurrency(d.serviceAmount / 100),
        width: 80,
        headerStyle: {
          textAlign: "right",
        },
        style: {
          textAlign: "right",
        },
      },
    ],
  }, {
    Header: <span>Lead Source</span>,
    columns: [
      {
        id: "leadName",
        Header: "Name",
        accessor: d => d.leadName || "None",
        show: !hiddenColumns.includes("leadName"),
      },
      {
        Header: "Expires",
        accessor: "leadExpires",
        width: 105,
      },
      {
        id: "leadCommission",
        Header: "Commission",
        accessor: d => formatCurrency(d.leadCommission / 100),
        width: 105,
        headerStyle: {
          textAlign: "right",
        },
        style: {
          textAlign: "right",
        },
      },
    ],
  }, {
    Header: <span>Sales Source</span>,
    columns: [
      {
        id: "salesName",
        Header: "Name",
        accessor: d => d.salesName || "None",
        show: !hiddenColumns.includes("salesName"),
      },
      {
        Header: "Expires",
        accessor: "salesExpires",
        width: 105,
      },
      {
        id: "salesCommission",
        Header: "Commission",
        accessor: d => formatCurrency(d.salesCommission / 100),
        width: 105,
        headerStyle: {
          textAlign: "right",
        },
        style: {
          textAlign: "right",
        },
      },
    ],
  }, {
    Header: <span>Production Source</span>,
    columns: [
      {
        Header: "Name",
        accessor: "productionName",
        show: !hiddenColumns.includes("productionName"),
      },
      {
        id: "productionCommission",
        Header: "Commission",
        accessor: d => formatCurrency(d.productionCommission / 100),
        show: !hiddenColumns.includes("productionCommission"),
        headerStyle: {
          textAlign: "right",
        },
        style: {
          textAlign: "right",
        },
      },
    ],
  }]

  return (
    <Table
      data={reports}
      columns={columns}
      loading={loading}
      className={className}
      handleRowClick={handleRowClick}
    />
  )
}

export default ReportTable
