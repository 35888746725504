import React, { useState } from "react"

import Modal from "components/Modal"
import Button from "components/forms/Button"
import TextInput from "components/forms/TextInput"

const CancelModal = ({ handleCancel, handleClose }) => {
  const [cancellationReason, setCancellationReason] = useState("")

  return (
    <Modal title="Cancel Appointment" handleClose={handleClose}>
      <TextInput
        textarea
        label="Cancellation reason (optional)"
        onChange={e => setCancellationReason(e.target.value)}
        value={cancellationReason}
        rows={4}
      />
      <Button
        kind="danger"
        style={{ marginTop: "10px" }}
        onClick={() => handleCancel(cancellationReason)}
      >
        Cancel Appointment
      </Button>
    </Modal>
  )
}

export default CancelModal
