import { SET_SELECTED_TECHNICIANS, SET_ON_DAY_VIEW } from "./types"

const setSelectedTechnicians = technicians => ({
  type: SET_SELECTED_TECHNICIANS,
  payload: technicians,
})

const setOnDayView = whether => ({
  type: SET_ON_DAY_VIEW,
  payload: whether,
})

export { setSelectedTechnicians, setOnDayView }
