import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { createOrganization } from "actions"
import Modal from "components/Modal"
import OrganizationForm from "./Form"

class NewOrganization extends React.Component {
  handleCreate = (values) => {
    const { createOrganization, handleClose } = this.props
    return createOrganization(values).then(handleClose)
  }

  render() {
    const { handleClose } = this.props
    return (
      <Modal title="New Organization" handleClose={handleClose}>
        <OrganizationForm
          handleSubmit={this.handleCreate}
          initialValues={{
            name: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipcode: ""
          }}
        />
      </Modal>
    )
  }
}

NewOrganization.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ createOrganization }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NewOrganization)
