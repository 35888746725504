import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { inviteUser } from "actions"
import Modal from "components/Modal"
import UserForm from "./Form"

class InviteUser extends React.Component {
  handleSubmit = (values) => {
    const { inviteUser, handleClose } = this.props
    return inviteUser(values).then(handleClose)
  }

  render() {
    const { handleClose } = this.props

    return (
      <Modal title="Invite New User" handleClose={handleClose}>
        <UserForm
          handleSubmit={this.handleSubmit}
          initialValues={{
            email: "",
            phone: "",
            first_name: "",
            last_name: "",
            role: "",
          }}
        />
      </Modal>
    )
  }
}

InviteUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ inviteUser }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(InviteUser)
