import React from "react"
import "react-table/react-table.css"
import Table from "components/Table"

const ProductsList = ({ products, loading, handleRowClick }) => {

  const formatServices = services => {
    if(!services) return ""
    return services.map(s => s.name).join(", ")
  }

  const columns = [{
    Header: <span>Name</span>,
    accessor: "name",
  }, {
    Header: <span>EPA Reg #</span>,
    accessor: "epaCode",
  }, {
    Header: <span>Manufacturer</span>,
    accessor: "company",
  }, {
    Header: <span>Unit</span>,
    accessor: "unit",
  }, {
    id: "relatedServices",
    Header: <span>Related Services</span>,
    accessor: p => formatServices(p.services),
  }]

  return (
    <Table
      data={products}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
    />
  )
}

export default ProductsList
