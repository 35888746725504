import React from "react"
import { connect } from "react-redux"
import { Redirect, Route, Switch, matchPath } from "react-router-dom"

import PrivateRoute from "components/routes/Private"
import SigninRoute from "components/routes/Signin"
import ForgotPasswordRoute from "components/routes/ForgotPassword"
import ResetPasswordRoute from "components/routes/ResetPassword"
import InvitationRoute from "components/routes/Invitations"
import DashboardRoute from "components/routes/Dashboard"
import AppointmentsRoute from "components/routes/Appointments"
import CustomersRoute from "components/routes/Customers"
import BillingRoute from "components/routes/Billing"
import ReportsRoute from "components/routes/Reports"
import SettingsRoute from "components/routes/Settings"
import ProfileRoute from "components/routes/Profile"
import NotFoundRoute from "components/routes/NotFound"
import SideModalRoute from "components/routes/SideModal"
import AccountDetail from "components/accounts/Detail"
import NewAppointment from "components/appointments/New"
import AppointmentFormSwitcher from "components/appointments/FormSwitcher"
import AppointmentDetail from "components/appointments/Detail"
import CalendarContext from "components/appointments/calendar/Context"

class AppSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.previousLocation = props.location
    this.state = {
      // Calendar-specific, has to live here because modals need it via CalendarContext
      clickedCalendarInfo: null,
    }
  }

  /**
   * Handle user visiting a SideModalRoute directly via URL.
   * Run only once in componentDidMount.
  */
  handleDirectModalAccess = () => {
    const { location: { pathname }, history } = this.props

    // key = modal route to match against
    // val = page to show in background behind modal
    const backgroundPages = {
      "/accounts/:id": "/customers/accounts",
      "/appointments/new": "/appointments/calendar",
      "/appointments/:id(\\d+)": "/appointments/calendar",
      "/time_blocks/:id(\\d+)": "/appointments/calendar",
    }

    for (let route in backgroundPages) {
      if (backgroundPages.hasOwnProperty(route) && matchPath(pathname, {
        path: route,
        exact: true,
      })) {
        history.replace(backgroundPages[route])
        return setTimeout(() => history.push(pathname, { modal: true }), 1)
      }
    }
  }

  closeModals = () => {
    const { history } = this.props
    history.replace(this.previousLocation)
  }

  componentDidMount() {
    this.handleDirectModalAccess()
  }

  componentDidUpdate(prevProps) {
    const { location } = prevProps

    // set previousLocation if props.location isn't a modal
    if (this.props.history.action !== "POP" && (!location.state || !location.state.modal)) {
      this.previousLocation = location
    }
  }

  setClickedCalendarInfo = info => {
    this.setState({ clickedCalendarInfo: info })
  }

  render() {
    const { currentUser, location } = this.props
    const { clickedCalendarInfo } = this.state
    const showingModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    )

    return (
      <CalendarContext.Provider value={{
        info: clickedCalendarInfo,
        setClicked: this.setClickedCalendarInfo,
      }}>
        <Switch location={showingModal ? this.previousLocation : location}>
          <Route exact path="/login" component={SigninRoute} />
          <Route exact path="/forgotpassword" component={ForgotPasswordRoute} />
          <Route exact path="/passwords/:token/edit" component={ResetPasswordRoute} />
          <Route exact path="/invitations/:token/accept" component={InvitationRoute} />
          <PrivateRoute exact path="/" component={DashboardRoute} />
          <Route exact path="/appointments" render={() => <Redirect to="/appointments/calendar" />} />
          <PrivateRoute path="/appointments/:option([a-zA-Z]+)" component={AppointmentsRoute} />
          <Route exact path="/customers" render={() => <Redirect to="/customers/accounts" />} />
          <PrivateRoute path="/customers/:option" component={CustomersRoute} />
          <Route exact path="/billing" render={() => <Redirect to="/billing/invoices" />} />
          <PrivateRoute path="/billing/:option" component={BillingRoute} />
          <Route exact path="/reports" render={() => <Redirect to="/reports/products" />} />
          <PrivateRoute path="/reports/:option" component={ReportsRoute} user={currentUser} />
          <Route exact path="/settings" render={() => <Redirect to="/settings/users" />} />
          {currentUser && currentUser.role === "Admin" &&
            <PrivateRoute path="/settings/:option" component={SettingsRoute} />
          }
          <PrivateRoute path="/profile" component={ProfileRoute} />
          <Route component={NotFoundRoute} />
        </Switch>

        {showingModal &&
          <Switch>
            <SideModalRoute
              exact path={"/accounts/:id"}
              component={AccountDetail}
              handleClose={this.closeModals}
            />
            <SideModalRoute
              exact path={"/appointments/new"}
              component={NewAppointment}
              handleClose={this.closeModals}
            />
            <SideModalRoute
              exact path={"/appointments/:id(\\d+)"}
              component={AppointmentDetail}
              handleClose={this.closeModals}
            />
            <SideModalRoute
              exact path={"/time_blocks/:id(\\d+)"}
              component={AppointmentFormSwitcher}
              handleClose={this.closeModals}
            />
          </Switch>
        }
      </CalendarContext.Provider>
    )
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser && state.currentUser.data && state.currentUser.data.attributes
  }
}

export default connect(
  mapStateToProps,
  null
)(AppSwitch)
