import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"

import { getInvoices } from "actions"
import SubNav from "components/SubNav"
import Content from "components/Content"
import Table from "components/Table"
import Status from "components/billing/Status"
import { formatCurrency, formatPhoneNumber } from "utils/format"

class InvoiceList extends Component {

  state = {
    loading: true
  }

  componentDidMount() {
    this.props.getInvoices().then(() => this.setState({ loading: false }))
  }

  render() {

    const { invoices, subNavOptions, handleRowClick } = this.props
    const { loading } = this.state

    const columns = [{
      id: "created",
      Header: <span>Date</span>,
      accessor: d => moment.unix(d.created).format("MMM D, YYYY"),
      maxWidth: 120,
    }, {
      Header: <span>Number</span>,
      accessor: "number",
      Cell: row => <span style={{fontFamily: "monospace"}}>{row.original.number}</span>
    }, {
      Header: <span>Account</span>,
      accessor: "customer_name",
    }, {
      Header: <span>Billing Contact</span>,
      accessor: "metadata.billing_contact",
    }, {
      Header: <span>Billing Email</span>,
      accessor: "customer_email",
    }, {
      id: "phoneNumber",
      Header: <span>Billing Phone</span>,
      accessor: d => formatPhoneNumber(d.customer_phone),
    }, {
      id: "type",
      Header: <span>Invoice Type</span>,
      accessor: d => d.subscription ? "Monthly Services" : "One-time",
    }, {
      id: "total",
      Header: <span>Total</span>,
      maxWidth: 120,
      accessor: d => formatCurrency(d.total/100),
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    }, {
      id: "status",
      Header: <span>Status</span>,
      maxWidth: 100,
      accessor: "status",
      Cell: row => <Status status={row.original.status} />
    }]

    return (
      <>
        <SubNav {...subNavOptions} />

        <Content>
          <Table
            data={invoices}
            columns={columns}
            loading={loading}
            handleRowClick={handleRowClick}
          />
        </Content>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    invoices: state.invoices.data || [],
    loading: state.invoices.isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getInvoices,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceList)
