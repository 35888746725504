import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"
import { connect } from "react-redux"

import Header from "components/Header"
import AppointmentCalendar from "components/appointments/Calendar"
import ProblemAppointmentList from "components/appointments/ProblemList"
import OptimizeModal from "components/appointments/OptimizeModal"
import Icon from "components/Icon"
import Button from "components/forms/Button";
import CalendarContext from "components/appointments/calendar/Context";
import styles from "./Appointments.module.scss"

class AppointmentsRoute extends Component {
  static contextType = CalendarContext

  state = {
    optimizeModalOpen: false,
  }

  toggleSubNav = option => {
    this.props.history.push(`/appointments/${option}`)
  }

  handleEventClick = event => {
    const isTimeBlock = !!(event.block)

    if (isTimeBlock) {
      const timeBlockId = event.id
      this.props.history.push(`/time_blocks/${timeBlockId}`, { modal: true })
    } else {
      const appointmentId = event.id
      this.props.history.push(`/appointments/${appointmentId}`, { modal: true })
    }
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const appointmentId = rowInfo.original.id
    this.props.history.push(`/appointments/${appointmentId}`, { modal: true })
  }

  render() {
    const {
      onDayView,
      selectedTechnicians,
      match: { params: { option } },
    } = this.props
    const { optimizeModalOpen } = this.state
    const { setClicked } = this.context

    const navOptions = {
      calendar: "calendar",
      attention: "Needs Attention",
    }

    return (
      <>
        <Header>
          <h1>Appointments</h1>
          <div className={styles.headerButtons}>
            {option === "calendar" && (
              <Button
                kind="gray"
                style={{ marginRight: "10px" }}
                disabled={!onDayView || selectedTechnicians.length < 1}
                onClick={() => this.setState({ optimizeModalOpen: true })}
              >
                Optimize Schedule
              </Button>
            )}
            <Button
              kind="primary"
              onClick={() => {
                setClicked(null)
                this.props.history.push("/appointments/new", { modal: true })
              }}
            >
              Schedule
              <Icon name="plus" width="12" height="12" />
            </Button>
          </div>
        </Header>

        <Switch>
          <Route exact path="/appointments/calendar/:year?/:month?/:day?" render={() => (
            <AppointmentCalendar
              handleEventClick={this.handleEventClick}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />

          <Route exact path="/appointments/attention" render={() => (
            <ProblemAppointmentList
              handleRowClick={this.handleRowClick}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />
        </Switch>

        {optimizeModalOpen && (
          <OptimizeModal
            handleClose={() => this.setState({ optimizeModalOpen: false })}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  onDayView: state.CSR_GLOBALS.onDayView,
  selectedTechnicians: state.CSR_GLOBALS.selectedTechnicians,
})

export default connect(mapStateToProps)(AppointmentsRoute)
