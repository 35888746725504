import React from "react"
import { formatPhoneNumber } from "utils/format"
import "react-table/react-table.css"
import Table from "components/Table"

const AccountList = ({ accounts, loading, handleRowClick, handleNextPage }) => {

  const formatLocation = location => {
    return `${location.street1}, ${location.city}, ${location.state}, ${location.zipcode}`
  }

  const columns = [{
    Header: <span>Account #</span>,
    accessor: "number",
    maxWidth: 120,
    sortable: false
  }, {
    Header: <span>Name</span>,
    accessor: "fullName",
    sortable: false
  }, {
    id: "primaryContact",
    Header: <span>Primary Contact</span>,
    accessor: d => d.primaryContact ? d.primaryContact.name: "--",
    maxWidth: 240,
    sortable: false
  }, {
    id: "contactPhone",
    Header: <span>Phone</span>,
    accessor: d => d.primaryContact ? formatPhoneNumber(d.primaryContact.phone): "--",
    maxWidth: 180,
    sortable: false
  }, {
    id: "contactEmail",
    Header: <span>Email</span>,
    accessor: d => d.primaryContact ? d.primaryContact.email: "--",
    sortable: false
  },
  {
    id: "billingAddress",
    Header: <span>Billing Address</span>,
    accessor: d => d.billingLocation ? formatLocation(d.billingLocation) : "--",
    sortable: false
  }]

  return (
    <Table
      data={accounts}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
      handleNextPage={handleNextPage}
    />
  )
}

export default AccountList
