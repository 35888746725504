import { PAGINATED_OBJECTS_SUCCESS, SEARCH_SUCCESS, CLEAR_OBJECTS } from "./types"
import { CALL_API } from "middleware/api"

const getContacts = (page=1, search) => {

  let options, params = {}

  if(page) params = { ...params, page: page }
  if(search) params = { ...params, q: search}
  options = { ...options, params: params}

  const successType = search && page === 1 ? SEARCH_SUCCESS : PAGINATED_OBJECTS_SUCCESS

  return {
    [CALL_API]: {
      endpoint: "/contacts",
      method: "get",
      options,
      successType
    },
  }
}

const clearContacts = () => {
  return dispatch => {
    dispatch({ type: CLEAR_OBJECTS })
  }
}

export { getContacts, clearContacts }
