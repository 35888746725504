import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import moment from "moment"

import { getAppointment, getSalesSources, updateAppointment } from "actions"
import { formatCurrency, formatPhoneNumber } from "utils/format"
import SideModal from "components/SideModal"
import EditAppointment from "components/appointments/Edit"
import StatusBar from "components/appointments/detail/StatusBar"
import TechnicianLog from "components/appointments/detail/TechnicianLog"
import ProductsSection from "components/appointments/detail/ProductsSection"
import Button from "components/forms/Button"
import Status from "components/billing/Status"
import stripeMark from "assets/stripe.png"
import styles from "./Detail.module.scss"

class AppointmentDetail extends Component {
  state = {
    isEditing: false,
    loading: true,

    isEditingSalesSources: false,
    asss: null, // appointmentService salesSources
  }

  componentDidMount() {
    const { match, getAppointment, getSalesSources } = this.props
    const appointmentId = match.params.id
    this.setState({ loading: true })
    getAppointment(appointmentId).then(() => this.setState({loading: false}))
    getSalesSources()
  }

  toggleEditing = () => {
    this.props.toggleLocked()
    this.setState({ isEditing: !this.state.isEditing })
  }

  toggleEditSalesSources = () => {
    const { appointment } = this.props
    const { isEditingSalesSources } = this.state

    if (!isEditingSalesSources) {
      const defaultAsss = {}
      appointment.appointmentServices.forEach(as => {
        defaultAsss[as.id] = as.salesSourceId
      })

      this.setState({
        isEditingSalesSources: true,
        asss: defaultAsss,
      })
    } else {
      this.setState({ isEditingSalesSources: false })
    }
  }

  onSelectSalesSource = (asId, sourceId) => {
    const { asss } = this.state
    this.setState({ asss: { ...asss, [asId]: sourceId } })
  }

  onSubmitSalesSources = () => {
    const { appointment, updateAppointment } = this.props
    const { asss } = this.state

    const toSubmit = { appointment_services: [] }
    Object.keys(asss).forEach(serviceId => {
      toSubmit.appointment_services.push({
        id: serviceId,
        sales_source_id: asss[serviceId]
      })
    })

    updateAppointment(appointment.id, toSubmit)
      .then(() => this.setState({ isEditingSalesSources: false }))
  }

  handleStripeClick = () => {
    const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env
    const invoiceId = this.props.appointment.invoice.id
    window.open(
      `${REACT_APP_STRIPE_DASHBOARD_URL}/invoices/${invoiceId}`,
      "_blank"
    )
  }

  render() {
    const { appointment, salesSources } = this.props
    const {
      isEditing,
      isEditingSalesSources,
      asss,
      loading
    } = this.state

    const isEditable = appointment && ["scheduled", "in_progress", "problem"].includes(appointment.state)

    let salesSourcesButtons = null
    if (!isEditable && !isEditingSalesSources) {
      salesSourcesButtons = (
        <Button
          kind="primary"
          className={styles.button}
          onClick={this.toggleEditSalesSources}
          small
        >
          Edit Sales Sources
        </Button>
      )
    } else if (!isEditable && isEditingSalesSources) {
      salesSourcesButtons = (
        <>
          <Button
            kind="gray"
            className={styles.button}
            onClick={this.toggleEditSalesSources}
            small
          >
            Cancel
          </Button>
          <Button
            kind="primary"
            className={styles.button}
            onClick={this.onSubmitSalesSources}
            small
          >
            Save
          </Button>
        </>
      )
    }

    return (
      <>
        {isEditing &&
          <SideModal handleClose={this.toggleEditing}>
            <EditAppointment
              appointment={appointment}
              toggleEditing={this.toggleEditing}
            />
          </SideModal>
        }
        {!loading &&
          <div className={styles.detailModal}>
            <header>
              <h3 className={styles.title}>Appointment for {appointment.title}</h3>
              <div className={styles.headerButtons}>
                {isEditable &&
                  <Button className="btn--gray" onClick={this.toggleEditing}>
                    Edit
                  </Button>
                }
              </div>
            </header>
            <div className={styles.detailContent}>
              <div className={styles.statusContainer}>
                <StatusBar status={appointment.state} />
              </div>

              <section className={styles.serviceLocation}>
                <div>
                  <div className={styles.sectionHeader}>
                    <i className="material-icons">place</i>
                    <h4>Service Location</h4>
                  </div>
                  <div>
                    <div>
                      <div className={styles.strong}>
                        <Link
                          to={{pathname: `/accounts/${appointment.account.id}`, state: { modal: true }}}>
                          {appointment.title}
                        </Link>
                      </div>
                      {appointment.location.name && (
                        <p className={styles.locationName}>
                          {appointment.location.name}
                        </p>
                      )}
                      <p>{appointment.location.street1}</p>
                      <p>{appointment.location.city}, {appointment.location.state} {appointment.location.zipcode}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.sectionHeader}>
                    <h4>&nbsp;</h4>
                  </div>
                  <div className={styles.contactDetail}>
                    <p className={styles.strong}>{appointment.contact.name}</p>
                    <p>{appointment.contact.email}</p>
                    <p>{formatPhoneNumber(appointment.contact.phone)}</p>
                  </div>
                </div>
              </section>

              <section className={styles.dateTime}>
                <div>
                  <div className={styles.sectionHeader}>
                    <i className="material-icons">calendar_today</i>
                    <h4>Date & Time</h4>
                  </div>
                  <ul>
                    <li className={styles.strong}>{moment(appointment.windowStart).format("dddd, MMMM D, YYYY")}</li>
                    <li>{moment(appointment.windowStart).format("h:mm a")} - {moment(appointment.windowEnd).format("h:mm a")}</li>
                    <li>{`Estimated ${appointment.estimatedDuration} min.`}</li>
                  </ul>
                </div>
                <div>
                  <div className={styles.sectionHeader}>
                    <h4 className={styles.offsetHeader}>Technician</h4>
                  </div>
                  <div className={styles.technicians}>
                    <ul>
                      <li className={styles.strong}>{`${appointment.technician.firstName} ${appointment.technician.lastName}`}</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className={styles.services}>
                <div className={styles.sectionHeader}>
                  <i className="material-icons">bug_report</i>
                  <div className={styles.sectionHeaderContent}>
                    <h4>Services</h4>
                    {salesSourcesButtons}
                  </div>
                </div>
                <table>
                  <tbody>
                    {appointment.appointmentServices.map((as, index) => {
                      const salesSourceOptions = salesSources
                        .filter(s => !s.discardedAt || String(as.salesSourceId) === s.id)
                        .map(s => (
                          <option key={s.id} value={s.id}>{s.name}</option>
                        ))

                      salesSourceOptions.unshift((
                        <option key="no-source" value="">None</option>
                      ))

                      return (
                        <tr key={index}>
                          <th className={styles.titleWrapper}>
                            <div>{as.name}</div>
                            <div className={styles.salesSource}>
                              Sales Source:&nbsp;
                              {isEditingSalesSources ? (
                                <select
                                  value={asss[as.id] || ""}
                                  onChange={e => {
                                    this.onSelectSalesSource(as.id, e.target.value)
                                  }}
                                >
                                  {salesSourceOptions}
                                </select>
                              ) : (
                                as.salesSource ? as.salesSource.name : "None"
                              )}
                            </div>
                          </th>
                          <td className={styles.recurrence}>
                            {as.recurrence &&
                              <span>
                                <i className="material-icons">autorenew</i>
                                {as.recurrence}
                              </span>
                            }
                          </td>
                          <td className={styles.price}>{formatCurrency(Number(as.price))}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Total Estimate</th>
                      <th></th>
                      <th className={styles.price}>{formatCurrency(appointment.amount)}</th>
                    </tr>
                  </tfoot>
                </table>
              </section>

              <ProductsSection
                isEditable={isEditable}
                appointment={appointment}
              />

              {appointment.invoice &&
                <section className={styles.billing}>
                  <div className={styles.sectionHeader}>
                    <i className="material-icons">attach_money</i>
                    <h4>Invoice</h4>
                  </div>
                  <div className={styles.billingDetails} onClick={this.handleStripeClick}>
                    <span><img src={stripeMark} alt="Stripe" /> {moment.unix(appointment.invoice.created).format("MMM D, YYYY")}</span>
                    <span className={styles.invoiceNumber}>{appointment.invoice.number}</span>
                    <span className={styles.invoiceTotal}>{formatCurrency(appointment.invoice.total/100)}</span>
                    <Status status={appointment.invoice.status} />
                  </div>
                </section>
              }

              <section>
                <div className={styles.instructions}>
                  <h5>Appointment Instructions</h5>
                  <p>{appointment.instructions || <i className={styles.inactive}>None</i>}</p>
                </div>
              </section>

              <section>
                <div className={styles.notes}>
                  <h5>Invoice Memo</h5>
                  <p>{appointment.customerNotes || <i className={styles.inactive}>None</i>}</p>
                </div>
              </section>

              <section>
                <div className={styles.notes}>
                  <h5>Technician's Notes</h5>
                  <p>{appointment.technicianNotes || <i className={styles.inactive}>None</i>}</p>
                </div>
              </section>

              <section>
                {appointment && appointment.state === "cancelled" ? <>
                  <h5>Cancellation Reason</h5>
                  <p>{appointment.cancellationReason || <i className={styles.inactive}>None</i>}</p>
                </> : (
                  <TechnicianLog
                    appointment={appointment}
                    editable={!isEditable}
                  />
                )}
              </section>
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const appointmentId = ownProps.match.params.id

  let appointment
  let salesSources = []
  let loading = false

  const metaUrl = `/appointments/${appointmentId}`
  if (state.data.meta[metaUrl]) {
    appointment = build(state.data, "appointment", appointmentId)
    loading = loading || state.data.meta[metaUrl].loading
  }

  if (state.data.meta["/commission_sources/sales"]) {
    salesSources = (state.data.meta["/commission_sources/sales"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
    loading = loading || state.data.meta["/commission_sources/sales"].loading
  }

  return { appointment, salesSources, loading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getAppointment,
      getSalesSources,
      updateAppointment,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDetail)
