const formatCurrency = (number=0, cents = true) => {
  const decimals = cents ? 2 : 0
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
  })
}

const formatPhoneNumber = string => {
  const s2 = ("" + string).replace(/\D/g, "")
  const main = s2.slice(0, 10)
  const m = main.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`
}

const formatLocation = loc => (loc.street2
  ? `${loc.street1}, ${loc.street2}, ${loc.city}, ${loc.state} ${loc.zipcode}`
  : `${loc.street1}, ${loc.city}, ${loc.state} ${loc.zipcode}`
)

export { formatCurrency, formatPhoneNumber, formatLocation }
