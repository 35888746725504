import React from "react"
import PropTypes from "prop-types"
import Checkbox from "components/forms/Checkbox"
import styles from "./TechnicianFilter.module.scss"

const TechnicianFilter = ({ technicians, selectedTechnicians, handleClick, handleSet }) => {

  const allChecked = technicians.length === selectedTechnicians.length
  const indeterminate = selectedTechnicians.length < technicians.length && selectedTechnicians.length > 0

  const toggleSelectAll = checked => {
    const list = checked ? technicians.map(t => t.id) : []
    handleSet(list)
  }
  
  
  const technicianList = technicians.map(t => {
    const technicianId = t.id

    return (
      <li key={technicianId}>
        <Checkbox
          id={`technician_${technicianId}`}
          labelText={`${t.firstName} ${t.lastName}`}
          checked={selectedTechnicians.includes(technicianId)}
          value={technicianId}
          onChange={handleClick}
        />
      </li>
    )
  })

  return (
    <div className={styles.technicianFilter}>
      <h3>Technician</h3>
      <ul>
        <li>
          <Checkbox
            id="all_technicians"
            labelText="All Technicians"
            wrapperClassName={styles.allTechnicians}
            checked={allChecked}
            indeterminate={indeterminate}
            value="all"
            onChange={toggleSelectAll}
          />
        </li>
        {technicianList}
      </ul>
    </div>
  )
}

TechnicianFilter.propTypes = {
  technicians: PropTypes.array.isRequired,
  selectedTechnicians: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSet: PropTypes.func.isRequired,
}

export default TechnicianFilter