import React from "react"
import { Switch, Route } from "react-router-dom"

import Header from "components/Header"
import ProductReports from "components/reports/products/Reports"
import CommissionReports from "components/reports/commissions/Reports"

class ReportsRoute extends React.Component {
  toggleSubNav = option => {
    this.props.history.push(`/reports/${option}`)
  }

  render() {
    const { user, match: { params: { option } } } = this.props

    const navOptions = {
      "products": "Product Usage",
    }

    if (user && user.role === "Admin"){
      navOptions["commissions"] = "Commissions"
    }

    return (
      <>
        <Header>
          <h1>Reports</h1>
        </Header>

        <Switch>
          <Route exact path="/reports/products" render={() => (
            <ProductReports
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />

          <Route exact path="/reports/commissions" render={() => (
            <CommissionReports
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />
        </Switch>
      </>
    )
  }
}

export default ReportsRoute
