import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"

import { getCommissionSources, getUsers } from "actions"
import SubNav from "components/SubNav"
import Content from "components/Content"
import NewCommissionSource from "components/commissionSources/New"
import EditCommissionSource from "components/commissionSources/Edit"
import CommissionSourcesList from "components/commissionSources/List"
import Button from "components/forms/Button"
import Icon from "components/Icon"

class CommissionSourcesRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newModalOpen: false,
      editModalOpen: false,
      clickedSourceId: null,
    }
  }

  componentDidMount() {
    this.props.getCommissionSources()
    this.props.getUsers()
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const sourceId = rowInfo.original.id
    this.setState({
      editModalOpen: true,
      clickedSourceId: sourceId,
    })
  }

  closeModals = () => {
    this.setState({
      newModalOpen: false,
      editModalOpen: false,
      clickedSourceId: null,
    })
  }

  render() {
    const { commissionSources, users, loading, subNavOptions } = this.props
    const { newModalOpen, editModalOpen, clickedSourceId } = this.state

    return (
      <>
        <SubNav {...subNavOptions}>
          <Button
            kind="primary"
            onClick={() => this.setState({ newModalOpen: true })}
          >
            New Commission Source
            <Icon name="plus" width="12" height="12" />
          </Button>
        </SubNav>

        <Content>
          <CommissionSourcesList
            commissionSources={commissionSources}
            loading={loading}
            handleRowClick={this.handleRowClick}
          />
        </Content>

        {newModalOpen &&
          <NewCommissionSource
            users={users}
            handleClose={this.closeModals}
          />
        }
        {editModalOpen &&
          <EditCommissionSource
            sourceId={clickedSourceId}
            users={users}
            handleClose={this.closeModals}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  const returned = {
    commissionSources: [],
    users: [],
    loading: true,
  }

  if (state.data.meta["/commission_sources"]) {
    returned.commissionSources = (state.data.meta["/commission_sources"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
    returned.loading = state.data.meta["/commission_sources"].loading
  }

  if (state.data.meta["/users"]) {
    returned.users = (state.data.meta["/users"].data || [])
      .map(object => build(state.data, "user", object.id))
  }

  return returned
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getCommissionSources,
      getUsers,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommissionSourcesRoute)
