import React from "react"
import { formatPhoneNumber } from "utils/format"
import "react-table/react-table.css"
import Table from "components/Table"

const ContactList = ({ contacts, loading, handleRowClick, handleNextPage, page }) => {
  const columns = [{
    Header: <span>Account #</span>,
    accessor: "accountNumber",
    maxWidth: 150,
    sortable: false
  }, {
    id: "name",
    Header: <span>Name</span>,
    accessor: d => d.name,
    maxWidth: 240,
    sortable: false
  }, {
    id: "phone",
    Header: <span>Phone</span>,
    accessor: d => formatPhoneNumber(d.phone),
    maxWidth: 180,
    sortable: false
  }, {
    id: "email",
    Header: <span>Email</span>,
    accessor: d => d.email,
    sortable: false
  }]

  return (
    <Table
      data={contacts}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
      handleNextPage={handleNextPage}
    />
  )
}

export default ContactList
