const loadingPlaceholders = {
  contact: {
    name: "--",
    email: "--",
    phone: "--",
  },
  location: {
    id: "",
    street1: "--",
    city: "--",
    state: "--",
    zipcode: "--",
  },
}

const getBillingLocation = account => {
  if (!account || !account.accountLocations || account.accountLocations.length === 0) {
    return loadingPlaceholders.location
  }
  const accountLocation = account.accountLocations.find(al => al.billing)
  return accountLocation.location
}

const getBillingContact = account => {
  if (!account || !account.accountContacts || account.accountContacts.length === 0) {
    return loadingPlaceholders.contact
  }
  const accountContact = account.accountContacts.find(ac => ac.billing)
  return accountContact.contact
}

const getPrimaryLocation = account => {
  if (!account || !account.accountLocations || account.accountLocations.length === 0) {
    return loadingPlaceholders.location
  }
  const accountLocation = account.accountLocations.find(al => al.primary)
  return accountLocation.location
}

const getPrimaryContact = account => {
  if (!account || !account.accountContacts || account.accountContacts.length === 0) {
    return loadingPlaceholders.contact
  }
  const accountContact = account.accountContacts.find(ac => ac.primary)
  return accountContact.contact
}

export {
  getBillingLocation,
  getBillingContact,
  getPrimaryLocation,
  getPrimaryContact
}
