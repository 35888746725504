/**
 * "Deepens" objects with dot-notation keys.
 * Useful for handling Formik's `error` object when very
 * long keys are involved.
 *
 * Input: {
 *  'ab.cd.e': 'foo',
 *  'ab.cd.f': 'bar',
 *  'ab.g': 'foo2'
 * }
 *
 * Output: {
 *  {ab: {cd: {e:'foo', f:'bar'}, g:'foo2'}}
 * }
 *
 * https://stackoverflow.com/a/7794127/1079597
 */
const deepen = (o) => {
  const oo = {}
  let t, parts, part
  for (let k in o) {
    t = oo
    parts = k.split('.')
    const key = parts.pop()
    while (parts.length) {
      part = parts.shift()
      t = t[part] = t[part] || {}
    }
    t[key] = o[k]
  }
  return oo
}

export default deepen
