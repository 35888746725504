import { CREATE_OBJECT_SUCCESS } from "./types"
import { CALL_API } from "middleware/api"

const createAccountLocation = data => {
  const options = {}
  options.data = { account_location: data }
  options.params = { include: "account,location" }

  return {
    [CALL_API]: {
      endpoint: "/account_locations",
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateAccountLocation = (accountLocationId, data) => {
  const options = {}
  options.data = { account_location: data }
  options.params = { include: "account,location" }

  return {
    [CALL_API]: {
      endpoint: `/account_locations/${accountLocationId}`,
      method: "patch",
      options,
    },
  }
}

export { createAccountLocation, updateAccountLocation }
