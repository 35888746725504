import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import "./Checkbox.scss"

const Checkbox = React.forwardRef(
  (
    {
      className,
      id,
      labelText,
      onChange,
      indeterminate,
      hideLabel,
      wrapperClassName,
      title = "",
      isFormik,
      ...other
    },
    ref
  ) => {
    const labelClasses = classNames(`checkbox-label`, className)
    const wrapperClasses = classNames(
      `checkbox-wrapper`,
      wrapperClassName
    )

    return (
      <div className={wrapperClasses}>
        <input
          {...other}
          type="checkbox"
          onChange={isFormik ? onChange : evt => {
            onChange(evt.target.checked, id, evt)
          }}
          className={classNames("checkbox", { disabled: other.disabled })}
          id={id}
          ref={el => {
            if (el) {
              el.indeterminate = indeterminate
            }
            if (typeof ref === "function") {
              ref(el)
            } else if (Object(ref) === ref) {
              ref.current = el
            }
          }}
        />
        <label htmlFor={id} className={labelClasses} title={title || null}>
          {labelText}
        </label>
      </div>
    )
  }
)

Checkbox.propTypes = {
  /**
   * Specify whether the underlying input should be checked
   */
  checked: PropTypes.bool,

  /**
   * Specify whether the underlying input should be checked by default
   */
  defaultChecked: PropTypes.bool,

  /**
   * Specify whether the Checkbox is in an indeterminate state
   */
  indeterminate: PropTypes.bool,

  /**
   * Specify an optional className to be applied to the <label> node
   */
  className: PropTypes.string,

  /**
   * Specify whether the Checkbox should be disabled
   */
  disabled: PropTypes.bool,

  /**
   * Provide an `id` to uniquely identify the Checkbox input
   */
  id: PropTypes.string.isRequired,

  /**
   * Provide a label to provide a description of the Checkbox input that you are
   * exposing to the user
   */
  labelText: PropTypes.node.isRequired,

  /**
   * Whether this Checkbox is being instantiated by FormikCheckbox.
   * If true, onChange is overwritten by the one FormikCheckbox provides.
   */
  isFormik: PropTypes.bool,

  onChange: PropTypes.func,

  /**
   * Specify a title for the <label> node for the Checkbox
   */
  title: PropTypes.string,

  /**
   * The CSS class name to be placed on the wrapping element
   */
  wrapperClassName: PropTypes.string,
}

Checkbox.defaultProps = {
  isFormik: false,
  onChange: () => {},
  indeterminate: false,
}

export default Checkbox
