import { CREATE_OBJECT_SUCCESS } from "./types"
import { CALL_API } from "middleware/api"

const getUsers = () => ({
  [CALL_API]: {
    endpoint: "/users",
    method: "get",
  },
})

const getUser = userId => ({
  [CALL_API]: {
    endpoint: `/users/${userId}`,
    method: "get",
  },
})

const updateUser = (userId, params) => {
  const options = {}
  options.data = { user: params }

  return {
    [CALL_API]: {
      endpoint: `/users/${userId}`,
      method: "patch",
      options: options
    },
  }
}

const inviteUser = (params) => {
  const options = {}
  options.data = { user: params }

  return {
    [CALL_API]: {
      endpoint: "/users/invite",
      method: "POST",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    }
  }
}

export { getUsers, getUser, updateUser, inviteUser }
