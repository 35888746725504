import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "./SubNav.module.scss"
const cx = classNames.bind(styles)

const SubNav = props => {
  // props.options can be an array:
  //   ["option", "option2"]
  // or an object for custom titles:
  //   { "option": "Title with Spaces", "option2": "..." }
  const optionsHaveTitles = Array.isArray(props.options)

  const options = optionsHaveTitles ? props.options : Object.keys(props.options)

  const navOptions = options.map((option, index) => {
    const optionClass = cx({ active: props.active === option })

    return (
      <li
        key={index}
        className={optionClass}
        onClick={() => props.handleClick(option)}
      >
        {optionsHaveTitles ? option : props.options[option]}
      </li>
    )
  })

  return (
    <div className={cx(styles.subNav, props.className)}>
      <ul>{navOptions}</ul>
      { props.children }
    </div>
  )
}

SubNav.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.array, PropTypes.shape({})
  ]).isRequired,
  active: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default SubNav
