import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { createProduct } from "actions"
import Modal from "components/Modal"
import ProductForm from "./Form"

class NewProduct extends React.Component {
  handleCreate = (values) => {
    const { createProduct, handleClose } = this.props
    return createProduct(values).then(handleClose)
  }

  render() {
    const { handleClose } = this.props
    return (
      <Modal title="New Product" handleClose={handleClose}>
        <ProductForm
          handleSubmit={this.handleCreate}
          initialValues={{
            name: "",
            epa_code: "",
            company: "",
            unit: "",
            service_ids: [""],
          }}
        />
      </Modal>
    )
  }
}

NewProduct.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ createProduct }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NewProduct)
