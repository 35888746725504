import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import build from "redux-object"
import { Formik, Field, Form } from "formik"
import classNames from "classnames/bind"
import PropTypes from "prop-types"
import queue from "utils/snackbarQueue"

import {
  getCounties,
  createAccountLocation,
  updateAccountLocation
} from "actions"
import Modal from "components/Modal"
import FormikTextInput from "components/forms/FormikTextInput"
import FormikCheckbox from "components/forms/FormikCheckbox"
import FormikSelect from "components/forms/FormikSelect"
import TimeConstraintsForm from "components/locations/TimeConstraintsForm"
import Button from "components/forms/Button"

import styles from "./Location.module.scss"
const cx = classNames.bind(styles)

class LocationModal extends React.Component {
  componentDidMount = () => {
    if (this.props.accountLocation) {
      this.props.getCounties()
    }
  }

  handleSubmit = (values, actions) => {
    const {
      accountId,
      accountLocation,
      createAccountLocation,
      updateAccountLocation,
      handleClose
    } = this.props
    actions.setSubmitting(true)

    const toSubmit = accountLocation ? {
      primary: values.primary,
      location: { ...values, id: accountLocation.location.id },
    } : {
      account_id: accountId,
      primary: values.primary,
      location: values,
    }

    // accountLocation was supplied, so we should update (not create)
    if (accountLocation) {
      updateAccountLocation(accountLocation.id, toSubmit)
        .then(() => actions.setSubmitting(false))
        .then(() => {
          handleClose()
          queue.notify({
            body: "Location updated",
            timeout: 5000,
            leading: true,
          })
        })
        .catch(e => {
          if (e.response) {
            // HTTP error
            actions.setErrors(e.response.data)
          } else {
            // JS error
            console.error(e.message)
          }
          actions.setSubmitting(false)
          actions.setStatus({ msg: 'Error updating account location' })
        })
    } else {
      createAccountLocation(toSubmit)
        .then(() => actions.setSubmitting(false))
        .then(() => {
          handleClose()
          queue.notify({
            body: "Location created",
            timeout: 5000,
            leading: true,
          })
        })
        .catch(e => {
          if (e.response) {
            // HTTP error
            actions.setErrors(e.response.data)
          } else {
            // JS error
            console.error(e.message)
          }
          actions.setSubmitting(false)
          actions.setStatus({ msg: 'Error creating account location' })
        })
      }
  }

  render() {
    const { accountLocation, counties, loading, handleClose } = this.props
    let location = null
    let latitude
    let longitude
    if (accountLocation) {
      location = accountLocation.location
      latitude = location.latitude
      longitude = location.longitude
    }

    const initialValues = location ? {
      name: location.name || "",
      street1: location.street1,
      street2: location.street2 || "",
      city: location.city,
      state: location.state,
      zipcode: location.zipcode,
      county_id: location.countyId ? String(location.countyId) : null,
      time_constraints: location.timeConstraints,
      primary: !!(accountLocation.primary)
    } : {
      name: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipcode: "",
      time_constraints: {monday: {am: true, pm: true}, tuesday: {am: true, pm: true}, wednesday: {am: true, pm: true}, thursday: {am: true, pm: true}, friday: {am: true, pm: true}},
      primary: false,
    }

    const countyOptions = counties.map(c => ({
      label: c.name,
      value: String(c.id),
    }))

    return (
      <Modal
        className={styles.modal}
        title={location ? "Edit Service Location" : "Add Service Location"}
        handleClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={({ values, errors, status, touched, isSubmitting }) => (
            <Form className={styles.locationForm}>
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="name"
                type="text"
                name="name"
                label="Name (optional)"
                errorText={errors["location.street1"] ? "An address is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="street1"
                type="text"
                name="street1"
                label="Address 1"
                errorText={errors["location.street1"] ? "An address is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="street2"
                type="text"
                name="street2"
                label="Address 2"
              />

              <div className={styles.cityStateZip}>
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="city"
                  type="text"
                  name="city"
                  label="City"
                  invalid={errors.hasOwnProperty("location.city")}
                />
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="state"
                  type="text"
                  name="state"
                  label="State"
                  invalid={errors.hasOwnProperty("location.state")}
                />
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="zipcode"
                  type="text"
                  name="zipcode"
                  label="ZIP Code"
                  invalid={errors.hasOwnProperty("location.zipcode")}
                />
              </div>

              {location && (
                <label className={styles.county}>
                  County
                  <Field
                    name="county_id"
                    component={FormikSelect}
                    className={styles.select}
                    options={countyOptions}
                    disabled={loading}
                  />
                </label>
              )}

              {latitude &&
                <div className={styles.coordinates}>
                  <span>Coordinates:</span>
                  {latitude}, {longitude}
                </div>
              }

              {errors.hasOwnProperty("location.city")
                || errors.hasOwnProperty("location.state")
                || errors.hasOwnProperty("location.zipcode") ?
                  <div className={styles.errorText}>
                    City, State, and ZIP Code are required
                  </div>
              : null}

              <Field
                component={FormikCheckbox}
                wrapperClassName={styles.checkbox}
                id="primary"
                name="primary"
                labelText="Primary service location"
                disabled={initialValues.primary}
              />

              <div className={styles.timeConstraints}>
                <h5>Time Constraints</h5>
                <TimeConstraintsForm />
              </div>

              <Button
                type="submit"
                className={cx(styles.btn, "btn--block", "btn--large")}
                disabled={isSubmitting}
              >
                {location ? "Update Location" : "Add Location"}
              </Button>
            </Form>
          )}
        />
      </Modal>
    )
  }
}

LocationModal.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accountLocation: PropTypes.shape({
    // id of the account_location relationship
    id: PropTypes.number,

    // actual location object
    location: PropTypes.shape({}),
  }),
  counties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

LocationModal.defaultProps = {
  accountLocation: null,
}

const mapStateToProps = state => {
  let loading = true
  let counties = []

  if (state.data.meta["/counties"]) {
    counties = (state.data.meta["/counties"].data || [])
      .map(object => build(state.data, "county", object.id))
    loading = state.data.meta["/counties"].loading
  }

  return { counties, loading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getCounties,
      createAccountLocation,
      updateAccountLocation,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationModal)
