import axios from "axios"
import { signoutUser} from "actions"
import store from "store"

const API_URL = process.env.REACT_APP_API_URL

export function apiHeaders(authenticated=true) {
  let headers = { "Content-Type":"application/json" }
  if(authenticated) {
    let jwt = localStorage.getItem("sessionToken")
    if(jwt !== undefined) {
      headers["Authorization"] = `Bearer ${jwt}`
    }
  }
  return headers
}

export function apiCall(method, path, options={}, authenticated=true) {
  return axios.request({
    method: method,
    url: `${API_URL}/${path}`,
    headers: apiHeaders(authenticated=true),
    params: options.params,
    data: JSON.stringify(options.data)
  }).catch(error => {
    if(error.response) {
      if(error.response.status === 401) {
        store.dispatch(signoutUser())
      }
      if(error.response.status === 500) {
      }
    }
    else {
      store.dispatch(signoutUser())
    }
    throw error
  })
}
