import {
  SET_SELECTED_TECHNICIANS,
  SET_OPTIMIZING,
  SET_OPTIMIZE_DATE,
  SET_ON_DAY_VIEW,
} from "actions/types"

const defaultState = {
  // Calendar
  selectedTechnicians: [],

  // Optimizer
  isOptimizing: false,
  optimizeDate: "",
  onDayView: false,
}

/** Global app state data */
const CSR_GLOBALS = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SELECTED_TECHNICIANS: return { ...state, selectedTechnicians: action.payload }

    case SET_OPTIMIZING: return { ...state, isOptimizing: action.payload }
    case SET_OPTIMIZE_DATE: return { ...state, optimizeDate: action.payload }
    case SET_ON_DAY_VIEW: return { ...state, onDayView: action.payload }
    default: return state
  }
}

export default CSR_GLOBALS
