import React from "react"
import { Field } from "formik"
import FormikCheckbox from "components/forms/FormikCheckbox"
import styles from "./TimeConstraintsForm.module.scss"

const LocationTimeConstraintsForm = () => {

  const meridians = ["am", "pm"]
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday"]

  const body = meridians.map(meridian => {
    
    const fields = days.map(day => (
      <td key={day}>
        <Field
          component={FormikCheckbox}
          id={`${day}_${meridian}`}
          name={`time_constraints.${day}.${meridian}`}
          labelText=""
          />
      </td>
    ))
    
    return (
      <tr key={meridian}>
        <th>{meridian.toUpperCase()}</th>
        {fields}
      </tr>
    )
  })

  return (
    <table className={styles.constraintsTable}>
      <thead>
        <tr>
          <td></td>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
        </tr>
      </thead>
      <tbody>
        {body}
      </tbody>
    </table>
  )
}

export default LocationTimeConstraintsForm
