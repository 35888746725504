import assign from "lodash/assign"
import merge from "lodash/merge"
import reject from "lodash/reject"
import uniqBy from "lodash/uniqBy"
import { API_DATA_REQUEST, API_DATA_SUCCESS, API_DATA_SUCCESS_DEDUPLICATE, API_DATA_FAILURE } from "middleware/api"
import { CREATE_OBJECT_SUCCESS, UPDATE_OBJECT_SUCCESS, PAGINATED_OBJECTS_SUCCESS, SEARCH_SUCCESS, CLEAR_OBJECTS, UPDATE_NORMALIZED_OBJECT, DELETE_OBJECT_SUCCESS } from "actions/types"

const initialState = {
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_DATA_SUCCESS:
      return merge(
        {},
        state,
        merge({}, action.response, {
          meta: { [action.endpoint]: { loading: false } },
        })
      )
    case API_DATA_SUCCESS_DEDUPLICATE:
      return mergeWithoutDuplicates(state, action)
    case API_DATA_REQUEST:
      return merge({}, state, {
        meta: { [action.endpoint]: { loading: true } },
      })
    case API_DATA_FAILURE:
      return merge({}, state, {
        meta: { [action.endpoint]: { loading: false } },
      })
    case CREATE_OBJECT_SUCCESS:
      return unshiftNewObject(state, action.response, action.endpoint)
    case UPDATE_OBJECT_SUCCESS:
      return replaceWithNewObject(state, action.response, action.endpoint)
    case DELETE_OBJECT_SUCCESS:
      return deleteObject(state, action)
    case PAGINATED_OBJECTS_SUCCESS:
      return concatNewObjects(state, action.response, action.endpoint)
    case SEARCH_SUCCESS:
        return replaceExistingObjects(state, action.response, action.endpoint)
    case CLEAR_OBJECTS:
        return clearExistingObjects(state, action.endpoint)
    case UPDATE_NORMALIZED_OBJECT:
        return updateNormalzedObject(state, action.params)
    default:
      return state
  }
}

function deduplicateNestedArrays(obj) {
  for (let k in obj) {
    if (obj[k] && typeof obj[k] === 'object') {
      deduplicateNestedArrays(obj[k])
      if(Array.isArray(obj[k]) && k === 'data'){
        obj[k] = uniqBy(obj[k], 'id')
      }
    }
  }
}

function mergeWithoutDuplicates(state, action) {
  let mergedObj = merge(
    {},
    state,
    merge({}, action.response, {
      meta: { [action.endpoint]: { loading: false } },
    })
  )
  deduplicateNestedArrays(mergedObj)
  return mergedObj
}

function replaceWithNewObject(state, response, endpoint) {
  let newState = { ...state }
  // merge won't work here because we have the possibility of remove an item
  // from a nested array
  for (let type in response) {
    for (let id in response[type]) {
      newState = {
        ...newState,
        [type]: {
          ...newState[type],
          [id]: {
            attributes: response[type][id].attributes,
            relationships: response[type][id].relationships
          }
        }
      }
    }
  }

  const newObject = response.meta[endpoint].data
  const newData = newState.meta[endpoint].data
    ? uniqBy(newState.meta[endpoint].data.concat(newObject), "id")
    : newObject
  return merge(
    {},
    newState,
    assign({}, response, { meta: { [endpoint]: { data: newData, loading: false }}}),
  )
}

function deleteObject(state, action) {
  const newState = { ...state }
  const { objectId, objectType } = action

  Object.keys(newState.meta).map(key => {
    const currentEndpoint = newState.meta[key]

    if(currentEndpoint.data) {
      currentEndpoint.data = reject(currentEndpoint.data, obj => {
        return obj.type === objectType && obj.id === objectId
      })
      newState.meta[key] = currentEndpoint
    }
    return newState.meta[key]
  })

  delete newState[objectType][objectId]
  return newState
}

function unshiftNewObject(state, response, endpoint) {
  const newObject = response.meta[endpoint].data
  const newData = state.meta[endpoint].data
    ? uniqBy(newObject.concat(state.meta[endpoint].data), "id")
    : newObject
  return merge(
    {},
    state,
    merge({}, response, { meta: { [endpoint]: { data: newData, loading: false }}}),
  )
}

function concatNewObjects(state, response, endpoint) {
  const newObjects = response.meta[endpoint].data
  const newData = state.meta[endpoint].data
    ? uniqBy(state.meta[endpoint].data.concat(newObjects), "id")
    : newObjects
  return merge(
    {},
    state,
    merge({}, response, { meta: { [endpoint]: { data: newData, loading: false }}}),
  )
}

function replaceExistingObjects(state, response, endpoint) {
  const newData = response.meta[endpoint].data
  const newMeta = response.meta[endpoint].meta
  return assign(
    {},
    state,
    assign({}, response, { meta: assign(
      {},
      state.meta,
      { [endpoint]: { data: newData, meta: newMeta, loading: false }}
    )}),
  )
}

function clearExistingObjects(state, endpoint) {
  const newData = []
  return assign(
    {},
    state,
    assign({}, { meta: assign(state.meta, { [endpoint]: { data: newData, loading: false }})}),
  )
}

function updateNormalzedObject(state, { type, id, data }) {
  const objectId = parseInt(id,0)
  return merge({}, state, assign({}, { [type]: { [objectId]: data }}))
}
