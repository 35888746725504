import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import get from "lodash/get"
import PropTypes from "prop-types"

import { updateUser, updateTechnician, getTechnicianProfile } from "actions"
import Modal from "components/Modal"
import UserForm from "./Form"

class EditUser extends React.Component {

  componentDidMount() {
    const { user } = this.props
    if(user.role === "Technician") {
      if(user.technicianProfile) {
        const technicianProfileId = user.technicianProfile.id
        this.props.getTechnicianProfile(technicianProfileId)
      }
    }
  }

  handleUpdate = values => {
    const { updateUser, updateTechnician, user, handleClose } = this.props
    return user.role === "Technician" 
      ? updateTechnician(user.id, values).then(handleClose)
      : updateUser(user.id, values).then(handleClose)
  }

  render() {
    const { user, handleClose } = this.props
    
    const isTechnician = user.role === "Technician"

    return (
      <Modal title="Edit User" handleClose={handleClose}>
        <UserForm
          editMode
          isTechnician={isTechnician}
          handleSubmit={this.handleUpdate}
          initialValues={{
            email: user.email || "",
            phone: user.phone || "",
            first_name: user.firstName || "",
            last_name: user.lastName || "",
            role: user.role || "",
            deactivated: typeof user.discardedAt === "string",
            technician_profile: {
              id: get(user, "technicianProfile.id", ""),
              production_commission: get(user, "technicianProfile.productionCommission", "")
            }
          }}
        />
      </Modal>
    )
  }
}

EditUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps
  if (userId) {
    return { user: build(state.data, "user", userId) }
  }
  return { user: null }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ 
      updateUser,
      updateTechnician,
      getTechnicianProfile
   }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser)
