import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import * as serviceWorker from "serviceWorker"
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import store from "store"
import App from "App"
import "./index.scss"

const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_ID)
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
