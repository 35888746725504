import { CALL_API } from "middleware/api"

const cancelRecurringService = recurringServiceId => ({
  [CALL_API]: {
    endpoint: `/recurring_services/${recurringServiceId}`,
    method: 'post',
  },
})

export { cancelRecurringService }
