import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames/bind"

import styles from "./Row.module.scss"
const cx = classNames.bind(styles)

const FormRow = ({ className, children, ...other }) => (
  <div className={cx('row', className)} {...other}>
    {children}
  </div>
)

FormRow.propTypes = {
  children: PropTypes.node,
}

export default FormRow
