import React from "react"
import { Formik, Field, Form } from "formik"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import FormikTextInput from "components/forms/FormikTextInput"
import FormikCheckbox from "components/forms/FormikCheckbox"
import FormikTextarea from "components/forms/FormikTextarea"
import FormikSelect from "components/forms/FormikSelect"
import RadioButton from "components/forms/RadioButton"
import Button from "components/forms/Button"
import queue from "utils/snackbarQueue"
import deepen from "utils/deepenObject"

import styles from "./NewForm.module.scss"
const cx = classNames.bind(styles)

class NewAccountForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountType: "hoa",
      orgSelected: false
    }
  }

  submitForm = (values, actions) => {
    const { handleSubmit } = this.props
    actions.setSubmitting(true)
    handleSubmit(values)
      .then(() => {
        actions.setSubmitting(false)
        queue.notify({
          body: `Account created for ${values.contact.name}.`,
          timeout: 5000,
          leading: true,
        })
      })
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error creating account' })
      })
  }

  changeAccountType = value => {
    this.setState({ accountType: value }, () => {
      // setFieldValue("sameAddress", value === "hoa")
    })
  }

  handleOrganizationChange = (org, setFieldValue) => {

    let street1, street2, city, state, zipcode

    if(org){
      const { organizations } = this.props
      const selectedOrg = organizations.find(o => o.id === org.value)
      street1 = selectedOrg.street1
      street2 = selectedOrg.street2
      city = selectedOrg.city
      state = selectedOrg.state
      zipcode = selectedOrg.zipcode
      this.setState({ orgSelected: true })
    } else {
      this.setState({ orgSelected: false })
    }
    
    setFieldValue("billing.street1", street1 || "")
    setFieldValue("billing.street2", street2 || "")
    setFieldValue("billing.city", city || "")
    setFieldValue("billing.state", state || "")
    setFieldValue("billing.zipcode", zipcode || "")
  }

  render() {
    const { organizations, commissionSources } = this.props
    const { accountType, orgSelected } = this.state
    const organizationOptions = organizations.map(s => ({
      label: s.name,
      value: s.id,
    }))

    const leadSourceOptions = commissionSources
      .filter(s => !s.discardedAt)
      .map(s => ({
        label: s.name,
        value: s.id,
      }))

    const sameAddressChecked = accountType === "resident"
    const billingAddressDisabled = accountType === "hoa" && orgSelected
    
    return (
      <Formik
        initialValues={{
          hoa_name: "",
          organization: "",
          contact: {
            name: "",
            phone: "",
            email: "",
          },
          billing: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipcode: "",
          },
          sameAddress: sameAddressChecked,
          serviceAddr: {
            name: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipcode: "",
          },
          notes: "",
          monthlyInvoicing: false,
          leadSourceId: "", // empty string for default of "Misc / Accurate"
        }}
        enableReinitialize={true}
        onSubmit={this.submitForm}
        render={({ values, errors, status, touched, isSubmitting }) => {
          const deepenedErrors = deepen(errors)
          const contactErr = deepenedErrors.account_contacts && deepenedErrors.account_contacts.contact
          const billingLocErr = deepenedErrors.billing_location
          const locationErr = deepenedErrors.account_locations && deepenedErrors.account_locations.location

          return (
            <>
              <div className={styles.typeSwitcher}>
                <div className={styles.switcherLabel}>Account Type</div>
                <div className={styles.switcherFields}>
                  <RadioButton
                    value="hoa"
                    className={styles.radio}
                    currentValue={accountType}
                    onChange={val => this.changeAccountType(val)}
                  >
                    HOA
                  </RadioButton>
                  <RadioButton
                    value="resident"
                    className={styles.radio}
                    currentValue={accountType}
                    onChange={val => this.changeAccountType(val)}
                  >
                    Resident
                  </RadioButton>
                </div>
              </div>
              <Form className={styles.form}>
              <div className={styles.formSection}>
                <div className={styles.sectionTitle}>Billing Info</div>

                <div className={styles.sectionFields}>
                
                  {accountType === "hoa" &&
                    <>
                      <Field
                        component={FormikTextInput}
                        className={styles.textInput}
                        id="hoa_name"
                        type="text"
                        name="hoa_name"
                        label="HOA Name"
                      />

                      <Field
                        component={FormikSelect}
                        className={styles.textInput}
                        id="organization"
                        name="organization"
                        icon="domain"
                        placeholder="Organization (optional)"
                        options={organizationOptions}
                        isClearable={true}
                        handleChangeCustom={this.handleOrganizationChange}
                      />
                    </>
                  }

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="contact.name"
                    type="text"
                    name="contact.name"
                    label="Name"
                    icon="person"
                    errorText={contactErr && contactErr.name ? "A name is required" : null}
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="contact.phone"
                    type="text"
                    name="contact.phone"
                    label="Phone number"
                    icon="call"
                    errorText={contactErr && contactErr.phone ? "A phone number is required" : null}
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="contact.email"
                    type="text"
                    name="contact.email"
                    label="Email address"
                    icon="email"
                    errorText={contactErr && contactErr.email ? "A valid email address is required" : null}
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="billing.street1"
                    type="text"
                    name="billing.street1"
                    label="Address 1"
                    disabled={billingAddressDisabled}
                    errorText={billingLocErr && billingLocErr.street1 ? "An address is required" : null}
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="billing.street2"
                    type="text"
                    name="billing.street2"
                    label="Address 2"
                    disabled={billingAddressDisabled}
                  />

                  <div className={styles.cityStateZip}>
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="billing.city"
                      type="text"
                      name="billing.city"
                      label="City"
                      disabled={billingAddressDisabled}
                      invalid={!!(billingLocErr && billingLocErr.city)}
                    />
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="billing.state"
                      type="text"
                      name="billing.state"
                      label="State"
                      disabled={billingAddressDisabled}
                      invalid={!!(billingLocErr && billingLocErr.state)}
                    />
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="billing.zipcode"
                      type="text"
                      name="billing.zipcode"
                      label="ZIP Code"
                      disabled={billingAddressDisabled}
                      invalid={!!(billingLocErr && billingLocErr.zipcode)}
                    />
                  </div>
                  {billingLocErr && (billingLocErr.city || billingLocErr.state || billingLocErr.zipcode)
                    ? <div className={styles.errorText}>
                        City, State, and ZIP Code are required
                      </div> : null}

                  <Field
                    component={FormikCheckbox}
                    wrapperClassName={styles.monthlyBilling}
                    id="monthlyInvoicing"
                    name="monthlyInvoicing"
                    labelText="Monthly Billing"
                  />

                  <div className={styles.notice}>
                    Additional contacts can be added after the account has been created.
                  </div>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={styles.sectionTitle}>Lead Source</div>
                <Field
                  component={FormikSelect}
                  id="leadSourceId"
                  name="leadSourceId"
                  placeholder="Select a lead source"
                  options={leadSourceOptions}
                />
              </div>

              <div className={styles.formSection}>
                <div className={styles.sectionTitle}>Service Location</div>

                <div className={styles.sectionFields}>
                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="serviceAddr.name"
                    type="text"
                    name="serviceAddr.name"
                    label="Location Name (optional)"
                  />

                  <Field
                    component={FormikCheckbox}
                    wrapperClassName={styles.sameAddress}
                    id="sameAddress"
                    name="sameAddress"
                    labelText="Same as billing address"
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="serviceAddr.street1"
                    type="text"
                    name="serviceAddr.street1"
                    label="Address 1"
                    disabled={values.sameAddress}
                    errorText={
                      !values.sameAddress
                      && locationErr
                      && locationErr.street1 ?
                        "An address is required" : null
                    }
                  />

                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    id="serviceAddr.street2"
                    type="text"
                    name="serviceAddr.street2"
                    label="Address 2"
                    disabled={values.sameAddress}
                  />

                  <div className={styles.cityStateZip}>
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="serviceAddr.city"
                      type="text"
                      name="serviceAddr.city"
                      label="City"
                      disabled={values.sameAddress}
                      invalid={!!(locationErr && locationErr.city)}
                    />
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="serviceAddr.state"
                      type="text"
                      name="serviceAddr.state"
                      label="State"
                      disabled={values.sameAddress}
                      invalid={!!(locationErr && locationErr.state)}
                    />
                    <Field
                      component={FormikTextInput}
                      className={styles.textInput}
                      id="serviceAddr.zipcode"
                      type="text"
                      name="serviceAddr.zipcode"
                      label="ZIP Code"
                      disabled={values.sameAddress}
                      invalid={!!(locationErr && locationErr.zipcode)}
                    />
                  </div>
                  {!values.sameAddress
                    && locationErr
                    && (locationErr.city || locationErr.state || locationErr.zipcode) ?
                      <div className={styles.errorText}>
                        City, State, and ZIP Code are required
                      </div> : null}

                  <div className={styles.notice}>
                    Additional service locations can be added after the account has been created.
                  </div>
                </div>
              </div>

              <div className={styles.formSection}>
                <div className={styles.sectionTitle}>Notes</div>

                <div className={styles.sectionFields}>
                  <Field
                    component={FormikTextarea}
                    className={styles.textInput}
                    id="notes"
                    name="notes"
                    rows={5}
                    label="Account Notes (optional)"
                  />
                </div>
              </div>

              <Button
                type="submit"
                className={cx("btn--block", "btn--large", styles.submitButton)}
                disabled={isSubmitting}
              >
                Create Account
              </Button>
            </Form>
            </>
          )
        }}
      />
    )
  }
}

NewAccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
}

export default NewAccountForm
