import React from "react"
import logo from "assets/logo.png"
import styles from "./AuthLayout.module.scss"

const AuthLayout = ({ children }) => (
  <main className={styles.auth}>
    <div className={styles.panel}>
      <img src={logo} alt="Accurate" />
      {children}
    </div>
  </main>
)

export default AuthLayout