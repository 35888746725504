import * as types from "./types"
import { CALL_API } from "middleware/api"

const getAppointments = (start, end) => ({
  [CALL_API]: {
    endpoint: "/appointments?include=account,contact,location,appointment_services",
    method: "get",
  },
})

const getProblemAppointments = () => ({
  [CALL_API]: {
    endpoint: "/appointments/attention?include=account,location,appointment_services",
    method: "get",
  },
})

const getCalendarAppointments = (start, end) => {

  const options = {}
  options.params = { start: start, end: end }

  const successType = types.PAGINATED_OBJECTS_SUCCESS

  return {
    [CALL_API]: {
      endpoint: "/appointments/calendar",
      method: "get",
      options,
      successType
    },
  }
}

const getAppointment = appointmentId => {

  let options = {}
  options.params = { include: "account,contact,location,technician,appointment_services,appointment_services.sales_source,appointment_products,punches" }

  return {
    [CALL_API]: {
      endpoint: `/appointments/${appointmentId}`,
      method: "get",
      options
    }
  }
}

const createAppointment = values => {
  let options = {}
  options["data"] = { appointment: values }

  return {
    [CALL_API]: {
      endpoint: `/appointments`,
      method: "post",
      options,
      successType: types.CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateAppointment = (appointmentId, values) => {
  let options = {}
  options.data = { appointment: values }
  options.params = { include: "account,contact,location,technician,appointment_products,appointment_services,appointment_services.sales_source" }

  const successType = types.UPDATE_OBJECT_SUCCESS

  return {
    [CALL_API]: {
      endpoint: `/appointments/${appointmentId}`,
      method: "patch",
      options,
      successType
    },
  }
}

const rescheduleAppointment = (appointmentId, start) => {
  const options = {}
  options.data = { appointment: { window_start: start } }

  return {
    [CALL_API]: {
      endpoint: `/appointments/${appointmentId}`,
      method: "patch",
      options
    }
  }
}

const cancelAppointment = (appointmentId, cancellation_reason = "") => ({
  [CALL_API]: {
    endpoint: `/appointments/${appointmentId}/cancel`,
    method: "patch",
    options: { data: { cancellation_reason }},
  }
})

const optimisticUpdateAppointment = (appointmentId, appointment) => {
  return dispatch => {
    const params = { type: "appointment", id: appointmentId, data: { attributes: appointment }}
    dispatch({ type: types.UPDATE_NORMALIZED_OBJECT, params })
  }
}

/**
 * @param {String} date YYYY-MM-DD
 * @param {Array} technician_ids
 */
const optimizeAppointments = (date, technician_ids) => {
  let options = {}
  options.data = { date, technician_ids }

  return {
    [CALL_API]: {
      endpoint: "/appointments/optimize",
      method: "post",
      options,
    },
  }
}

export {
  getAppointments,
  getProblemAppointments,
  getCalendarAppointments,
  getAppointment,
  createAppointment,
  updateAppointment,
  rescheduleAppointment,
  cancelAppointment,
  optimisticUpdateAppointment,
  optimizeAppointments,
}
