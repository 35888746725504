import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import get from "lodash/get"

import { getAccount, updateAccount } from "actions"
import { formatPhoneNumber, formatLocation } from "utils/format"
import { getBillingContact, getBillingLocation } from "utils/accounts"
import BillingModal from "./detail/sub-modals/Billing"
import Button from "components/forms/Button"
import SubNav from "components/SubNav"
import Icon from "components/Icon"
import DetailOverview from "./detail/Overview"
import DetailAppointments from "./detail/Appointments"
import DetailInvoices from "./detail/Invoices"
import Loading from "components/Loading"
import stripeMark from "assets/stripe.png"
import styles from "./Detail.module.scss"

class AccountDetail extends React.Component {
  constructor() {
    super()
    this.state = {
      tab: "overview",
      billingModalOpen: false,
      loading: true,
    }
  }

  componentDidMount() {
    const { getAccount, match } = this.props
    getAccount(match.params.id).then(() => this.setState({ loading: false }))
  }

  updateAccount = data => {
    const { account, updateAccount } = this.props
    return updateAccount(account.id, data)
  }

  handleStripeClick = () => {
    const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env
    const customerId = this.props.account.stripeId
    window.open(
      `${REACT_APP_STRIPE_DASHBOARD_URL}/customers/${customerId}`,
      "_blank"
    )
  }

  handleNewAppointment = () => {
    const { account, location, history } = this.props

    // These may or may not be populated depending on how the user got here
    // Just used to pre-populate the New Appointment fields when present
    const { location_id, contact_id } = location.state
    history.push("/appointments/new", {
      modal: true,
      account_id: account.id,
      location_id,
      contact_id,
    })
  }

  closeModals = () => this.setState({ billingModalOpen: false })

  render() {
    const { account } = this.props
    const { tab, billingModalOpen, loading} = this.state

    const billingContact = getBillingContact(account)
    const billingLocation = getBillingLocation(account)

    const headerName = account.name

    return (
      loading ? <Loading /> :
      <div className={styles.detailModal}>
        <header>
          <h3 className={styles.title}>Account {account.number}</h3>
          <div className={styles.headerButtons}>
            <Button
              onClick={() => this.setState({ billingModalOpen: true })}
              className={styles.btn}
              kind="gray"
              small
            >
              Edit Billing
            </Button>
            <Button
              onClick={this.handleNewAppointment}
              className={styles.btn}
              kind="primary"
              small
            >
              New Appointment
              <Icon name="plus" width="12" height="12" />
            </Button>
          </div>
          <div className={styles.billing}>
            <div className={styles.headerName}>
              <span>{headerName}</span>
              {account.stripeId &&
                <img
                  src={stripeMark}
                  alt="Stripe"
                  onClick={this.handleStripeClick}
                />
              }
            </div>
            {account.organization &&
              <div>
                <span className={styles.organization}>
                  {account.organization.name}
                </span>
              </div>
            }
            {billingContact.name !== headerName &&
              <div>
                <span className={styles.contact}>
                  {billingContact.name}
                </span>
              </div>
            }
            <div>
            {formatPhoneNumber(billingContact.phone)}</div>
            <div>{formatLocation(billingLocation)}</div>
            <div className={styles.leadSource}>
              {`Lead Source: ${get(account, "leadSource.name", "Accurate")}`}
            </div>
          </div>
        </header>

        <SubNav
          className={styles.tabs}
          options={["overview", "appointments", "invoices"]}
          handleClick={tab => this.setState({ tab })}
          active={tab}
        />

        {tab === "overview" &&
          <DetailOverview
            account={account}
            updateAccount={this.updateAccount}
          />
        }

        {tab === "appointments" && (
          <DetailAppointments appointments={account.appointments} />
        )}

        {tab === "invoices" && (
          <DetailInvoices account={account} />
        )}

        {billingModalOpen &&
          <BillingModal
            account={account}
            updateAccount={this.updateAccount}
            handleClose={this.closeModals}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const metaUrl = `/accounts/${ownProps.match.params.id}`
  if (state.data.meta[metaUrl]) {
    const account = build(state.data, "account", ownProps.match.params.id)
    const loading = state.data.meta[metaUrl].loading
    return { account, loading }
  }
  return {
    account: {
      accountContacts: [],
      accountLocations: [],
      recurringServices: [],
      appointments: [],
    },
    loading: true,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getAccount,
      updateAccount
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDetail)
