import * as types from "./types"
import { apiCall } from "utils/rawApi"

const getInvoices = params => {
  return dispatch => {

    const options = {}
    options.params = params

    dispatch({ type: types.GET_INVOICES_REQUEST })
    return apiCall("get", "/invoices", options).then(response => {
      dispatch({
        type: types.GET_INVOICES_SUCCESS,
        data: response.data,
      })
    }).catch(error => {
      dispatch({ type: types.GET_INVOICES_FAILURE })
      throw error
    })
  }
}

const clearInvoices = () => {
  return dispatch => {
    dispatch({ type: types.CLEAR_INVOICES })
  }
}

export { getInvoices, clearInvoices }
