import React from "react"
import "react-table/react-table.css"
import Table from "components/Table"

const OrganizationsList = ({ organizations, loading, handleRowClick }) => {

  const columns = [{
    Header: <span>Name</span>,
    accessor: "name",
    sortable: false
  }, {
    id: "street",
    Header: <span>Street Address</span>,
    accessor: d => d.street2 ? `${d.street1}, ${d.street2}` : d.street1,
    sortable: false,
  }, {
    id: "city",
    Header: <span>City</span>,
    accessor: d => d.city,
    maxWidth: 350,
    sortable: true,
  }, {
    id: "state",
    Header: <span>State</span>,
    accessor: d => d.state,
    maxWidth: 200,
    sortable: true,
  }, {
    id: "zipcode",
    Header: <span>Zip Code</span>,
    accessor: d => d.zipcode,
    maxWidth: 150,
    sortable: false,
  }]

  return (
    <Table
      data={organizations}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
    />
  )
}

export default OrganizationsList
