import React from "react"
import styles from "./ResourceHeader.module.scss"

const ResourceHeader = ({ resource }) => (
  <div className={styles.resourceHeader}>
    <strong>{resource.firstName} {resource.lastName}</strong>
    {/* <p style={{color: "#f00"}}>{resource.resourceCapacity}% Capacity</p> */}
  </div>
)

export default ResourceHeader