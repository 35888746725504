import React from "react"
import { Redirect } from "react-router-dom"

const Dashboard = () => {
  return (
    <Redirect to="/appointments/calendar" />
  )
}

export default Dashboard
