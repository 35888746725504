import * as types from "./types"
import { signinUser } from "actions/auth"
import { apiCall } from "utils/rawApi"

const resetPassword = email => {
  return dispatch => {
    const data = { email }
    dispatch({ type: types.FORGOT_PASSWORD_REQUEST })
    return apiCall("post", "passwords", { data: data }).then(response => {

      dispatch({ type: types.FORGOT_PASSWORD_SUCCESS })
    }).catch(err => {
      // API returns 404 without a specific message
      dispatch({ type: types.FORGOT_PASSWORD_ERROR, error: err.response.data.error })
      throw err
    })
  }
}

const updatePassword = args => {
  const data = {
    user: {
      password: args.password,
      password_confirmation: args.password_confirmation,
      email: args.email,
    }
  }
  return dispatch => {
    dispatch({ type: types.FORGOT_PASSWORD_REQUEST })
    return apiCall("patch", `passwords/${args.token}`, { data }).then(response => {
      dispatch({ type: types.FORGOT_PASSWORD_SUCCESS })
      if(!response.data.technician){
        dispatch(signinUser(args.email, args.password))
      }
      return response
    }).catch(err => {
      // API returns 404 without a specific
      dispatch({ type: types.FORGOT_PASSWORD_ERROR, error: err.response.data.error })
      throw err
    })
  }
}

export { resetPassword, updatePassword }
