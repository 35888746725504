import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import PropTypes from "prop-types"

import { getOrganizations, updateOrganization } from "actions"
import Modal from "components/Modal"
import OrganizationForm from "./Form"

class EditOrganization extends React.Component {
  handleUpdate = (values) => {
    const { updateOrganization, organization, handleClose } = this.props
    return updateOrganization(organization.id, values).then(() => {
      this.props.getOrganizations()
      handleClose()
    })
  }

  render() {
    const { organization, handleClose } = this.props

    return (
      <Modal title="Edit Organization" handleClose={handleClose}>
        <OrganizationForm
          editMode
          handleSubmit={this.handleUpdate}
          initialValues={{
            name: organization.name,
            street1: organization.street1 || "",
            street2: organization.street2 || "",
            city: organization.city || "",
            state: organization.state || "",
            zipcode: organization.zipcode || "",
          }}
        />
      </Modal>
    )
  }
}

EditOrganization.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { organizationId } = ownProps
  if (organizationId) {
    return { organization: build(state.data, "organization", organizationId) }
  }
  return { organization: null }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getOrganizations, updateOrganization }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganization)
