import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"

import { getInvoices, clearInvoices } from "actions"
import { formatCurrency } from "utils/format"
import styles from "./Invoices.module.scss"
import Status from "components/billing/Status"
import Loading from "components/Loading"

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env

class DetailInvoices extends Component {

  state = {
    loading: true
  }

  componentDidMount() {
    this.props.clearInvoices()
    const customer = this.props.account.stripeId
    if(customer) {
      this.props.getInvoices({ customer: customer }).then(() => this.setState({loading: false}))
    } else {
      this.setState({loading: false})
    }
    
  }

  componentWillUnmount() {
    this.props.clearInvoices()
  }

  render() {

    const { invoices } = this.props
    const { loading } = this.state

    return (
      loading
      ? <><br /><Loading /></>
      : <div className={styles.invoices}>
          {invoices.length > 0 ? invoices.map(i => (
            <div
              key={i.id}
              className={styles.invoiceRow}
              onClick={() => window.open(
                `${REACT_APP_STRIPE_DASHBOARD_URL}/invoices/${i.id}`,
                "_blank"
              )}
            >
              <span>{moment.unix(i.created).format("MMM D, YYYY")}</span>
              <span className={styles.invoiceNumber}>{i.number}</span>
              <span className={styles.invoiceTotal}>{formatCurrency(i.total/100)}</span>
              <Status status={i.status} />
            </div>
          )) : <div className={styles.noApptsMessage}>No past invoices</div>}
        </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    invoices: state.invoices.data || [],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getInvoices,
      clearInvoices
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailInvoices)
