import { CREATE_OBJECT_SUCCESS, UPDATE_OBJECT_SUCCESS } from "./types"
import { CALL_API } from "middleware/api"

const getCommissionSources = () => ({
  [CALL_API]: {
    endpoint: "/commission_sources",
    method: "get",
  },
})

const getSalesSources = () => ({
  [CALL_API]: {
    endpoint: "/commission_sources/sales",
    method: "get",
  },
})

const createCommissionSource = data => {
  let options = {}
  options["data"] = { commission_source: data }

  return {
    [CALL_API]: {
      endpoint: "/commission_sources",
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateCommissionSource = (sourceId, data) => {
  let options = {}
  options["data"] = { commission_source: data }

  return {
    [CALL_API]: {
      endpoint: `/commission_sources/${sourceId}`,
      method: "patch",
      options,
      successType: UPDATE_OBJECT_SUCCESS,
    },
  }
}

export {
  getCommissionSources,
  getSalesSources,
  createCommissionSource,
  updateCommissionSource
}
