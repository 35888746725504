import React, { Component } from "react"
import { Link, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { signinUser, clearSignInError } from "actions/auth"
import AuthLayout from "components/AuthLayout"
import Button from "components/forms/Button"
import Form from "components/forms/Form"
import FormItem from "components/forms/FormItem"
import TextInput from "components/forms/TextInput"
import ErrorMessage from "components/ErrorMessage"
import styles from "./Signin.module.scss"

class SigninRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
    }
  }

  getRedirectPath = () => {
    const locationState = this.props.location.state
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname // redirects to referring url
    } else {
      return "/appointments"
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.signinUser(this.state.email, this.state.password).catch(() => {
      this.setState({ password: "" })
    })
  }

  onChange = e => {
    const target = e.target
    this.setState({
      [target.name]: target.value
    })
  }

  render() {

    const { errorMessage, authenticated } = this.props

    return (
      authenticated
      ?
        <Redirect to={{
          pathname: this.getRedirectPath(), state: {
            from: this.props.location
          }
        }}/>
      :
        <AuthLayout>
          {errorMessage && <ErrorMessage text={errorMessage} />}
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              <TextInput
                className={styles.input}
                id="email"
                name="email"
                value={this.state.email}
                type="text"
                onChange={this.onChange}
                label="Email"
              />
            </FormItem>
            <FormItem>
              <TextInput
                className={styles.input}
                id="password"
                name="password"
                value={this.state.password}
                type="password"
                onChange={this.onChange}
                label="Password"
              />
            </FormItem>
            <Button
              className="btn--block btn--large"
              type="submit"
              kind="primary"
              disabled={this.props.isLoading}
              large
            >
              Log In
            </Button>
          </Form>
          <div className={styles.forgot}>
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
        </AuthLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.isLoading,
    errorMessage: state.auth.errorMessage,
    authenticated: state.auth.authenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      signinUser,
      clearSignInError,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigninRoute)
