import * as types from "./types"
import { signinUser } from "actions/auth"
import { apiCall } from "utils/rawApi"

const acceptInvitation = args => {
  const data = {
    user: {
      password: args.password,
      password_confirmation: args.password_confirmation,
      email: args.email,
    }
  }
  return dispatch => {
    dispatch({ type: types.ACCEPT_INVITATION_REQUEST })
    return apiCall("post", `invitations/${args.token}/accept`, { data }).then(response => {
      dispatch({ type: types.ACCEPT_INVITATION_SUCCESS })
      if(!response.data.technician){
        dispatch(signinUser(args.email, args.password))
      }
      return response
    }).catch(err => {
      // API returns 404 without a specific message
      dispatch({ type: types.ACCEPT_INVITATION_ERROR, error: err.response.data.error })
      throw err
    })
  }
}

export { acceptInvitation }
