import React from "react"
import "./Loading.scss"

const Loading = () => {
  return (
    <div className="loading">
      <p>Loading...</p>
    </div>
  )
}

export default Loading
