import { CREATE_OBJECT_SUCCESS } from "./types"
import { CALL_API } from "middleware/api"

const getServices = () => ({
  [CALL_API]: {
    endpoint: "/services",
    method: "get",
  },
})

const createService = params => {
  const options = {}
  options.data = params

  return {
    [CALL_API]: {
      endpoint: `/services`,
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateService = (serviceId, params) => {
  const options = {}
  options.data = { service: params }

  return {
    [CALL_API]: {
      endpoint: `/services/${serviceId}`,
      method: 'patch',
      options: options
    },
  }
}

export { getServices, createService, updateService }
