import React from "react"
import moment from "moment"

import { formatCurrency } from "utils/format"
import styles from "./RecurringService.module.scss"

const RecurringService = ({ recurringService, onDelete }) => {

  const nextAppointment = moment(recurringService.nextOccurence).format("L")

  return ( 
    <div className={styles.row}>
      <div className={styles.service}>{recurringService.name}</div>
      <div className={styles.nextAppointment}>Next appt. {nextAppointment}</div>
      <div className={styles.price}>{formatCurrency(parseFloat(recurringService.price))}</div>
      <div className={styles.action}>
        <i
          className="material-icons"
          onClick={() => onDelete(recurringService.id)}
        >
          delete
        </i>
      </div>
    </div>
  )
}

export default RecurringService
