import React from "react"
import DatePicker from "react-datepicker"
import classNames from "classnames/bind"

import "react-datepicker/dist/react-datepicker.css"
import styles from "./FormikDatePicker.module.scss"
const cx = classNames.bind(styles)

const FormikDatePicker = props => {

  const { field, form, wrapperClassName } = props

  const selectedDate = field.value && field.value !== ""
    ? new Date(field.value)
    : ""

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <i className={cx("material-icons", styles.icon)}>
        event
      </i>

      <DatePicker
        id={props.id}
        name={field.name}
        selected={selectedDate}
        onChange={selectedDate => form.setFieldValue(field.name, selectedDate)}
        placeholderText={props.placeholder}
        popperClassName={cx(styles.popper)}
        calendarClassName="calendar"
        autoComplete="off"
        className={styles.datePicker}
        dateFormat={props.dateFormat}
      />
    </div>
  )
}

export default FormikDatePicker
