import axios from "axios"
import store from "store"
import { signoutUser } from "actions/auth"
import normalize from "json-api-normalizer"

const API_ROOT = process.env.REACT_APP_API_URL

export const API_DATA_REQUEST = "API_DATA_REQUEST"
export const API_DATA_SUCCESS = "API_DATA_SUCCESS"
export const API_DATA_SUCCESS_DEDUPLICATE = "API_DATA_SUCCESS_DEDUPLICATE"
export const API_DATA_FAILURE = "API_DATA_FAILURE"

function apiHeaders(authenticated=true) {
  let headers = { "Content-Type":"application/json" }
  if(authenticated) {
    const jwt = localStorage.getItem("sessionToken")
    if(jwt !== undefined) {
      headers["Authorization"] = `Bearer ${jwt}`
    }
  }
  return headers
}

function callApi(endpoint, method, options = {}, authenticated=true) {
  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signoutUser())
        }
      }
      return Promise.reject(error)
    }
  )

  return axios.request({
    method: method,
    url: fullUrl,
    headers: apiHeaders(authenticated),
    params: options.params,
    data: JSON.stringify(options.data)
  }).then(response => {
    if (![200, 201, 204].includes(response.status)) {
      return Promise.reject(response.data)
    }
    return Object.assign({}, normalize(response.data, { endpoint }))
  })
}

export const CALL_API = Symbol("Call API")

export default function (store) {
  return function nxt(next) {
    return function call(action) {
      const callAPI = action[CALL_API]

      if (typeof callAPI === "undefined") {
        return next(action)
      }

      let { endpoint, method } = callAPI;
      const { options, successType } = callAPI

      if (typeof endpoint === "function") {
        endpoint = endpoint(store.getState())
      }

      if (typeof endpoint !== "string") {
        throw new Error("Specify a string endpoint URL.");
      }

      const actionWith = data => {
        const finalAction = Object.assign({}, action, data)
        delete finalAction[CALL_API]
        return finalAction
      }

      next(actionWith({ type: API_DATA_REQUEST, endpoint, method, options }))

      return callApi(endpoint, method, options || {})
        .then(
          response => next(actionWith({
            response,
            type: successType || API_DATA_SUCCESS,
            endpoint,
          }))
        ).catch(error => {
          next(actionWith({
            type: API_DATA_FAILURE,
            error: error.message || "Something bad happened",
            endpoint,
          }))
          throw error
        })
    }
  }
}
