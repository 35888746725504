import React from "react"
import { Formik, Field, Form } from "formik"

import FormikTextInput from "components/forms/FormikTextInput"
import FormikTextarea from "components/forms/FormikTextarea"
import Select from "components/forms/FormikSelect"
import Button from "components/forms/Button"
import FormRow from "components/forms/Row"
import PropTypes from "prop-types"

import styles from "./Form.module.scss"

class ServiceForm extends React.Component {
  submitForm = (values, actions) => {
    const { handleSubmit } = this.props
    actions.setSubmitting(true)

    const newValues = {
      ...values,
      price: +(parseFloat(values.price*100).toFixed(2))
    }

    handleSubmit(newValues)
      .then(() => actions.setSubmitting(false))
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error submitting service' })
      })
  }

  render() {
    const { editMode, initialValues } = this.props
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ values, errors, status, touched, isSubmitting }) => (
          <Form className={styles.serviceForm}>
            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="name"
              type="text"
              name="name"
              label="Name"
              errorText={errors.name ? "A service name is required" : null}
            />

            <Field
              component={FormikTextarea}
              className={styles.textarea}
              placeholder="Description"
              name="description"
              rows={6}
            />

            <FormRow className={styles.formRow}>
              <Field
                component={FormikTextInput}
                id="price"
                type="text"
                name="price"
                label="Suggested Price"
              />

              <Field
                component={FormikTextInput}
                id="service_code"
                type="text"
                name="service_code"
                label="Service Code"
                errorText={errors.service_code ? "A service code is required" : null}
              />
            </FormRow>

            <Field
              component={Select}
              className={styles.select}
              name="recurrence"
              placeholder="Recurrence Schedule"
              options={[
                { label: "Never Repeat", value: "" },
                { label: "Repeat Monthly", value: "monthly" },
                { label: "Repeat Quarterly", value: "quarterly" },
              ]}
            />

            <Button type="submit" className="btn--block btn--large" disabled={isSubmitting}>
              {editMode ? "Update Service" : "Create New Service"}
            </Button>
          </Form>
        )}
      />
    )
  }
}

ServiceForm.defaultProps = {
  editMode: false,
}

ServiceForm.propTypes = {
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
    service_code: PropTypes.string,
  }).isRequired
}

export default ServiceForm
