import React from "react"
import Table from "components/Table"
import "react-table/react-table.css"

const ReportTable = ({ reports, loading, handleRowClick, className }) => {

  const columns = [{
    Header: <span>Product Name</span>,
    accessor: "product",
  }, {
    Header: <span>EPA Reg #</span>,
    accessor: "epaCode",
  }, {
    Header: <span>Manufacturer</span>,
    accessor: "manufacturer",
  }, {
    Header: <span>Total Applications</span>,
    accessor: "applications",
    width: 170,
  }, {
    id: "quantity",
    Header: <span>Dil. Qty Used</span>,
    accessor: d => `${d.amount} ${d.unit}`,
    width: 125,
  }]

  return (
    <Table
      data={reports}
      columns={columns}
      loading={loading}
      className={className}
      handleRowClick={handleRowClick}
    />
  )
}

export default ReportTable
