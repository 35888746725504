import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"
import classNames from "classnames/bind"

import { optimizeAppointments } from "actions/appointments"
import { setOptimizing } from "actions/optimizer"
import Modal from "components/Modal"
import Button from "components/forms/Button"
import queue from "utils/snackbarQueue"

import styles from "./OptimizeModal.module.scss"
const cx = classNames.bind(styles)

const OptimizeModal = ({
  optimizeAppointments,
  isOptimizing,
  setOptimizing,
  optimizeDate,
  selectedTechnicians,
  handleClose
}) => (
  <Modal
    title="Optimize Schedule"
    className={styles.optimizeModal}
    handleClose={handleClose}
  >
    <p className={styles.modalText}>
      This will automatically adjust start times for recurring
      appointments to reduce the amount of time between appointments.
      Appointments will only be moved to different times on the same
      day and technician they are already scheduled for.
    </p>

    <ul className={cx(styles.list, styles.modalText)}>
      <li>{moment(optimizeDate).format("dddd, MMMM Do, YYYY")}</li>
      <li>
        {selectedTechnicians.length === 1
          ? "1 technician"
          : `${selectedTechnicians.length} technicians`
        }
      </li>
    </ul>

    <Button
      type="submit"
      large
      disabled={isOptimizing}
      className={cx(
        "btn--block",
        styles.optimizeButton,
        { optimizing: isOptimizing }
      )}
      onClick={() => {
        setOptimizing(true)
        optimizeAppointments(optimizeDate, selectedTechnicians)
          .then(() => {
            queue.notify({
              body: "Optimization complete",
              timeout: 5000,
              leading: true,
            })
            setOptimizing(false)
            handleClose()
          })
          .catch(e => {
            console.error("Problem optimizing appointments", e)
            queue.notify({
              body: "Error while optimizing",
              timeout: 5000,
              leading: true,
            })
            setOptimizing(false)
          })
      }}
    >
      {isOptimizing ? "Optimizing..." : "Optimize"}
    </Button>
  </Modal>
)

const mapStateToProps = state => ({
  isOptimizing: state.CSR_GLOBALS.isOptimizing,
  optimizeDate: state.CSR_GLOBALS.optimizeDate,
  selectedTechnicians: state.CSR_GLOBALS.selectedTechnicians,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      optimizeAppointments,
      setOptimizing,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimizeModal)
