import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"
import PropTypes from "prop-types"

import CalendarContext from "components/appointments/calendar/Context"
import { createAppointment, getCalendarAppointments } from "actions"
import AppointmentFormSwitcher from "./FormSwitcher"

class NewAppointment extends Component {

  handleCreate = values => {
    const { createAppointment, getCalendarAppointments, handleClose } = this.props

    const selectedDate = moment(values.window_start).format("YYYY-MM-DD")

    return createAppointment(values).then(() => {
      getCalendarAppointments(selectedDate)
      handleClose()
    })
  }

  render() {
    const { account_id, location_id, contact_id } = this.props.location.state

    return (
      <CalendarContext.Consumer>
        {({ info }) => (
          <AppointmentFormSwitcher
            handleSubmit={this.handleCreate}
            initialValues={{
              account_id: account_id ? String(account_id) : "",
              location_id: location_id ? String(location_id) : "",
              contact_id: contact_id ? String(contact_id) : "",
              appointment_services: [],
              estimated_duration: "",
              technician_ids: [(info && info.resourceId) || ""],
              instructions: "",
              customer_notes: "",
              date: (info && info.start) || "",
              time: (info && info.start) || "",
              fixed: true,
            }}
          />
        )}
      </CalendarContext.Consumer>
    )
  }
}

NewAppointment.propTypes = {
  createAppointment: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      createAppointment,
      getCalendarAppointments,
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NewAppointment)
