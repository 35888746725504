import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import PropTypes from "prop-types"

import { updateCommissionSource } from "actions"
import Modal from "components/Modal"
import CommissionSourceForm from "./Form"

class EditCommissionSource extends React.Component {
  handleUpdate = values => {
    const { updateCommissionSource, commissionSource, handleClose } = this.props
    return updateCommissionSource(commissionSource.id, values).then(handleClose)
  }

  render() {
    const { commissionSource, users, handleClose } = this.props

    return (
      <Modal title="Edit Commission Source" handleClose={handleClose}>
        <CommissionSourceForm
          editMode
          users={users}
          handleSubmit={this.handleUpdate}
          initialValues={{
            name: commissionSource.name || "",
            category: commissionSource.category || "",
            sales: !!(commissionSource.sales),
            deactivated: typeof commissionSource.discardedAt === "string",
          }}
        />
      </Modal>
    )
  }
}

EditCommissionSource.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { sourceId } = ownProps
  if (sourceId) {
    return { commissionSource: build(state.data, "commissionSource", sourceId) }
  }
  return { commissionSource: null }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateCommissionSource }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCommissionSource)
