import React from "react"
import Table from "components/Table"
import { Badge, COLORS } from "components/Badge"
import "react-table/react-table.css"
import "./List.scss"

const CommissionSourcesList = ({ commissionSources, loading, handleRowClick }) => {
  const columns = [{
    Header: <span>Name</span>,
    accessor: "name",
  }, {
    Header: <span>Category</span>,
    accessor: "category",
    Cell: ({ row, original }) => {
      return (
        <>
          <span className="content">{original.category}</span>
          {original.discardedAt
            ? <Badge color={COLORS.red}>Deactivated</Badge>
            : null
          }
        </>
      )
    },
    className: "category"
  }, {
    id: "salesSource",
    Header: <span>Also a sales source?</span>,
    accessor: d => d.sales ? "Yes" : "No",
    width: 200,
  }]

  return (
    <Table
      data={commissionSources}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
    />
  )
}

export default CommissionSourcesList
