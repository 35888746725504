import React from "react"
import moment from "moment"
import orderBy from "lodash/orderBy"
import { withRouter } from "react-router-dom"

import Status from "components/appointments/Status"
import { formatCurrency } from "utils/format"
import styles from "./Appointments.module.scss"

const DetailAppointments = ({ appointments, history }) => {

  const sortedAppointments = orderBy(appointments, ["windowStart"], ["desc"])

  return (
    <div className={styles.appointments}>
      {sortedAppointments.length > 0 ? sortedAppointments.map(a => (
        <div
          key={a.id}
          className={styles.appointmentRow}
          onClick={() => history.push(`/appointments/${a.id}`, { modal: true })}
        >
          <span>{moment(a.windowStart).format("MMM D, YYYY")}</span>
          <span className={styles.serviceList}>
            {a.appointmentServices.map(s => s.name).join(", ")}
          </span>
          <span>{formatCurrency(a.amount)}</span>
          <Status status={a.state} />
        </div>
      )) : <div className={styles.noApptsMessage}>No past appointments</div>}
    </div>
  )
}

export default withRouter(DetailAppointments)
