import React from "react"
import PropTypes from "prop-types"
import "./Label.scss"

const Label = ({ htmlFor, children, ...other }) => {

  return (
    <label className="label" htmlFor={htmlFor} {...other}>
      {children}{" "}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string
}

export default Label