import { CALL_API } from "middleware/api"

const getTechnicians = () => ({
  [CALL_API]: {
    endpoint: "/technicians",
    method: "get",
  },
})

const updateTechnician = (technicianId, params) => {
  const options = {}
  options.data = { technician: params }

  return {
    [CALL_API]: {
      endpoint: `/technicians/${technicianId}`,
      method: "patch",
      options: options
    },
  }
}

export { getTechnicians, updateTechnician }
