import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getLocations, clearLocations } from "actions"
import build from "redux-object"
import debounce from "lodash/debounce"

import Header from "components/Header"
import SubNav from "components/SubNav"
import SearchInput from "components/forms/SearchInput"
import Content from "components/Content"
import LocationsList from "components/locations/List"

class CustomerLocationsRoute extends Component {
  state = { 
    loading: true,
    searchString: "" 
  }

  componentDidMount() {
    this.fetchLocations()
  }

  openAccountDetails = (accountId, location_id) => {
    this.props.history.push(`/accounts/${accountId}`, {
      modal: true,
      location_id,
    })
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const location = rowInfo.original
    const accountId = location.accountId
    this.openAccountDetails(accountId, location.id)
  }

  fetchLocations(page=1) {
    const { searchString } = this.state
    this.props.getLocations(page, searchString).then(() => this.setState({ loading: false }))
  }

  handleSearchChange = e => {
    const value = e.target.value
    this.setState({ searchString: value }, () => {
      if(this.state.searchString.length === 0) {
        this.props.clearLocations()
      }
      this.updateSearch()
    })
  }

  updateSearch = debounce(() => {
    this.fetchLocations()
  }, 500)

  handleNextPage = () => {
    const { currentPage, totalPages } = this.props
    if(currentPage < totalPages) {
      const page = currentPage + 1
      this.fetchLocations(page)
    }
  }

  render() {
    const { locations, subNavOptions } = this.props
    const { loading } = this.state

    return (
      <>
        <Header>
          <h1>Customers</h1>
        </Header>

        <SubNav {...subNavOptions}>
          <SearchInput
            onChange={this.handleSearchChange}
            placeholder="Search Locations"
          />
        </SubNav>

        <Content>
          <LocationsList
            locations={locations}
            loading={loading}
            handleRowClick={this.handleRowClick}
            handleNextPage={this.handleNextPage}
          />
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const url = `/locations`
  if(state.data.meta[url]) {
    const metaUrl = state.data.meta[url]
    const currentPage = metaUrl.meta && metaUrl.meta.currentPage
    const totalPages = metaUrl.meta && metaUrl.meta.totalPages
    const locations = (metaUrl.data || []).map(object => build(state.data, 'location', object.id))
    return { locations, currentPage, totalPages }
  }

  return { locations: [] }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getLocations,
      clearLocations,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLocationsRoute)
