import React, { useState } from "react"
import { Route } from "react-router-dom"
import SideModal from "components/SideModal"

const SideModalRoute = ({ component: Component, title, handleClose, ...rest }) => {

  const [locked, toggleLocked] = useState(false)

  return (
    <Route {...rest} render={props => (
      <SideModal title={title} handleClose={handleClose} locked={locked}>
        <Component 
          handleClose={handleClose} 
          toggleLocked={() => toggleLocked(!locked)} 
          {...props} 
        />
      </SideModal>
    )} />
  )
}

export default SideModalRoute