import { combineReducers } from "redux"
import auth from "./auth"
import currentUser from "./currentUser"
import data from "./data"
import invitations from "./invitations"
import invoices from "./invoices"
import passwords from "./passwords"
import CSR_GLOBALS from "./csrGlobals"

const rootReducer = combineReducers({
  auth,
  currentUser,
  data,
  invitations,
  invoices,
  passwords,
  CSR_GLOBALS,
})

export default rootReducer
