import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "./StatusBar.module.scss"

const StatusBar = ({ status }) => {

  const cx = classNames.bind(styles)

  const active = ["scheduled", "in_progress", "completed", "invoiced"].includes(status)
  
  const cancelledClass = cx({ cancelled: true, segment: true })
  const problemClass = cx({ problem: true, segment: true })
  const scheduledClass = cx({ complete: true, segment: true })
  const inProgressClass = cx({ complete: ["in_progress", "completed", "invoiced"].includes(status), segment: true })
  const completeClass = cx({ complete: ["completed", "invoiced"].includes(status), segment: true })
  const invoicedClass = cx({ complete: status === "invoiced", segment: true })

  return (
    <ul className={styles.statusBar}>
      {status === "cancelled" &&
        <li className={cancelledClass}>
          Cancelled
        </li>
      }

      {status === "problem" &&
        <li className={problemClass}>
          Problem
        </li>
      }

      {active &&
        <>
          <li className={scheduledClass}>
            Scheduled
          </li>
          <li className={inProgressClass}>
            In Progress
          </li>
          <li className={completeClass}>
            Complete
          </li>
          <li className={invoicedClass}>
            Invoiced
          </li>
        </>
      }
    </ul>
  )
}

StatusBar.propTypes = {
  status: PropTypes.string.isRequired,
}

export default StatusBar
