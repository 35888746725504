import React, { useEffect } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"

import { getProblemAppointments } from "actions"
import Status from "./Status"
import Table from "components/Table"
import SubNav from "components/SubNav"
import Content from "components/Content"
import styles from "./ProblemList.module.scss"
import "react-table/react-table.css"

const getReasonText = appointment => {
  let reasonText = "--"
  if (appointment.state === "scheduled" || appointment.state === "in_progress") {
    reasonText = "Overdue"
  } else if (appointment.state === "problem") {
    reasonText = "Reschedule"
  }
  return reasonText
}

const ProblemAppointmentList = ({
  getProblemAppointments,
  appointments,
  loading,
  handleRowClick,
  subNavOptions
}) => {
  useEffect(() => {
    async function fetchData() {
      getProblemAppointments()
    }
    fetchData()
  }, [getProblemAppointments])

  const formatTechnicians = technician => {
    return `${technician.firstName.charAt(0)}. ${technician.lastName}`
  }

  const formatLocation = location => {
    let address = `${location.street1}, ${location.city}, ${location.state}, ${location.zipcode}`
    if (location.name) address = `${location.name} - ${address}`
    return address
  }

  const formatServices = services => {
    if(!services) return ""
    return services.map(s => s.name).join(", ")
  }

  const columns = [{
    id: "appointmentDateTime",
    Header: <span>Date & Time</span>,
    Cell: row => (
      <div className={styles.dateTime}>
        <div>
          <span className={styles.date}>{moment(row.original.windowStart).format("MMM D")}</span>
          <span>{moment(row.original.windowStart).format("h:mma")}</span>
          <span className={styles.timeHyphen}>-</span>
          <span>{moment(row.original.windowEnd).format("h:mma")}</span>
        </div>
        <div className={styles.recurrence}>
          {row.original.hasRecurringServices &&
            <i className="material-icons">autorenew</i>
          }
        </div>
      </div>
    ),
    width: 180,
  }, {
    id: "appointmentAccount",
    Header: <span>Account</span>,
    Cell: row => (
      <div className={styles.accountName}>{row.original.account.name}</div>
    ),
    accessor: d => d.account.name
  }, {
    id: "appointmentLocation",
    Header: <span>Service Location</span>,
    accessor: d => formatLocation(d.location)
  }, {
    id: "appointmentServices",
    Header: <span>Services</span>,
    accessor: d => formatServices(d.appointmentServices)
  }, {
    id: "appointmentTechnicians",
    className: "technicianCell",
    Header: <span>Technician</span>,
    Cell: row => (
      <div className={styles.technician}>
        <span style={{ backgroundColor: `#${row.original.color}`}}>{formatTechnicians(row.original.technician)}</span>
      </div>
    ),
    accessor: d => d.technician.lastName,
    width: 180
  }, {
    id: "reason",
    Header: <span>Reason</span>,
    accessor: d => (
      <Status status={d.state} displayText={getReasonText(d)} />
    ),
    headerClassName: styles.reason,
    className: styles.reason,
    width: 120
  }]

  return (
    <>
      <SubNav {...subNavOptions} />

      <Content>
        <Table
          data={appointments}
          columns={columns}
          loading={loading}
          handleRowClick={handleRowClick}
        />
      </Content>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const url = "/appointments/attention"
  if(state.data.meta[url]) {
    const appointments = (state.data.meta[url].data || []).map(object => build(state.data, 'appointment', object.id))
    const loading = state.data.meta[url].loading
    return { appointments, loading }
  }

  return { appointments: [], loading: true }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getProblemAppointments,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblemAppointmentList)
