import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { cancelRecurringService } from "actions"

import NotesModal from "./sub-modals/Notes";
import ContactModal from "./sub-modals/Contact";
import LocationModal from "./sub-modals/Location";
import RecurringService from "./RecurringService"
import Button from "components/forms/Button"
import { Badge, COLORS } from "components/Badge"
import { formatPhoneNumber, formatLocation, formatCurrency } from "utils/format"
import queue from "utils/snackbarQueue"
import styles from "./Overview.module.scss"

class DetailOverview extends React.Component {
  state = {
    contactModalOpen: false,
    locationModalOpen: false,
    contactIdToEdit: null,
    locationIdToEdit: null,
  }

  updateAccount = data => {
    return this.props.updateAccount(data)
  }

  closeModals = () => {
    this.setState({
      notesModalOpen: false,
      contactModalOpen: false,
      locationModalOpen: false,
      accountContactToEdit: null,
      accountLocationToEdit: null,
    })
  }

  deleteRecurringService = recurringServiceId => {
    if(window.confirm(`
      Are you sure you want to cancel this Recurring Service?\n
      - Future appointments will no longer be created.\r
      - You should cancel future appointments as needed.\r
      - Customers will have to schedule a new recurring service.`)) {
      this.props.cancelRecurringService(recurringServiceId).then(() => {
        queue.notify({
          body: "Recurring Service cancelled",
          timeout: 5000,
          leading: true,
        })
      })
    }
  }

  render() {
    const { account } = this.props
    const {
      notesModalOpen,
      contactModalOpen,
      locationModalOpen,
      accountContactToEdit,
      accountLocationToEdit,
    } = this.state

    const contactRows = account.accountContacts.map(accountContact => {
      const { contact } = accountContact

      return (
        <div key={contact.id} className={styles.detailRow}>
          <div className={styles.detailTextStack}>
            <div>{contact.name}</div>
            <div>{contact.email}</div>
          </div>

          <div className={styles.center}>
            {formatPhoneNumber(contact.phone)}
          </div>

          <div className={styles.end}>
            {accountContact.billing &&
              <Badge
                className={styles.badge}
                color={COLORS.orange}
              >
                Billing
              </Badge>
            }
            {accountContact.primary &&
              <Badge
                className={styles.badge}
                color={COLORS.gray}
              >
                Primary
              </Badge>
            }
            <i
              className="material-icons"
              onClick={() => {
                this.setState({
                  contactModalOpen: true,
                  accountContactToEdit: accountContact,
                })
              }}
            >
              edit
            </i>
          </div>
        </div>
      )
    })

    const recurringServiceRows = account.recurringServices
      .filter(rs => !rs.cancelled)
      .map(recurringService =>
        <RecurringService
          key={recurringService.id}
          recurringService={recurringService}
          onDelete={this.deleteRecurringService}
        />
    )

    const locationRows = account.accountLocations
      .filter(al => !al.billing)
      .map(accountLocation => {
        const { location } = accountLocation

        return (
          <div key={location.id} className={styles.detailRow}>
            <div className={styles.locationText}>
              {location.name && (
                <div className={styles.locationName}>{location.name}</div>
              )}
              {formatLocation(location)}
            </div>

            <div className={styles.end}>
              {accountLocation.billing &&
                <Badge
                  className={styles.badge}
                  color={COLORS.orange}
                >
                  Billing
                </Badge>
              }
              {accountLocation.primary &&
                <Badge
                  className={styles.badge}
                  color={COLORS.gray}
                >
                  Primary
                </Badge>
              }
              <i
                className="material-icons"
                onClick={() => {
                  this.setState({
                    locationModalOpen: true,
                    accountLocationToEdit: accountLocation,
                  })
                }}
              >
                edit
              </i>
            </div>
          </div>
        )
      })

    return (
      <div className={styles.overview}>
        <div className={styles.bigMoneyContainer}>
          <div className={styles.bigMoney}>
            <h1>{formatCurrency(account.balance/100)}</h1>
            <h5>Outstanding Balance</h5>
          </div>
          <div className={styles.bigMoney}>
            <h1>{formatCurrency(account.credit/100)}</h1>
            <h5>Account Credit</h5>
          </div>
        </div>

        <section>
          <header>
            <h4>Notes</h4>
            <Button
              className={styles.btn}
              kind="link"
              small
              onClick={() => this.setState({ notesModalOpen: true })}
            >
              Edit Notes
            </Button>
          </header>
          <p className={styles.notes}>{account.notes}</p>
        </section>

        <section>
          <header>
            <h4>Contacts</h4>
            <Button
              onClick={() => this.setState({ contactModalOpen: true })}
              className={styles.btn}
              kind="link"
              small
            >
              Add Contact <b>+</b>
            </Button>
          </header>

          {contactRows}
        </section>

        {account.recurringServices.length > 0 &&
          <section>
            <header>
              <h4>Recurring Services</h4>
            </header>
            {recurringServiceRows}
          </section>
        }

        <section>
          <header>
            <h4>Service Locations</h4>
            <Button
              onClick={() => this.setState({ locationModalOpen: true })}
              className={styles.btn}
              kind="link"
              small
            >
              Add Location <b>+</b>
            </Button>
          </header>

          {locationRows}
        </section>

        {account.legacyId &&
          <section className={styles.legacy}>
            <span>{`Legacy ID: ${account.legacyId}`}</span>
          </section>
        }

        {notesModalOpen &&
          <NotesModal
            handleClose={this.closeModals}
            updateAccount={this.updateAccount}
            account={account}
          />
        }
        {locationModalOpen &&
          <LocationModal
            accountId={account.id}
            accountLocation={accountLocationToEdit}
            handleClose={this.closeModals}
          />
        }
        {contactModalOpen &&
          <ContactModal
            accountId={account.id}
            accountContact={accountContactToEdit}
            handleClose={this.closeModals}
          />
        }
      </div>
    )
  }
}

DetailOverview.propTypes = {
  account: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      cancelRecurringService,
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(DetailOverview)
