import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import PropTypes from "prop-types"

import { getServices, updateService } from "actions"
import Modal from "components/Modal"
import ServiceForm from "./Form"

class EditService extends React.Component {
  handleUpdate = (values) => {
    const { updateService, service, handleClose } = this.props
    return updateService(service.id, values).then(() => {
      this.props.getServices()
      handleClose()
    })
  }

  render() {
    const { service, handleClose } = this.props

    return (
      <Modal title="Edit Service" handleClose={handleClose}>
        <ServiceForm
          editMode
          handleSubmit={this.handleUpdate}
          initialValues={{
            name: service.name,
            description: service.description || "",
            service_code: service.serviceCode,
            recurrence: service.recurrence || "",
            price: parseFloat(service.price).toFixed(2),
          }}
        />
      </Modal>
    )
  }
}

EditService.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { serviceId } = ownProps
  if (serviceId) {
    return { service: build(state.data, "service", serviceId) }
  }
  return { service: null }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getServices, updateService }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditService)
