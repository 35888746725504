import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const Form = ({ className, children, ...other }) => {
  const classNames = classnames('form', className)

  return (
    <form className={classNames} {...other}>
      {" "}
      {children}{" "}
    </form>
  )
}

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Form