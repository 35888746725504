import React from "react"

const Event = ({ event }) => (
  <>
    <p>{event.title}</p>
    <p>{event.location}</p>
  </>
)

export default Event
