import React from "react"
import { Formik, Field, Form } from "formik"
import FormikTextInput from "components/forms/FormikTextInput"
import Label from "components/forms/Label"
import Button from "components/forms/Button"
import FormikCheckbox from "components/forms/FormikCheckbox"
import Select from "components/forms/FormikSelect"
import PropTypes from "prop-types"

import styles from "./Form.module.scss"

class CommissionSourceForm extends React.Component {
  submitForm = (values, actions) => {
    const { handleSubmit } = this.props
    actions.setSubmitting(true)
    handleSubmit(values)
      .then(() => actions.setSubmitting(false))
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: "Error submitting commission source" })
      })
  }

  render() {
    const { users, editMode, initialValues } = this.props
    const userOptions = users.map(u => ({
      label: `${u.firstName} ${u.lastName}`,
      value: `${u.firstName} ${u.lastName}`,
    }))

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ values, errors, isSubmitting }) => {
          // Show user select if "employee" category is chosen, text field otherwise
          const nameField = values.category === "Employee" ? (
            <>
              <Label htmlFor="name">Name</Label>
              <Field
                component={Select}
                id="name"
                name="name"
                placeholder="Select an employee"
                options={userOptions}
                invalid={!!(errors.name)}
                allowWriteIns
              />
              {errors.name &&
                <div className={styles.errorText}>
                  {errors.name[0]}
                </div>
              }
            </>
          ) : (
            <>
              <Label htmlFor="name">Name</Label>
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                type="text"
                id="name"
                name="name"
                required
                errorText={errors.name ? errors.name[0] : null}
              />
            </>
          )

          return (
            <Form className={styles.commissionSourceForm}>
              <Label htmlFor="category">Category</Label>
              <Field
                component={Select}
                name="category"
                placeholder="Select a Category"
                options={[
                  { label: "Employee", value: "Employee" },
                  { label: "Internet", value: "Internet" },
                  { label: "Miscellaneous", value: "Miscellaneous" },
                ]}
                invalid={!!(errors.category)}
              />
              {errors.category &&
                <div className={styles.errorText}>
                  A category is required
                </div>
              }

              {nameField}

              <Field
                wrapperClassName={styles.checkbox}
                component={FormikCheckbox}
                id="sales"
                name="sales"
                labelText="Also a sales source"
              />

              {editMode &&
                <Field
                  wrapperClassName={styles.deactivateButton}
                  component={FormikCheckbox}
                  id="deactivated"
                  name="deactivated"
                  labelText="Deactivated"
                />
              }

              <Button type="submit" className="btn--block btn--large" disabled={isSubmitting}>
                {editMode ? "Update Commission Source" : "Add Commission Source"}
              </Button>
            </Form>
          )
        }}
      />
    )
  }
}

CommissionSourceForm.propTypes = {
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    category: PropTypes.string,
    sales: PropTypes.bool,
  }).isRequired,
}

CommissionSourceForm.defaultProps = {
  editMode: false,
}

export default CommissionSourceForm
