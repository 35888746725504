import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { Formik, Form, Field, FieldArray } from "formik"
import classNames from "classnames/bind"
import build from "redux-object"
import get from "lodash/get"
import moment from "moment"
import * as Yup from "yup"

import {
  getTechnicians,
  createTimeBlock,
  getCalendarTimeBlocks,
  updateTimeBlock,
  deleteTimeBlock,
} from "actions"
import CalendarContext from "../calendar/Context"
import FormikTextInput from "components/forms/FormikTextInput"
import FormikDatePicker from "components/forms/FormikDatePicker"
import FormikTimePicker from "components/forms/FormikTimePicker"
import FormikCheckbox from "components/forms/FormikCheckbox"
import Checkbox from "components/forms/Checkbox"
import Button from "components/forms/Button"
import queue from "utils/snackbarQueue"
import "react-datepicker/dist/react-datepicker.css"

import styles from "./Form.module.scss"
const cx = classNames.bind(styles)

const TimeBlockFormSchema = Yup.object().shape({
  title: Yup.string().min(1).required(),
  startDate: Yup.date().required(),
  startTime: Yup.date().required(),
  endDate: Yup.date().required(),
  endTime: Yup.date().required(),
  technician_ids: Yup.array()
    .compact(e => e === "")
    .min(1)
    .required(),
})

class TimeBlockForm extends React.Component {
  static contextType = CalendarContext

  componentDidMount() {
    this.props.getTechnicians()
  }

  handleSubmit = (values, actions) => {
    const {
      timeBlock,
      createTimeBlock,
      getCalendarTimeBlocks,
      updateTimeBlock,
      history
    } = this.props
    actions.setSubmitting(true)

    const startDate = moment(values.startDate).format("YYYY-MM-DD")
    const startTime = moment(values.startTime).format("HH:mm:00")
    const starts_at = moment(`${startDate} ${startTime}`).toISOString()

    const endDate = moment(values.endDate).format("YYYY-MM-DD")
    const endTime = moment(values.endTime).format("HH:mm:00")
    const ends_at = moment(`${endDate} ${endTime}`).toISOString()

    const toSubmit = {
      title: values.title,
      starts_at,
      ends_at,
      technician_ids: values.technician_ids,
    }

    if (timeBlock) {
      return updateTimeBlock(timeBlock.id, toSubmit)
        .then(() => {
          actions.setSubmitting(false)
          queue.notify({
            body: "Time Block updated",
            timeout: 5000,
            leading: true,
          })
          return getCalendarTimeBlocks(startDate, endDate)
        })
        .then(() => history.goBack())
        .catch(e => {
          if (e.response) {
            // HTTP error
            actions.setErrors(e.response.data)
          } else {
            // JS error
            console.error(e.message)
          }
          actions.setSubmitting(false)
          actions.setStatus({ msg: "Error updating time block" })
        })
    }

    return createTimeBlock(toSubmit)
      .then(() => {
        actions.setSubmitting(false)
        queue.notify({
          body: "Time Block created",
          timeout: 5000,
          leading: true,
        })
        return getCalendarTimeBlocks(startDate, endDate)
      })
      .then(() => history.goBack())
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: "Error creating time block" })
      })
  }

  handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this time block?")) {
      const idToDelete = get(this.props, "match.params.id")
      this.props.deleteTimeBlock(idToDelete).then(() => {
        queue.notify({
          body: "Time Block deleted",
          timeout: 5000,
          leading: true,
        })
        this.props.handleClose()
      })
    }
  }

  render() {
    const { technicians, timeBlock } = this.props
    const { info } = this.context

    const initialValues = timeBlock ? {
      title: timeBlock.title,
      startDate: new Date(timeBlock.startsAt),
      startTime: new Date(timeBlock.startsAt),
      endDate: new Date(timeBlock.endsAt),
      endTime: new Date(timeBlock.endsAt),
      technician_ids: timeBlock.technicianIds.map(v => String(v)),
    } : {
      title: "",
      startDate: (info && info.start) || "",
      startTime: (info && info.start) || "",
      endDate: "",
      endTime: "",
      technician_ids: info && info.resourceId ? [info.resourceId] : [],
    }

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={TimeBlockFormSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={this.handleSubmit}
        render={({ values, errors, isSubmitting, setFieldValue }) => {
          const allChecked = values.technician_ids.length === technicians.length
          const indeterminate = !!(values.technician_ids.length > 0
            && values.technician_ids.length < technicians.length)

          const toggleSelectAll = checked => {
            const list = checked ? technicians.map(t => t.id) : []
            setFieldValue("technician_ids", list)
          }

          return (
            <Form className={styles.timeBlockForm}>
              <section>
                <div className={styles.sectionTitle}>Schedule</div>
                <div className={styles.sectionFields}>
                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    name="title"
                    type="text"
                    label="Title"
                    errorText={errors.title && "A title is required"}
                  />

                  <div className={styles.twoColumns}>
                    <Field
                      component={FormikDatePicker}
                      wrapperClassName={styles.dateTimeInputs}
                      name="startDate"
                      placeholder="Start Date"
                      invalid={!!(errors.startDate || errors.startTime)}
                    />
                    <Field
                      component={FormikTimePicker}
                      wrapperClassName={styles.dateTimeInputs}
                      name="startTime"
                      placeholder="Start Time"
                      invalid={!!(errors.startDate || errors.startTime)}
                    />
                  </div>
                  {!!(errors.startDate || errors.startTime) &&
                    <div className={styles.errorText}>
                      Start date and time are required
                    </div>
                  }

                  <div className={styles.twoColumns}>
                    <Field
                      component={FormikDatePicker}
                      wrapperClassName={styles.dateTimeInputs}
                      name="endDate"
                      placeholder="End Date"
                    />
                    <Field
                      component={FormikTimePicker}
                      wrapperClassName={styles.dateTimeInputs}
                      name="endTime"
                      placeholder="End Time"
                      invalid={errors.endDate || errors.endTime}
                    />
                  </div>
                  {!!(errors.endDate || errors.endTime) &&
                    <div className={styles.errorText}>
                      End date and time are required
                    </div>
                  }
                </div>
              </section>

              <section>
                <div className={styles.sectionTitle}>Technicians</div>
                {!!(errors.technician_ids) &&
                  <div className={cx(styles.techErrorText, styles.errorText)}>
                    At least one technician must be selected
                  </div>
                }

                <div className={styles.sectionFields}>
                  <Checkbox
                    id="all_technicians_block"
                    labelText="All Technicians"
                    wrapperClassName={cx(styles.allTechnicians, styles.checkbox)}
                    checked={allChecked}
                    indeterminate={indeterminate}
                    value="all"
                    onChange={toggleSelectAll}
                  />

                  <FieldArray
                    name="technician_ids"
                    render={arrayHelpers => (
                      technicians.map(tech => (
                        <Field
                          key={tech.id}
                          id={tech.id}
                          component={FormikCheckbox}
                          wrapperClassName={styles.checkbox}
                          labelText={`${tech.firstName} ${tech.lastName}`}
                          value={tech.id}
                          checked={values.technician_ids.includes(tech.id)}
                          onChange={e => {
                            if (e.target.checked) arrayHelpers.push(tech.id)
                            else {
                              const idx = values.technician_ids.indexOf(tech.id)
                              arrayHelpers.remove(idx);
                            }
                          }}
                        />
                      ))
                    )}
                  />
                </div>
              </section>

              <div className={styles.action}>
                <Button
                  type="submit"
                  large
                  className={cx("btn--block", styles.submitButton)}
                  disabled={isSubmitting}
                >
                  {timeBlock ? "Update Time Block" : "Create Time Block"}
                </Button>

                {timeBlock &&
                  <div className={styles.deleteButton}>
                    <Button
                      kind="link"
                      onClick={this.handleDelete}
                    >
                      Delete Time Block
                    </Button>
                  </div>
                }
              </div>
            </Form>
          )
        }
      }/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // Are we editing a block (based on URL)? If so, build it here
  const idToEdit = get(ownProps, "match.params.id", null)
  const blockToEdit = idToEdit
    ? build(state.data, "timeBlock", idToEdit)
    : null

  let technicians = []
  let loading = true
  if (state.data.meta[`/technicians`]) {
    technicians = (state.data.meta[`/technicians`].data || []).map(object => build(state.data, 'technician', object.id))
    loading = state.data.meta[`/technicians`].loading
  }

  return { technicians, loading, timeBlock: blockToEdit }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getTechnicians,
      createTimeBlock,
      updateTimeBlock,
      deleteTimeBlock,
      getCalendarTimeBlocks,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeBlockForm))
