import {
  SET_OPTIMIZING,
  SET_OPTIMIZE_DATE,
} from "actions/types"

const setOptimizing = whether => {
  return dispatch => {
    // If setting to false, wait 1.5 seconds so animation can finish
    if (!whether) {
      setTimeout(() => dispatch({
        type: SET_OPTIMIZING,
        payload: whether
      }), 1500)
    } else {
      return dispatch({
        type: SET_OPTIMIZING,
        payload: whether
      })
    }
  }
}

const setOptimizeDate = yyyyMmDddd => ({
  type: SET_OPTIMIZE_DATE,
  payload: yyyyMmDddd,
})

export {
  setOptimizing,
  setOptimizeDate,
}
