import { CREATE_OBJECT_SUCCESS } from "./types"
import { CALL_API, API_DATA_SUCCESS_DEDUPLICATE } from "middleware/api"

const getProducts = () => ({
  [CALL_API]: {
    endpoint: "/products?include=services",
    method: "get",
  },
})

const getProduct = productId => ({
  [CALL_API]: {
    endpoint: `/products/${productId}`,
    method: "get",
  },
})

const createProduct = params => {
  let options = {}
  options["data"] = { product: params }

  return {
    [CALL_API]: {
      endpoint: `/products`,
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateProduct = (productId, params) => {
  let options = {}
  options["data"] = { product: params }

  return {
    [CALL_API]: {
      endpoint: `/products/${productId}`,
      method: 'patch',
      options: options,
      successType: API_DATA_SUCCESS_DEDUPLICATE
    },
  }
}

export { getProducts, getProduct, createProduct, updateProduct }
