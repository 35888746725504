import PropTypes from "prop-types"
import React from "react"

import styles from "./SearchInput.module.scss"

const SearchInput = ({ onChange, ...other }) => (

  <div className={styles.searchInput}>
    <i className="material-icons">search</i>
    <input 
      type="search"
      onChange={onChange}
      {...other}
    /> 

  </div>
  
)

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchInput
