import * as types from "./types"
import { apiCall } from "utils/rawApi"

export function getCurrentUser() {
  return dispatch => {

    dispatch({
      type: types.GET_CURRENT_USER
    })
    return apiCall("get", "users/me").then(response => {

      dispatch({
        type: types.LOAD_CURRENT_USER,
        data: response.data
      })
    }).catch(error => {
      throw error
    })
  }
}
