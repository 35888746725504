import { CREATE_OBJECT_SUCCESS } from "./types"
import { CALL_API } from "middleware/api"

const getOrganizations = () => ({
  [CALL_API]: {
    endpoint: "/organizations",
    method: "get",
  },
})

const createOrganization = params => {
  let options = {}
  options["data"] = { organization: params }

  return {
    [CALL_API]: {
      endpoint: `/organizations`,
      method: "post",
      options,
      successType: CREATE_OBJECT_SUCCESS,
    },
  }
}

const updateOrganization = (organizationID, params) => {
  const options = {}
  options.data = { organization: params }

  return {
    [CALL_API]: {
      endpoint: `/organizations/${organizationID}`,
      method: 'patch',
      options: options
    },
  }
}

export { getOrganizations, createOrganization, updateOrganization }
