import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import api from "middleware/api"
import { composeWithDevTools } from "redux-devtools-extension"
import reducers from "./reducers"

function configureStore(initialState) {
  return composeWithDevTools(
    applyMiddleware(thunk, api)
  )(createStore)(reducers, initialState)
}

const store = configureStore()
export default store
