import React from "react"
import { Route, Switch } from "react-router-dom"

import Header from "components/Header"
import UsersRoute from "components/routes/settings/Users"
import ProductsRoute from "components/routes/settings/Products"
import ServicesRoute from "components/routes/settings/Services"
import OrganizationsRoute from "components/routes/settings/Organizations"
import CommissionSourcesRoute from "components/routes/settings/CommissionSources"

const SettingsRoute = props => {
  const toggleSubNav = option => {
    props.history.push(`/settings/${option}`)
  }

  const navOptions = {
    "users": "Users",
    "products": "Products",
    "services": "Services",
    "commissions": "Commission Sources",
    "organizations": "Organizations"
  }

  const activeSubNav = props.match.params.option

  return (
    <>
      <Header>
        <h1>Settings</h1>
      </Header>

      <Switch>
        <Route
          exact path="/settings/products"
          render={() => (
            <ProductsRoute subNavOptions={{
              options: navOptions,
              active: activeSubNav,
              handleClick: toggleSubNav,
            }} />
          )}
        />
        <Route
          exact path="/settings/users"
          render={() => (
            <UsersRoute subNavOptions={{
              options: navOptions,
              active: activeSubNav,
              handleClick: toggleSubNav,
            }} />
          )}
        />
        <Route
          exact path="/settings/services"
          render={() => (
            <ServicesRoute subNavOptions={{
              options: navOptions,
              active: activeSubNav,
              handleClick: toggleSubNav,
            }} />
          )}
        />
        <Route
          exact path="/settings/organizations"
          render={() => (
            <OrganizationsRoute subNavOptions={{
              options: navOptions,
              active: activeSubNav,
              handleClick: toggleSubNav,
            }} />
          )}
        />
        <Route
          exact path="/settings/commissions"
          render={() => (
            <CommissionSourcesRoute subNavOptions={{
              options: navOptions,
              active: activeSubNav,
              handleClick: toggleSubNav,
            }} />
          )}
        />
      </Switch>
    </>
  )
}

export default SettingsRoute
