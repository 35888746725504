import React from "react"
import DatePicker from "react-datepicker"
import PropTypes from "prop-types"

import "./MonthPicker.scss"

const MonthPicker = ({ value, onChange }) => (
  <div className="monthPicker-wrapper">
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat="MMMM yyyy"
      className="monthPicker"
      showMonthYearPicker
    />
    <i className="material-icons">date_range</i>
  </div>
)

MonthPicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MonthPicker
