// Authentication
export const SIGNIN_REQUEST = "SIGNIN_REQUEST"
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNIN_ERROR = "SIGNIN_ERROR"
export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST"
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS"
export const CLEAR_SIGNIN_ERROR = "CLEAR_SIGNIN_ERROR"

// Current User
export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER"

// Invitations
export const ACCEPT_INVITATION_REQUEST = "ACCEPT_INVITATION_REQUEST"
export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS"
export const ACCEPT_INVITATION_ERROR = "ACCEPT_INVITATION_ERROR"

// Passwords
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

export const API_DATA_SUCCESS_DEDUPLICATE = "API_DATA_SUCCESS_DEDUPLICATE"
export const CREATE_OBJECT_SUCCESS = "CREATE_OBJECT_SUCCESS"
export const UPDATE_OBJECT_SUCCESS = "UPDATE_OBJECT_SUCCESS"
export const DELETE_OBJECT_SUCCESS = "DELETE_OBJECT_SUCCESS"
export const PAGINATED_OBJECTS_SUCCESS = "PAGINATED_OBJECTS_SUCCESS"
export const SEARCH_SUCCESS = "SEARCH_SUCCESS"
export const CLEAR_OBJECTS = "CLEAR_OBJECTS"
export const UPDATE_NORMALIZED_OBJECT = "UPDATE_NORMALIZED_OBJECT"

// Invoices
export const GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE"
export const CLEAR_INVOICES = "CLEAR_INVOICES"

// Calendar state
export const SET_SELECTED_TECHNICIANS = "SET_SELECTED_TECHNICIANS"
export const SET_ON_DAY_VIEW = "SET_ON_DAY_VIEW"

// Optimizer
export const SET_OPTIMIZING = "SET_OPTIMIZING"
export const SET_OPTIMIZE_DATE = "SET_OPTIMIZE_DATE"
