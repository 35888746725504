import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { createService } from "actions"
import Modal from "components/Modal"
import ServiceForm from "./Form"

class NewService extends React.Component {
  handleCreate = values => {
    const { createService, handleClose } = this.props
    return createService(values).then(handleClose)
  }

  render() {
    const { handleClose } = this.props
    return (
      <Modal title="New Service" handleClose={handleClose}>
        <ServiceForm
          handleSubmit={this.handleCreate}
          initialValues={{
            name: "",
            description: "",
            service_code: "",
            recurrence: "",
            price: "",
          }}
        />
      </Modal>
    )
  }
}

NewService.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ createService }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NewService)
