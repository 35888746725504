import React from "react"
import ReactSelect from "react-select"
import Creatable from "react-select/creatable"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./FormikSelect.module.scss"
const cx = classNames.bind(styles)

class Select extends React.Component {
  handleInputChange = (newVal, action) => {
    const { handleSearchChange } = this.props
    if (handleSearchChange) {
      handleSearchChange(newVal, action)
    }
  }

  handleSelectChange = option => {
    const { form, field, handleChangeCustom } = this.props
    form.setFieldValue(field.name, option ? option.value : null)
    if (handleChangeCustom) {
      handleChangeCustom(option, form.setFieldValue)
    }
  }

  render = () => {
    const {
      allowWriteIns,
      disabled,
      field,
      invalid,
      className,
      options,
      loadNextPage,
      isClearable,
    } = this.props

    const allProps = {
      ...field,
      ...this.props,
      isDisabled: disabled,
      isSearchable: true,
      onChange: option => this.handleSelectChange(option),
      className: cx(styles.select, { invalid }, className),
    }

    if (allowWriteIns) {
      return (
        <Creatable
          {...allProps}
          onMenuScrollToBottom={loadNextPage}
          value={{ label: field.value, value: field.value }}
          onInputChange={this.handleInputChange}
          isClearable={isClearable || !!(this.props.handleSearchChange)}
          classNamePrefix="react-select"
        />
      )
    }

    return (
      <ReactSelect
        {...allProps}
        onMenuScrollToBottom={loadNextPage}
        value={options && options.length > 0
          ? options.find(o => o.value === field.value)
          : ""
        }
        onInputChange={this.handleInputChange}
        isClearable={isClearable || !!(this.props.handleSearchChange)}
        classNamePrefix="react-select"
      />
    )
  }
}

Select.defaultProps = {
  defaultValue: 0,
  disabled: false,
  name: "",
  id: "",
  placeholder: "Select...",
  className: "",
  allowWriteIns: false,
  loadNextPage: null,
  handleSearchChange: null,
  isClearable: false,
}

Select.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  allowWriteIns: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string,
  })).isRequired,

  // These props are for server-controlled pagination/search,
  // which needs to be handled by the parent component
  loadNextPage: PropTypes.func,
  handleSearchChange: PropTypes.func,

  // Set true by default if search/pagination are enabled
  isClearable: PropTypes.bool,
}

export default Select
