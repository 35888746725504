import React from "react"
import Checkbox from "components/forms/Checkbox"

const FormikCheckbox = ({ id, field, form, ...other }) => (
  <Checkbox
    id={id}
    name={field.name}
    checked={field.value}
    isFormik={true}
    onChange={field.onChange}
    {...other}
  />
)

export default FormikCheckbox
