import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import Icon from "components/Icon"
import styles from "./SideModal.module.scss"

class SideModal extends Component {

  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string,
  }

  static defaultProps = {
    title: null,
  }

  constructor(props){
    super(props)
    this.state = {
      active: false,
    }
  }

  componentDidMount() {
    this.timeoutID = setTimeout(() => {
      this.setState({ active: true })
    }, 1)
    document.body.classList.add("modal-open")
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open")
    this.setState({ active: false })
  }

  handleClickOutside = event => {
    event.stopPropagation()
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ active: false })
    setTimeout(() => {
      this.props.handleClose()
    }, 300)
  }

  render() {

    const { active } = this.state
    const { children, title, locked } = this.props

    let cx = classNames.bind(styles)
    const overlayClass = cx({ overlay: true, open: active })
    const contentClass = cx({ content: true, open: active, locked })

    return (
      <div className={styles.wrapper}>
        <div className={overlayClass} onClick={this.handleClickOutside} />
        <div className={contentClass}>
          <Icon name="close" className={styles.closeIcon} width="14" height="14" onClick={this.handleClose} />
          <div className={styles.contentWrapper}>
            { title && <h3 className={styles.title}>{title}</h3> }
            { children }
          </div>
        </div>
      </div>
    )
  }
}

export default SideModal
