import React from "react"
import classNames from "classnames/bind"

import styles from "./Badge.module.scss"
const cx = classNames.bind(styles)

const COLORS = {
  red: "red",
  orange: "orange",
  gray: "gray",
}

const Badge = ({ color = COLORS.gray, className, children }) => (
  <span className={cx(styles.badge, color, className)}>
    {children}
  </span>
)

export { COLORS, Badge }