import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getServices } from "actions"
import build from "redux-object"
import { Formik, Field, FieldArray, Form } from "formik"
import FormikTextInput from "components/forms/FormikTextInput"
import Label from "components/forms/Label"
import Button from "components/forms/Button"
import FormikCheckbox from "components/forms/FormikCheckbox"
import Select from "components/forms/FormikSelect"
import PropTypes from "prop-types"

import styles from "./Form.module.scss"

class ProductForm extends React.Component {
  componentDidMount() {
    const { getServices } = this.props
    getServices()
  }

  submitForm = (values, actions) => {
    const { handleSubmit } = this.props
    actions.setSubmitting(true)
    handleSubmit(values)
      .then(() => actions.setSubmitting(false))
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error submitting product' })
      })
  }

  render() {
    const {
      editMode,
      initialValues,
      services,
    } = this.props

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => (
          <Form className={styles.productForm}>
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="name"
                type="text"
                name="name"
                label="Product Name"
                required
                errorText={errors.name ? "A name is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="epa_code"
                type="text"
                name="epa_code"
                label="EPA Registration #"
                errorText={errors.epa_code ? "An EPA registration # is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="company"
                type="text"
                name="company"
                label="Manufacturer"
                errorText={errors.company ? "A manufacturer is required" : null}
              />

              <Label htmlFor="unit">Unit of Measure</Label>
              <Field
                component={Select}
                id="unit"
                name="unit"
                placeholder="Select a Unit of Measure"
                options={[
                  { label: "Ounce (oz)", value: "oz" },
                  { label: "Pound (lb)", value: "lb" },
                  { label: "Gallon (ga)", value: "ga" },
                ]}
              />

              <Label>Related Services</Label>
              <FieldArray
                name="service_ids"
                render={({ push, remove }) => (
                  values.service_ids.map((serviceId, index) => (
                    <React.Fragment key={Math.random()}>
                      <Field
                        component={Select}
                        name={`service_ids.${index}`}
                        className={styles.serviceSelect}
                        placeholder="Select a Service..."
                        options={services && services
                          .filter(s => serviceId === s.id || !values.service_ids.includes(s.id))
                          .map(s => ({ label: s.name, value: s.id }))
                        }
                      />
                      {values.service_ids && index === values.service_ids.length - 1
                        ? <b className={styles.addRemoveButton} onClick={() => push("")}>+</b>
                        : <b className={styles.addRemoveButton} onClick={() => remove(index)}>&times;</b>}
                    </React.Fragment>
                  ))
                )}
              />

              {editMode &&
                <Field
                  wrapperClassName={styles.deactivateButton}
                  component={FormikCheckbox}
                  id="deactivated"
                  name="deactivated"
                  labelText="Deactivated"
                />
              }

            <Button type="submit" className="btn--block btn--large" disabled={isSubmitting}>
              {editMode ? "Update Product" : "Create New Product"}
            </Button>
          </Form>
        )}
      />
    )
  }
}

ProductForm.defaultProps = {
  editMode: false,
}

ProductForm.propTypes = {
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    epa_code: PropTypes.string,
    company: PropTypes.string,
    unit: PropTypes.string,
    service_ids: PropTypes.arrayOf(PropTypes.string),
    deactivated: PropTypes.bool,
  }).isRequired,
}

const mapStateToProps = state => {
  let services = []
  let loading = true
  if (state.data.meta[`/services`]) {
    services = (state.data.meta[`/services`].data || []).map(object => build(state.data, 'service', object.id))
    loading = state.data.meta[`/services`].loading
  }
  return { services, loading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getServices }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductForm)
