import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import iconSprite from "assets/icons.svg"

const Icon = ({ name, width, height, className, onClick }) => {
  const iconClasses = classNames(`icon ${name}`, className)
  return (
    <svg className={iconClasses} width={width} height={height} onClick={onClick}>
      <use xlinkHref={`${iconSprite}#${name}`} />
    </svg>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Icon