import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  authenticated: false,
}

export default function invitations(state = defaultState, action) {
  switch (action.type) {
    case types.ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      }
    case types.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
      }
    case types.ACCEPT_INVITATION_ERROR:
      return {
        ...state,
        errorMessage: action.error,
        isLoading: false,
      }
    default:
      return state
  }
}
