import React from "react"
import Table from "components/Table"
import "react-table/react-table.css"

const ServicesList = ({ services, loading, handleRowClick }) => {

  const columns = [{
    Header: <span>Name</span>,
    accessor: "name",
    width: 420,
  }, {
    Header: <span>Service Code</span>,
    accessor: "serviceCode",
  }, {
    id: "price",
    Header: <span>Suggested Price</span>,
    Cell: row => parseFloat(row.original.price).toFixed(2),
    accessor: d => Number(d.price)
  }, {
    id: "recurrence",
    Header: <span>Recurs</span>,
    accessor: d => {
      switch (d.recurrence) {
        case "monthly": return "Monthly"
        case "quarterly": return "Quarterly"
        default: return "--" // empty string means "never"
      }
    },
  }]

  return (
    <Table
      data={services}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
      defaultSorted={[
        {
          id: "name",
          desc: false,
        }
      ]}
    />
  )
}

export default ServicesList
