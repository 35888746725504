import * as types from "actions/types"

const defaultState = {}

export default function currentUser(state = defaultState, action) {
  switch (action.type) {
    case types.GET_CURRENT_USER:
      return state
    case types.LOAD_CURRENT_USER:
      return action.data
    default:
      return state
  }
}
