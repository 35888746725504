import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"

import AccountsSubRoute from "components/routes/accounts/Accounts"
import ContactsRoute from "components/routes/accounts/Contacts"
import LocationsRoute from "components/routes/accounts/Locations"

class CustomersRoute extends Component {
  toggleSubNav = option => {
    this.props.history.push(`/customers/${option}`)
  }

  render() {
    const { history, match: { params: { option } } } = this.props

    const navOptions = [
      "accounts",
      "contacts",
      "locations",
    ]

    return (
      <>
        <Switch>
          <Route exact path="/customers/accounts" render={() => (
            <AccountsSubRoute
              history={history}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />

          <Route exact path="/customers/contacts" render={() => (
            <ContactsRoute
              history={history}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />

          <Route exact path="/customers/locations" render={() => (
            <LocationsRoute
              history={history}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />
        </Switch>
      </>
    )
  }
}

export default CustomersRoute
