import React from "react"
import TextInput from "components/forms/TextInput"

const FormikTextarea = props => (
  <TextInput
    textarea
    name={props.field.name}
    value={props.field.value}
    onChange={props.field.onChange}
    {...props}
  />
)

export default FormikTextarea
