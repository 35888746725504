import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import DatePicker from "react-datepicker"
import ReactSelect from "react-select"
import build from "redux-object"
import moment from "moment"
import get from "lodash/get"
import debounce from "lodash/debounce"
import fileDownload from "js-file-download"
import classnames from "classnames/bind"

import {
  getAccounts as getAccts,
  clearAccounts as clearAccts,
  getTechnicians as getTechs,
  getCommissionSources as getCommSources,
  getCommissionReports as getReports,
} from "actions"
import { apiCall } from "utils/rawApi"
import SubNav from "components/SubNav"
import Content from "components/Content"
import Button from "components/forms/Button"
import RadioButton from "components/forms/RadioButton"
import ReportTable from "./Table"
import selectStyles from "components/forms/FormikSelect.module.scss"

import styles from "./Reports.module.scss"
const cx = classnames.bind(styles)

const SOURCE_TYPES = [
  "technician",
  "commission source",
  "account",
]

class CommissionReports extends React.Component {
  state = {
    // JS Date objects
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().toDate(),

    sourceType: "technician", // "sales source", "lead source", "account"
    sourceId: null, // a tech ID, commissionSource ID, or account ID

    // account name search
    searchString: "",
  }

  componentDidMount() {
    this.props.getAccounts()
    this.props.getTechnicians()
    this.props.getCommissionSources()
    this.runReport()
  }

  onSelectStartDate = startDate => {
    this.setState({ startDate }, this.runReport)
  }

  onSelectEndDate = endDate => {
    this.setState({ endDate }, this.runReport)
  }

  onSelectType = sourceType => {
    this.setState({
      sourceType,
      sourceId: null,
    }, this.runReport)
  }

  onSelectSource = option => {
    this.setState({ sourceId: String(option.value) }, this.runReport)
  }

  handleMoreAccounts = () => {
    const { currentPage, totalPages } = this.props
    if(currentPage < totalPages) {
      const page = currentPage + 1
      this.fetchAccounts(page)
    }
  }

  handleAccountSearchChange = newVal => {
    if (newVal !== this.state.searchString) {
      this.setState({ searchString: newVal }, () => {
        if(this.state.searchString.length === 0) {
          this.props.clearAccounts()
        }
        this.updateSearch()
      })
    }
  }

  updateSearch = debounce(() => {
    this.fetchAccounts()
  }, 500)

  fetchAccounts(page = 1) {
    const { searchString } = this.state
    this.props.getAccounts(page, searchString)
  }

  openAppointment = (event, rowInfo) => {
    event.preventDefault()
    const appointmentId = rowInfo.original.appointmentId
    this.props.history.push(`/appointments/${appointmentId}`, { modal: true })
  }

  // Build `data` object for passing to getCommissionReports
  buildRequestBody = () => {
    const { startDate, endDate, sourceType, sourceId } = this.state
    const start_date = moment(startDate).format("YYYY-MM-DD")
    const end_date = moment(endDate).format("YYYY-MM-DD")

    const data = { start_date, end_date }
    if (sourceType === "account") data.account_id = sourceId
    else if (sourceType === "technician") data.technician_id = sourceId
    else if (sourceType === "commission source") data.commission_source_id = sourceId
    return data
  }

  runReport = () => {
    const data = this.buildRequestBody()
    this.props.getCommissionReports(data)
  }

  downloadReport = () => {
    const data = this.buildRequestBody()

    apiCall("post", "commission_reports.csv", { data })
      .then(res => {
        fileDownload(
          res.data,
          `Commissions ${data.start_date}-${data.end_date}.csv`,
          res.headers["content-type"],
        )
      })
  }

  render() {
    const {
      subNavOptions,
      reports,
      accounts,
      technicians,
      commissionSources,
      loading,
    } = this.props
    const {
      startDate,
      endDate,
      sourceType,
      sourceId,
    } = this.state

    const typeButtons = SOURCE_TYPES.map(t => (
      <RadioButton
        key={t}
        value={t}
        currentValue={sourceType}
        onChange={this.onSelectType}
        className={styles.typeButton}
      >
        {`By ${t}`}
      </RadioButton>
    ))

    let sourceOptions
    switch (sourceType) {
      case "technician":
        sourceOptions = technicians.map(t => ({
          label: `${t.firstName} ${t.lastName}`,
          value: t.id,
        }))
        break
      case "commission source":
        sourceOptions = commissionSources
          .map(s => ({
            label: s.name,
            value: s.id,
          }))
        break
      case "account":
        sourceOptions = accounts
          .map(a => ({
            label: a.name,
            value: a.id,
          }))
          break
      default:
        sourceOptions = []
        break
    }

    const hiddenColumns = []
    if (sourceType === "account") {
      hiddenColumns.push("service")
    } else if (sourceType === "commission source") {
      hiddenColumns.push("productionCommission")
      hiddenColumns.push("leadName")
      hiddenColumns.push("salesName")
      hiddenColumns.push("productionName")
    } else {
      hiddenColumns.push("leadName")
      hiddenColumns.push("salesName")
      hiddenColumns.push("productionName")
    }

    return (
      <>
        <SubNav {...subNavOptions} />

        <Content>
          <div className={styles.reports}>
            <div className={styles.leftColumnWrapper}>

              <div className={styles.timeframe}>
                <h3>TIMEFRAME</h3>
                <div className={styles.datePickerWrapper}>
                  Start
                  <DatePicker
                    selectsStart
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.onSelectStartDate}
                    className={styles.datePicker}
                  />
                  <i className={cx("material-icons", styles.datePickerIcon)}>
                    date_range
                  </i>
                </div>

                <div className={styles.datePickerWrapper}>
                  End
                  <DatePicker
                    selectsEnd
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={this.onSelectEndDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    className={styles.datePicker}
                  />
                  <i className={cx("material-icons", styles.datePickerIcon)}>
                    date_range
                  </i>
                </div>
              </div>

              <div className={styles.scrollWrapper}>
                <h3>REPORT TYPE</h3>
                <div className={styles.typeButtons}>
                  {typeButtons}
                </div>

                <h3>{sourceType.toUpperCase()}</h3>
                <ReactSelect
                  isSearchable
                  className={selectStyles.select}
                  classNamePrefix="react-select"
                  placeholder={sourceType.charAt(0).toUpperCase() + sourceType.slice(1)}
                  options={sourceOptions}
                  onChange={this.onSelectSource}
                  menuPosition="fixed"
                  value={sourceId && sourceOptions && sourceOptions.length > 0
                    ? sourceOptions.find(o => o.value === sourceId)
                    : null}
                  onMenuScrollToBottom={sourceType === "account"
                    ? this.handleMoreAccounts : null
                  }
                  onInputChange={sourceType === "account"
                    ? this.handleAccountSearchChange : null
                  }
                />
              </div>

              <Button
                kind="primary"
                className={styles.exportButton}
                onClick={this.downloadReport}
                disabled={!reports || reports.length === 0}
              >
                <div>
                  <span>Export Report</span>
                  <i className="material-icons">cloud_download</i>
                </div>
              </Button>
            </div>

            <ReportTable
              className={styles.reportTable}
              handleRowClick={this.openAppointment}
              reports={reports}
              loading={loading}
              hiddenColumns={hiddenColumns}
            />
          </div>
        </Content>
      </>
    )
  }
}

const mapStateToProps = state => {
  let loading = true

  let accounts = []
  let currentPage
  let totalPages

  let reports = []
  let technicians = []
  let commissionSources = []

  if (state.data.meta["/commission_reports"]) {
    reports = get(state, "data.commissionReport.null.attributes.results", [])
    loading = state.data.meta["/commission_reports"].loading
  }

  const accountsUrl = `/accounts`
  if (state.data.meta[accountsUrl]) {
    const metaUrl = state.data.meta[accountsUrl]
    currentPage = metaUrl.meta && metaUrl.meta.currentPage
    totalPages = metaUrl.meta && metaUrl.meta.totalPages
    accounts = (metaUrl.data || []).map(object => build(state.data, 'account', object.id))
  }

  if (state.data.meta["/technicians"]) {
    technicians = (state.data.meta["/technicians"].data || [])
      .map(object => build(state.data, "technician", object.id))
  }

  if (state.data.meta["/commission_sources"]) {
    commissionSources = (state.data.meta["/commission_sources"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
  }

  return {
    accounts,
    currentPage,
    totalPages,
    reports,
    technicians,
    commissionSources,
    loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getAccounts: getAccts,
      clearAccounts: clearAccts,
      getTechnicians: getTechs,
      getCommissionSources: getCommSources,
      getCommissionReports: getReports,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CommissionReports))
