import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Formik, FieldArray, Field, Form } from "formik"
import sum from "lodash/sum"
import compact from "lodash/compact"
import classNames from "classnames/bind"
import ReactSelect from "react-select"
import get from "lodash/get"
import debounce from "lodash/debounce"
import moment from "moment"
import * as Yup from "yup"
import build from "redux-object"

import {
  clearAccounts,
  getAccounts,
  getAccount,
  getSalesSources,
  getServices,
  getTechnicians,
  checkLocation,
} from "actions"
import { formatLocation, formatCurrency } from "utils/format"
import Button from "components/forms/Button"
import FormikSelect from "components/forms/FormikSelect"
import FormikTextInput from "components/forms/FormikTextInput"
import FormikTextarea from "components/forms/FormikTextarea"
import FormikDatePicker from "components/forms/FormikDatePicker"
import FormikTimePicker from "components/forms/FormikTimePicker"
import FormikCheckbox from "components/forms/FormikCheckbox"
import TimeConstraints from "components/locations/TimeConstraints"
import CancelModal from "./CancelModal"
import queue from "utils/snackbarQueue"
import "react-datepicker/dist/react-datepicker.css"

import styles from "./Form.module.scss"
const cx = classNames.bind(styles)

const AppointmentFormSchema = Yup.object().shape({
  account_id: Yup.string().min(1).required(),
  location_id: Yup.string().min(1).required(),
  contact_id: Yup.string().min(1).required(),
  appointment_services: Yup.array()
    .required()
    .min(1),
  estimated_duration: Yup.string().min(1).required(),
  technician_ids: Yup.array()
    .compact(e => e === "")
    .min(1)
    .max(1)
    .required(),
  instructions: Yup.string(),
  date: Yup.date().required(),
  time: Yup.date().required(),
})

class AppointmentForm extends React.Component {
  state = {
    loadingAccount: false,
    searchString: "",
    cancelModalOpen: false,
  }

  componentDidMount() {
    const {
      initialValues,
      getAccount,
      getAccounts,
      getSalesSources,
      getServices,
      getTechnicians,
    } = this.props

    if(initialValues.account_id) {
      getAccount(initialValues.account_id)
    }
    getAccounts()
    getSalesSources()
    getServices()
    getTechnicians()
  }

  serializeValues = values => {
    const selectedDate = moment(new Date(values.date)).format("YYYY-MM-DD")
    const selectedTime = moment(values.time).format("HH:mm:00")
    const combined = moment(`${selectedDate} ${selectedTime}`).toISOString()
    const appointmentServices = values.appointment_services.map(as => ({
      id: as.id,
      service_id: as.serviceId,
      _destroy: as._destroy,
      price: as.price && +(parseFloat(as.price*100).toFixed(2)),
      sales_source_id: as.salesSourceId,
    }))

    const toSubmit = { ...values }
    toSubmit.appointment_services = appointmentServices
    toSubmit.window_start = combined
    delete toSubmit.date
    delete toSubmit.time
    return toSubmit
  }

  handleMoreAccounts = () => {
    const { currentPage, totalPages } = this.props
    if(currentPage < totalPages) {
      const page = currentPage + 1
      this.fetchAccounts(page)
    }
  }

  handleAccountSearchChange = newVal => {
    if (newVal && newVal !== this.state.searchString) {
      this.setState({ searchString: newVal }, () => {
        if(this.state.searchString.length === 0) {
          this.props.clearAccounts()
        }
        this.updateSearch()
      })
    }
  }

  updateSearch = debounce(() => {
    this.fetchAccounts()
  }, 500)

  fetchAccounts(page = 1) {
    const { searchString } = this.state
    this.props.getAccounts(page, searchString)
  }

  checkLocation = (values, actions) => {
    const serializedValues = this.serializeValues(values)

    const locationId = values.location_id
    const time = serializedValues.window_start
    this.props.checkLocation(locationId, time).then(response => {
      if(response.data) {
        this.submitForm(serializedValues, actions)
      } else {
        if(window.confirm(
          "The selected time falls outside of the preferred slots " +
          "for this location. Are you sure you want to schedule " +
          "the appointment?"
        )) {
          this.submitForm(serializedValues, actions)
        } else {
          actions.setSubmitting(false)
        }
      }
    })
  }

  formatLocationOption = location => {
    let address = formatLocation(location)
    if(location.name) address = <div className={styles.locationOption}><strong>{location.name}</strong> - {address}</div>
    return address
  }

  submitForm = (serializedValues, actions) => {
    const { isEditing, handleSubmit } = this.props
    const successMsg = isEditing ? `Appointment updated` : `Appointment created for Account #${serializedValues.account_id}`

    actions.setSubmitting(true)

    handleSubmit(serializedValues)
      .then(() => {
        queue.notify({
          body: successMsg,
          timeout: 5000,
          leading: true,
        })
      })
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error creating appointment' })
      })
  }

  render() {
    const {
      isEditing,
      initialValues,
      accounts,
      getAccount,
      salesSources,
      services,
      currentUser,
      technicians,
      handleCancel,
    } = this.props
    const { loadingAccount, cancelModalOpen } = this.state

    const techOptions = technicians.map(t => ({
      label: `${t.firstName} ${t.lastName}`,
      value: String(t.id),
    }))

    const currentUserSalesSource = salesSources.find(s => (
      s.name === `${currentUser.first_name} ${currentUser.last_name}`))
    const defaultSalesSourceId = currentUserSalesSource
      ? currentUserSalesSource.id
      : ""

    const buttonText = isEditing ? "Update Appointment" : "Create Appointment"

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={AppointmentFormSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={this.checkLocation}
        render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => {
          const account = values.account_id !== ""
            ? accounts.find(a => String(a.id) === String(values.account_id))
            : null

          let locationOptions = []
          if (!loadingAccount && account && account.accountLocations && account.accountLocations.length > 0) {
            locationOptions = account.accountLocations
              .filter(al => !al.billing)
              .map(al => ({
                label: this.formatLocationOption(al.location),
                value: String(al.location.id),
              }))
          }

          const selectedLocation =
            account
            && account.locations
            && values.location_id
            && account.locations.filter(l => String(l.id) === values.location_id)[0]

          let contactOptions = []
          if (!loadingAccount && account && account.contacts && account.contacts.length > 0) {
            contactOptions = account.contacts.map(c => ({
              label: c.name,
              value: String(c.id),
            }))
          }

          // Load account info (locations/contacts/...) after selecting a new account
          if (!loadingAccount && account && !account.locations) {
            setTimeout(() => {
              this.setState({ loadingAccount: true })
              locationOptions = []
              contactOptions = []
              setFieldValue("location_id", "")
              setFieldValue("contact_id", "")

              getAccount(values.account_id)
                .then(() => this.setState({ loadingAccount: false }))
            }, 1)
          }

          return (
            <Form className={styles.appointmentForm}>
              <section>
                <div className={styles.sectionTitle}>Account</div>
                <div className={styles.sectionFields}>
                  <Field
                    component={FormikSelect}
                    className={styles.select}
                    name="account_id"
                    placeholder="Account"
                    options={accounts && accounts.map(a => ({ label: a.name, value: String(a.id) }))}
                    invalid={!!(errors.account_id)}
                    disabled={isEditing}
                    loadNextPage={this.handleMoreAccounts}
                    handleSearchChange={this.handleAccountSearchChange}
                  />
                  {errors.account_id &&
                    <div className={styles.errorText}>
                      An account is required
                    </div>
                  }

                  <Field
                    key={Math.random()}
                    component={FormikSelect}
                    className={cx(styles.select, styles.locationSelect)}
                    name="location_id"
                    placeholder="Service Location"
                    disabled={locationOptions.length === 0}
                    options={locationOptions}
                    invalid={!!(errors.location_id)}
                  />
                  {errors.location_id &&
                    <div className={styles.errorText}>
                      A service location is required
                    </div>
                  }

                  <Field
                    key={Math.random()}
                    component={FormikSelect}
                    className={styles.select}
                    name="contact_id"
                    placeholder="Contact"
                    disabled={contactOptions.length === 0}
                    options={contactOptions}
                    invalid={!!(errors.contact_id)}
                  />
                  {errors.contact_id &&
                    <div className={styles.errorText}>
                      A contact is required
                    </div>
                  }
                </div>
              </section>

              <section>
                <div className={styles.sectionTitle}>Services</div>
                <div className={styles.sectionFields}>
                  <div className={styles.services}>
                    <FieldArray
                      name="appointment_services"
                      validateOnChange={false}
                      render={({ push, remove }) => (
                        <>
                          <ReactSelect
                            name="services"
                            className={styles.select}
                            placeholder="Add a Service"
                            options={services}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            value=""
                            onChange={option => {
                              const price = parseFloat(option.price).toFixed(2)
                              push({
                                serviceId: option.id,
                                name: option.name,
                                price: price,
                                salesSourceId: defaultSalesSourceId,
                              })
                            }}
                          />
                          {errors.appointment_services && errors.appointment_services.length > 0 &&
                            <div className={styles.errorText}>
                              At least one service is required.
                            </div>
                          }
                          {values.appointment_services.map((appointmentService, index) => {
                            const salesSourceOptions = salesSources
                              .filter(s => !s.discardedAt || String(appointmentService.salesSourceId) === s.id)
                              .map(s => (
                                <option key={s.id} value={s.id}>{s.name}</option>
                              ))
                            salesSourceOptions.unshift((
                              <option key="no-source" value="">None</option>
                            ))
                            return (
                              !appointmentService._destroy &&
                              ( <React.Fragment key={index}>
                                  <div className={styles.serviceRow}>
                                    <div className={styles.serviceName}>{appointmentService.name}</div>
                                    <div className={styles.serviceRecurring}></div>
                                    <div className={styles.servicePrice}>
                                      <Field
                                        component="input"
                                        name={`appointment_services.${index}.price`}
                                      />
                                    </div>
                                    <div className={styles.serviceRemove}>
                                      <b className={styles.addRemoveButton} onClick={() => {
                                        remove(index)
                                        push({ id: appointmentService.id, _destroy: true, name: appointmentService.name })
                                      }}
                                      >&ndash;</b>
                                    </div>
                                  </div>
                                  <div className={styles.salesSource}>
                                    <span>Sales Source</span>
                                    <Field
                                      component="select"
                                      name={`appointment_services.${index}.salesSourceId`}
                                    >
                                      {salesSourceOptions}
                                    </Field>
                                  </div>
                                </React.Fragment>
                              )
                            )
                          })}
                          <div className={styles.serviceTotal}>
                            <div className={styles.label}>
                              Total Estimate
                            </div>
                            <div className={styles.value}>
                              {formatCurrency(sum(compact(values.appointment_services.map(as => parseFloat(as.price)))))}
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </div>

                  <Field
                    component={FormikTextarea}
                    className={styles.textInput}
                    name="customer_notes"
                    rows={4}
                    label="Invoice Memo (optional)"
                  />

                  <Field
                    component={FormikTextarea}
                    className={styles.textInput}
                    name="instructions"
                    rows={4}
                    label="Technician Instructions (optional)"
                  />
                </div>
              </section>

              <section>
                <div className={styles.sectionTitle}>Schedule</div>
                <div className={styles.sectionFields}>
                  <Field
                    component={FormikSelect}
                    className={styles.select}
                    name="technician_ids[0]"
                    placeholder="Preferred Technician"
                    options={techOptions}
                    invalid={!!(errors.technician_ids)}
                  />
                  {errors.technician_ids &&
                    <div className={styles.errorText}>
                      {Array.isArray(errors.technician_ids)
                        ? errors.technician_ids[0]
                        : "A technician is required"
                      }
                    </div>
                  }

                  {selectedLocation && selectedLocation.timeConstraints &&
                    <div className={styles.timeConstraints}>
                      <p>Preferred times for {selectedLocation.street1}</p>
                      <TimeConstraints timeConstraints={selectedLocation.timeConstraints} />
                    </div>
                  }

                  <div className={styles.twoColumns}>
                    <div>
                      <Field
                        component={FormikDatePicker}
                        wrapperClassName={styles.dateTimeInputs}
                        name="date"
                        placeholder="Date"
                      />
                      {errors.date &&
                        <div className={styles.errorText}>
                          A date is required
                        </div>
                      }
                    </div>
                    <div>
                      <Field
                        component={FormikTextInput}
                        className={cx(styles.textInput, styles.short)}
                        name="estimated_duration"
                        icon="timelapse"
                        type="text"
                        label="Duration (minutes)"
                        invalid={errors.estimated_duration}
                      />
                      {(errors.estimated_duration) &&
                      <div className={styles.errorText}>
                        Duration is required
                      </div>
                      }
                    </div>
                  </div>
                  <div className={styles.twoColumns}>
                    <div>
                      <Field
                        component={FormikTimePicker}
                        wrapperClassName={styles.dateTimeInputs}
                        name="time"
                        placeholder="Start Time"
                        invalid={errors.time}
                      />

                    </div>
                    <Field
                      component={FormikCheckbox}
                      wrapperClassName={styles.dateTimeInputs}
                      name="fixed"
                      id="appointmentFixed"
                      labelText="Fixed"
                      invalid={errors.fixed}
                    />
                  </div>
                </div>
              </section>
              <div className={styles.action}>
                <Button
                  type="submit"
                  large
                  className={cx("btn--block", styles.submitButton)}
                  disabled={isSubmitting}
                >
                  {buttonText}
                </Button>

                {isEditing &&
                  <div className={styles.cancelApppointment}>
                    <Button
                      kind="link"
                      onClick={() => this.setState({ cancelModalOpen: true })}
                    >
                      Cancel Appointment
                    </Button>
                  </div>
                }
                {cancelModalOpen &&
                  <CancelModal
                    handleCancel={handleCancel}
                    handleClose={() => this.setState({ cancelModalOpen: false})}
                  />
                }
              </div>
            </Form>
          )
        }
      }/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let accounts = []
  let currentPage
  let totalPages

  let services = []
  let technicians = []
  let salesSources = []
  let loading = false

  const currentUser = get(
    state,
    "currentUser.data.attributes",
    { first_name: "", last_name: "" }
  )

  if (ownProps.isEditing) {
    const url = `/accounts/${ownProps.initialValues.account_id}`
    if (state.data.meta[url]) {
      accounts = (state.data.meta[url].data || []).map(object => build(state.data, 'account', object.id))
      loading = loading || state.data.meta[url].loading
    }
  } else {
    const accountsUrl = `/accounts`
    if (state.data.meta[accountsUrl]) {
      const metaUrl = state.data.meta[accountsUrl]
      currentPage = metaUrl.meta && metaUrl.meta.currentPage
      totalPages = metaUrl.meta && metaUrl.meta.totalPages
      accounts = (metaUrl.data || []).map(object => build(state.data, 'account', object.id))
    }

    // Add in data for initial account in case it wasn't present in /accounts data
    const initialAccountUrl = `/accounts/${ownProps.initialValues.account_id}`
    if (ownProps.initialValues.account_id && state.data.meta[initialAccountUrl]) {
      const initialAccount = (state.data.meta[initialAccountUrl].data || [])
        .map(object => build(state.data, 'account', object.id))[0]
      accounts.push(initialAccount)
    }
  }
  if (state.data.meta["/commission_sources/sales"]) {
    salesSources = (state.data.meta["/commission_sources/sales"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
    loading = loading || state.data.meta["/commission_sources/sales"].loading
  }
  if (state.data.meta[`/services`]) {
    services = (state.data.meta[`/services`].data || []).map(object => build(state.data, 'service', object.id))
    loading = loading || state.data.meta[`/services`].loading
  }
  if (state.data.meta[`/technicians`]) {
    technicians = (state.data.meta[`/technicians`].data || []).map(object => build(state.data, 'technician', object.id))
    loading = loading || state.data.meta[`/technicians`].loading
  }

  return {
    accounts,
    currentPage,
    totalPages,
    salesSources,
    services,
    currentUser,
    technicians,
    loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      clearAccounts,
      getAccounts,
      getAccount,
      getSalesSources,
      getServices,
      getTechnicians,
      checkLocation,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentForm)
