import React from "react"
import styles from "./ErrorMessage.module.scss"

const ErrorMessage = ({ text }) => {
  return (
    <div className={styles.error}>
      {text}
    </div>
  )
}

export default ErrorMessage
