import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames/bind"
import logomark from "assets/logomark.png"
import styles from "./Sidebar.module.scss"

const Sidebar = props => {

  const mainItems = [
    { route: "appointments/calendar", name: "appointments", icon: "date_range" },
    { route: "customers/accounts", name: "customers", icon: "account_circle" },
    { route: "billing/invoices", name: "billing", icon: "attach_money" },
    { route: "reports/products", name: "reports", icon: "insert_chart" },
  ]

  const bottomItems = [
    { route: "profile", name: "profile", icon: "person" }
  ]

  if (props.currentUser && props.currentUser.role === "Admin") {
    bottomItems.unshift({ route: "settings/users", name: "settings", icon: "settings" })
  }

  let cx = classNames.bind(styles)

  return (
    <nav className={styles.sidebar}>
      <Link to="/">
        <img src={logomark} alt="Accurate" />
      </Link>
      <div className={styles.menu}>
        <ul>
          {mainItems.map(item =>
            <li key={item.name} className={cx({ active: props.match.path.indexOf(item.name) !== -1})}>
              <Link to={`/${item.route}`}>
                <i className={cx("material-icons", styles.icon)}>{item.icon}</i>
              </Link>
            </li>
          )}
        </ul>

        <ul>
          {bottomItems.map(item =>
            <li key={item.name} className={cx({ active: props.match.path.indexOf(item.name) !== -1})}>
              <Link to={`/${item.route}`}>
                <i className={cx("material-icons", styles.icon)}>{item.icon}</i>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
