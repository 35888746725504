import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"

import { createCommissionSource } from "actions"
import Modal from "components/Modal"
import CommissionSourceForm from "./Form"

class NewCommissionSource extends React.Component {
  handleCreate = (values) => {
    const { createCommissionSource, handleClose } = this.props
    return createCommissionSource(values).then(handleClose)
  }

  render() {
    const { users, handleClose } = this.props
    return (
      <Modal title="New Commission Source" handleClose={handleClose}>
        <CommissionSourceForm
          users={users}
          handleSubmit={this.handleCreate}
          initialValues={{
            name: "",
            category: "",
            sales: false,
          }}
        />
      </Modal>
    )
  }
}

NewCommissionSource.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ createCommissionSource }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(NewCommissionSource)
