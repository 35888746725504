import React, { Component } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { SIGNIN_SUCCESS } from "actions/types"
import { getCurrentUser } from "actions/currentUser"
import store from "store"
import { ThemeProvider } from "@rmwc/theme"
import { SnackbarQueue } from "@rmwc/snackbar"
import "@material/theme/dist/mdc.theme.css"

import AppSwitch from "./AppSwitch"
import queue from "utils/snackbarQueue"
import Loading from "components/Loading"
import styles from "./App.module.scss"

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const token = localStorage.getItem("sessionToken")
    if(token) {
      store.dispatch({ type: SIGNIN_SUCCESS })
      store.dispatch(getCurrentUser())
        .then(() => {
          this.setState({ loading: false })
        }).catch(() => {
          this.setState({ loading: false })
        })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <ThemeProvider options={{ primary: "#3670D0", secondary: "#3670D0" }}>
        <div className={styles.app}>
          {!this.state.loading ? (
            <Router>
              <Route component={AppSwitch} />
            </Router>
          ) : (
            <Loading />
          )}
          <SnackbarQueue className={styles.snackbars} messages={queue.messages} />
        </div>
      </ThemeProvider>
    )
  }
}

export default App
