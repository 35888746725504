import React from "react"
import Table from "components/Table"
import "react-table/react-table.css"

const LocationList = ({ locations, loading, handleRowClick, handleNextPage, page }) => {
  const columns = [{
    Header: <span>Account #</span>,
    accessor: "accountNumber",
    maxWidth: 150,
    sortable: false
  }, {
    id: "name",
    Header: <span>Name</span>,
    accessor: "name",
    sortable: false,
  }, {
    id: "street",
    Header: <span>Street Address</span>,
    accessor: d => d.street2 ? `${d.street1}, ${d.street2}` : d.street1,
    sortable: false,
  }, {
    id: "city",
    Header: <span>City</span>,
    accessor: d => d.city,
    maxWidth: 350,
    sortable: true,
  }, {
    id: "state",
    Header: <span>State</span>,
    accessor: d => d.state,
    maxWidth: 200,
    sortable: true,
  }, {
    id: "zipcode",
    Header: <span>Zip Code</span>,
    accessor: d => d.zipcode,
    maxWidth: 150,
    sortable: false,
  }]

  return (
    <Table
      data={locations}
      columns={columns}
      loading={loading}
      handleRowClick={handleRowClick}
      handleNextPage={handleNextPage}
    />
  )
}

export default LocationList
