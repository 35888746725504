import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import moment from "moment"
import get from "lodash/get"
import fileDownload from "js-file-download"

import { getProductReports as getReports, getCounties as getCs } from "actions"
import { apiCall } from "utils/rawApi"
import SubNav from "components/SubNav"
import Content from "components/Content"
import Button from "components/forms/Button"
import RadioButton from "components/forms/RadioButton"
import MonthPicker from "components/forms/MonthPicker"
import ReportTable from "./Table"
import styles from "./Reports.module.scss"

class ProductReports extends React.Component {
  state = {
    // JS Date object
    date: moment().subtract(1, "month").toDate(),
    countyId: "All Counties",
  }

  componentDidMount() {
    this.props.getCounties()
    this.runReport()
  }

  onSelectCounty = countyId => {
    this.setState({ countyId }, this.runReport)
  }

  onSelectMonth = date => {
    this.setState({ date }, this.runReport)
  }

  runReport = () => {
    const { date, countyId } = this.state

    const start = moment(date).startOf("month").format("YYYY-MM-DD")
    const end = moment(date).endOf("month").format("YYYY-MM-DD")

    this.props.getProductReports(
      start,
      end,
      countyId === "All Counties" ? [] : [countyId]
    )
  }

  downloadReport = () => {
    const { counties } = this.props
    const { date, countyId } = this.state
    const start = moment(date).startOf("month").format("YYYY-MM-DD")
    const end = moment(date).endOf("month").format("YYYY-MM-DD")

    apiCall("post", "product_reports.csv", {
      data: {
        start_on: start,
        end_on: end,
        county_ids: countyId === "All Counties" ? [] : [countyId],
      },
    })
      .then(res => {
        const countyName = countyId === "All Counties"
          ? countyId
          : counties.find(c => c.id === countyId).name
        fileDownload(
          res.data,
          `${moment(date).format("YYYY-MM")} - ${countyName}.csv`,
          res.headers["content-type"],
        )
      })
  }

  render() {
    const { counties, subNavOptions, reports, reportLoading } = this.props
    const { date, countyId } = this.state

    const countyOptions = counties.map(c => (
      <RadioButton
        key={c.id}
        value={c.id}
        currentValue={countyId}
        onChange={this.onSelectCounty}
        className={styles.radio}
      >
        {c.name}
      </RadioButton>
    ))

    countyOptions.unshift(
      <RadioButton
        key="allCounties"
        value="All Counties"
        currentValue={countyId}
        onChange={this.onSelectCounty}
        className={styles.radio}
      >
        <b style={{ color: "black" }}>All Counties</b>
      </RadioButton>
    )

    return (
      <>
        <SubNav {...subNavOptions} />

        <Content>
          <div className={styles.reports}>
            <div className={styles.leftColumnWrapper}>
              <div className={styles.timeframe}>
                <h3>TIMEFRAME</h3>
                <MonthPicker
                  value={date}
                  onChange={this.onSelectMonth}
                />
              </div>

              <h3 className={styles.countyHeader}>COUNTY</h3>
              <div className={styles.counties}>
                {countyOptions}
              </div>

              <Button
                kind="primary"
                className={styles.exportButton}
                onClick={this.downloadReport}
              >
                <div>
                  <span>Export Report</span>
                  <i className="material-icons">cloud_download</i>
                </div>
              </Button>
            </div>

            <ReportTable
              className={styles.reportTable}
              handleRowClick={() => {}}
              reports={reports}
              loading={reportLoading}
            />
          </div>
        </Content>
      </>
    )
  }
}

const mapStateToProps = state => {
  let reportLoading = true
  let countiesLoading = true
  let reports = []
  let counties = []

  if (state.data.meta["/product_reports"]) {
    reports = get(state, "data.productReport.null.attributes.results", [])
    reportLoading = state.data.meta["/product_reports"].loading
  }

  if (state.data.meta["/counties"]) {
    counties = (state.data.meta["/counties"].data || [])
      .map(object => build(state.data, "county", object.id))
    countiesLoading = state.data.meta["/counties"].loading
  }

  return { reports, reportLoading, counties, countiesLoading }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getProductReports: getReports,
      getCounties: getCs,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductReports)
