import React from "react"
import { Formik, Field, Form } from "formik"

import FormikTextInput from "components/forms/FormikTextInput"
import Button from "components/forms/Button"
import PropTypes from "prop-types"

import styles from "./Form.module.scss"

class OrganizationForm extends React.Component {

  submitForm = (values, actions) => {
    const { handleSubmit } = this.props
    actions.setSubmitting(true)
    handleSubmit(values)
      .then(() => actions.setSubmitting(false))
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error submitting product' })
      })
  }

  render() {
    const {
      editMode,
      initialValues,
    } = this.props

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => (
          <Form className={styles.organizationForm}>
            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="name"
              type="text"
              name="name"
              label="Name"
              errorText={errors["name"] ? "A name is required" : null}
            />

            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="street1"
              type="text"
              name="street1"
              label="Address 1"
              errorText={errors["street1"] ? "An address is required" : null}
            />

            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="street2"
              type="text"
              name="street2"
              label="Address 2"
            />

            <div className={styles.cityStateZip}>
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="city"
                type="text"
                name="city"
                label="City"
                invalid={errors.hasOwnProperty("city")}
              />
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="state"
                type="text"
                name="state"
                label="State"
                invalid={errors.hasOwnProperty("state")}
              />
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="zipcode"
                type="text"
                name="zipcode"
                label="ZIP Code"
                invalid={errors.hasOwnProperty("zipcode")}
              />
            </div>

            <Button type="submit" className="btn--block btn--large" disabled={isSubmitting}>
              {editMode ? "Update Organization" : "Create New Organization"}
            </Button>
          </Form>
        )}
      />
    )
  }
}

OrganizationForm.defaultProps = {
  editMode: false,
}

OrganizationForm.propTypes = {
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
  }).isRequired,
}

export default OrganizationForm
