import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getUsers } from "actions"
import build from "redux-object"

import SubNav from "components/SubNav"
import Content from "components/Content"
import UsersList from "components/users/List"
import InviteUser from "components/users/Invite";
import EditUser from "components/users/Edit"
import Button from "components/forms/Button"
import Icon from "components/Icon"

class SettingsUsersRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteModalOpen: false,
      editModalOpen: false,
      clickedUserId: null,
    }
  }

  componentDidMount() {
    this.props.getUsers()
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const userId = rowInfo.original.id
    this.setState({
      editModalOpen: true,
      clickedUserId: userId,
    })
  }

  closeModals = () => {
    this.setState({
      inviteModalOpen: false,
      editModalOpen: false,
      clickedUserId: null,
    })
  }

  render() {
    const { users, loading, subNavOptions } = this.props
    const { inviteModalOpen, editModalOpen, clickedUserId } = this.state

    return (
      <>
        <SubNav {...subNavOptions}>
          <Button
            kind="primary"
            onClick={() => this.setState({ inviteModalOpen: true })}
          >
            Invite New User
            <Icon name="plus" width="12" height="12" />
          </Button>
        </SubNav>

        <Content>
          <UsersList
            users={users}
            loading={loading}
            handleRowClick={this.handleRowClick}
          />
        </Content>

        {inviteModalOpen &&
          <InviteUser handleClose={this.closeModals} />
        }
        {editModalOpen &&
          <EditUser
            handleClose={this.closeModals}
            userId={clickedUserId}
          />
        }
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  if (state.data.meta["/users"]) {
    let allUsers = state.data.meta["/users"].data || []

    if (state.data.meta["/users/invite"]) {
      allUsers = allUsers.concat(state.data.meta["/users/invite"].data || [])
    }

    const users = allUsers.map(object => build(state.data, 'user', object.id))
    const loading = state.data.meta[`/users`].loading
    return { users, loading }
  }

  return { users: [], loading: true }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getUsers,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsUsersRoute)
