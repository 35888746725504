import React from "react"
import classNames from "classnames/bind"
import styles from "./Status.module.scss"

const cx = classNames.bind(styles)

const AppointmentStatus = ({ status, displayText }) => (
  <span className={cx("status", status)}>{displayText || status}</span>
)

export default AppointmentStatus
