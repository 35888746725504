import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getServices } from "actions"
import build from "redux-object"

import SubNav from "components/SubNav"
import Content from "components/Content"
import ServicesList from "components/services/List"
import NewService from "components/services/New"
import EditService from "components/services/Edit"
import Button from "components/forms/Button"
import Icon from "components/Icon"

class SettingsServicesRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newModalOpen: false,
      editModalOpen: false,
      clickedServiceId: null,
    }
  }

  componentDidMount() {
    this.props.getServices()
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const serviceId = rowInfo.original.id
    this.setState({
      editModalOpen: true,
      clickedServiceId: serviceId,
    })
  }

  closeModals = () => {
    this.setState({
      newModalOpen: false,
      editModalOpen: false,
      clickedServiceId: null,
    })
  }

  render() {
    const { services, loading, subNavOptions } = this.props
    const { newModalOpen, editModalOpen, clickedServiceId } = this.state

    return (
      <>
        <SubNav {...subNavOptions}>
          <Button
            kind="primary"
            onClick={() => this.setState({ newModalOpen: true })}
          >
            New Service
            <Icon name="plus" width="12" height="12" />
          </Button>
        </SubNav>

        <Content>
          <ServicesList
            services={services}
            loading={loading}
            handleRowClick={this.handleRowClick}
          />
        </Content>

        {newModalOpen && <NewService handleClose={this.closeModals} />}
        {editModalOpen &&
          <EditService
            handleClose={this.closeModals}
            serviceId={clickedServiceId}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  if(state.data.meta[`/services`]) {
    const services = (state.data.meta[`/services`].data || [])
      .map(object => build(state.data, 'service', object.id))
    const loading = state.data.meta[`/services`].loading
    return { services, loading }
  }

  return { services: [], loading: true }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getServices,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsServicesRoute)
