import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Formik, Field, Form } from "formik"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import { createAccountContact, updateAccountContact } from "actions"
import Modal from "components/Modal"
import FormikTextInput from "components/forms/FormikTextInput"
import FormikCheckbox from "components/forms/FormikCheckbox"
import Button from "components/forms/Button"

import styles from "./Contact.module.scss"
const cx = classNames.bind(styles)

class ContactModal extends React.Component {
  handleSubmit = (values, actions) => {
    const {
      accountId,
      accountContact,
      createAccountContact,
      updateAccountContact,
      handleClose
    } = this.props
    actions.setSubmitting(true)

    const toSubmit = accountContact ? {
      id: accountContact.id,
      primary: values.primary,
      billing: values.billing,
      contact: { ...values, id: accountContact.contact.id },
    } : {
      account_id: accountId,
      primary: values.primary,
      billing: values.billing,
      contact: values,
    }

    // accountContact was supplied, so we should update (not create)
    if (accountContact) {
      updateAccountContact(accountContact.id, toSubmit)
        .then(() => actions.setSubmitting(false))
        .then(handleClose)
        .catch(e => {
          if (e.response) {
            // HTTP error
            actions.setErrors(e.response.data)
          } else {
            // JS error
            console.error(e.message)
          }
          actions.setSubmitting(false)
          actions.setStatus({ msg: 'Error updating accountContact' })
        })
    } else {
      createAccountContact(toSubmit)
        .then(() => actions.setSubmitting(false))
        .then(handleClose)
        .catch(e => {
          if (e.response) {
            // HTTP error
            actions.setErrors(e.response.data)
          } else {
            // JS error
            console.error(e.message)
          }
          actions.setSubmitting(false)
          actions.setStatus({ msg: 'Error creating accountContact' })
        })
    }
  }

  render() {
    const { accountContact, handleClose } = this.props
    let contact = null
    if (accountContact) { contact = accountContact.contact }

    console.log(contact)

    const initialValues = contact ? {
      email: contact.email || "",
      name: contact.name,
      phone: contact.phone || "",
      primary: !!(accountContact.primary),
      billing: !!(accountContact.billing),
    } : {
      email: "",
      name: "",
      phone: "",
      primary: false,
      billing: false,
    }

    return (
      <Modal
        className={styles.modal}
        title={contact ? "Edit Contact" : "Add Contact"}
        handleClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={({ values, errors, status, touched, isSubmitting }) => (
            <Form className={styles.contactForm}>
              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="name"
                icon="person"
                type="text"
                name="name"
                label="Name"
                errorText={errors["contact.name"] ? "A name is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="phone"
                icon="phone"
                type="text"
                name="phone"
                label="Phone Number"
                errorText={errors["contact.phone"] ? "A phone number is required" : null}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="email"
                icon="email"
                type="text"
                name="email"
                label="Email Address"
                errorText={errors["contact.email"] ? "A valid email address is required" : null}
              />

              <Field
                component={FormikCheckbox}
                wrapperClassName={styles.checkbox}
                id="primary"
                name="primary"
                labelText="Primary contact for appointments"
                disabled={initialValues.primary}
              />

              <Field
                component={FormikCheckbox}
                wrapperClassName={styles.checkbox}
                id="billing"
                name="billing"
                labelText="Billing contact"
                disabled={initialValues.billing}
              />

              <Button
                type="submit"
                className={cx(styles.btn, "btn--block", "btn--large")}
                disabled={isSubmitting}
              >
                {contact ? "Update Contact" : "Add Contact"}
              </Button>
            </Form>
          )}
        />
      </Modal>
    )
  }
}

ContactModal.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accountContact: PropTypes.shape({
    // id of the account_contact relationship
    id: PropTypes.number,

    // actual contact object
    contact: PropTypes.shape({}),
  }),
  handleClose: PropTypes.func.isRequired,
}

ContactModal.defaultProps = {
  accountContact: null,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      createAccountContact,
      updateAccountContact
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(ContactModal)
