import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getContacts, clearContacts } from "actions"
import build from "redux-object"
import debounce from "lodash/debounce"

import Header from "components/Header"
import SubNav from "components/SubNav"
import SearchInput from "components/forms/SearchInput"
import Content from "components/Content"
import ContactsList from "components/contacts/List"

class CustomerContactsRoute extends Component {
  state = { 
    loading: true,
    searchString: ""
  }

  componentDidMount() {
    this.fetchContacts()
  }

  openAccountDetails = (accountId, contact_id) => {
    this.props.history.push(`/accounts/${accountId}`, {
      modal: true,
      contact_id,
    })
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const contact = rowInfo.original
    this.openAccountDetails(contact.accountId, contact.id)
  }

  fetchContacts(page=1) {
    const { searchString } = this.state
    this.props.getContacts(page, searchString).then(() => this.setState({ loading: false }))
  }

  handleSearchChange = e => {
    const value = e.target.value
    this.setState({ searchString: value }, () => {
      if(this.state.searchString.length === 0) {
        this.props.clearContacts()
      }
      this.updateSearch()
    })
  }

  updateSearch = debounce(() => {
    this.fetchContacts()
  }, 500)

  handleNextPage = () => {
    const { currentPage, totalPages } = this.props
    if(currentPage < totalPages) {
      const page = currentPage + 1
      this.fetchContacts(page)
    }
  }

  render() {
    const { contacts, subNavOptions } = this.props
    const { loading } = this.state

    return (
      <>
        <Header>
          <h1>Customers</h1>
        </Header>

        <SubNav {...subNavOptions}>
          <SearchInput
            onChange={this.handleSearchChange}
            placeholder="Search Contacts"
          />
        </SubNav>

        <Content>
          <ContactsList
            contacts={contacts}
            loading={loading}
            handleRowClick={this.handleRowClick}
            handleNextPage={this.handleNextPage}
          />
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const url = `/contacts`
  if(state.data.meta[url]) {
    const metaUrl = state.data.meta[url]
    const currentPage = metaUrl.meta && metaUrl.meta.currentPage
    const totalPages = metaUrl.meta && metaUrl.meta.totalPages
    const contacts = (metaUrl.data || []).map(object => build(state.data, 'contact', object.id))
    return { contacts, currentPage, totalPages }
  }

  return { contacts: [] }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getContacts,
      clearContacts,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactsRoute)
