import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import PropTypes from "prop-types"

import { updateProduct } from "actions"
import Modal from "components/Modal"
import ProductForm from "./Form"

class EditProduct extends React.Component {
  handleUpdate = (values) => {
    const { updateProduct, product, handleClose } = this.props
    return updateProduct(product.id, values).then(handleClose)
  }

  render() {
    const { product, handleClose } = this.props

    const initialServices = product.services && product.services.length > 0
      ? product.services.map(s => s.id)
      : [""]

    return (
      <Modal title="Edit Product" handleClose={handleClose}>
        <ProductForm
          editMode
          handleSubmit={this.handleUpdate}
          initialValues={{
            name: product.name || "",
            epa_code: product.epaCode || "",
            company: product.company || "",
            unit: product.unit || "",
            service_ids: initialServices,
            deactivated: typeof product.discardedAt === "string",
          }}
        />
      </Modal>
    )
  }
}

EditProduct.propTypes = {
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const { productId } = ownProps
  if (productId) {
    return { product: build(state.data, "product", productId) }
  }
  return { product: null }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ updateProduct }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProduct)
