import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Formik } from "formik"
import * as Yup from "yup"

import { updateAppointment } from "actions"
import EditProducts from "./EditProducts"
import Button from "components/forms/Button"
import styles from "../Detail.module.scss"

const AppointmentProductSchema = Yup.object().shape({
  appointment_products: Yup.array().of(Yup.object({
    id: Yup.string(),
    _destroy: Yup.bool(),
    product_id: Yup.string().min(1).required().nullable(),
    notes: Yup.string().min(1).required(),
    amount: Yup.string().min(1).required(),
  }))
})

class ProductsSection extends React.Component {
  state = {
    isEditingProducts: false,
    deletedProducts: [],
  }

  toggleEditProducts = () => {
    this.setState(currentState => ({
      isEditingProducts: !currentState.isEditingProducts
    }))
  }

  // Callback to mark particular IDs which already exist in the DB as "deleted"
  handleRemoveExisting = existingId => {
    this.setState(prevState => ({
      deletedProducts: [...prevState.deletedProducts, existingId],
    }))
  }

  submitForm = (values, actions) => {
    const { appointment, updateAppointment } = this.props
    const { deletedProducts } = this.state

    actions.setSubmitting(true)
    const toSubmit = {
      // Snip product rows where all fields are empty
      appointment_products: values.appointment_products
        .filter(ap => Object.values(ap).every(v => v !== ""))
    }

    // Add { _destroy: true } entries for any deleted objects
    deletedProducts.forEach(pId => toSubmit.appointment_products.push({
      id: pId,
      _destroy: true,
    }))

    return updateAppointment(appointment.id, toSubmit)
      .then(() => actions.setSubmitting(false))
      .then(() => this.setState({ deletedProducts: [] }))
      .then(this.toggleEditProducts)
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error updating products' })
      })
  }

  render() {
    const { isEditable, appointment } = this.props
    const { isEditingProducts } = this.state

    const initialProducts = appointment.appointmentProducts
    const initialValues = {
      appointment_products: initialProducts && initialProducts.length > 0
        ? initialProducts.map(p => ({
            id: p.id,
            product_id: String(p.productId),
            name: p.name,
            notes: p.notes,
            amount: p.amount,
          }))
        : [new ProductPlaceholder()]
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={AppointmentProductSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={this.submitForm}
        render={({ values, errors, isSubmitting, handleSubmit }) => {
          let productsButtons = null
          if (!isEditable && !isEditingProducts) {
            productsButtons = (
              <Button
                kind="primary"
                className={styles.button}
                onClick={this.toggleEditProducts}
                small
              >
                Edit Products
              </Button>
            )
          } else if (!isEditable && isEditingProducts) {
            productsButtons = (
              <>
                <Button
                  kind="gray"
                  className={styles.button}
                  onClick={this.toggleEditProducts}
                  disabled={isSubmitting}
                  small
                >
                  Cancel
                </Button>
                <Button
                  kind="primary"
                  className={styles.button}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  small
                >
                  Save
                </Button>
              </>
            )
          }

          return (
            <section>
              <div className={styles.sectionHeader}>
                <i className="material-icons">list</i>
                <div className={styles.sectionHeaderContent}>
                  <h4>Products Applied</h4>
                  {productsButtons}
                </div>
              </div>
              {isEditingProducts ? (
                <EditProducts
                  values={values}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  handleRemoveExisting={this.handleRemoveExisting}
                  ProductPlaceholder={ProductPlaceholder}
                />
              ) : (
                <div className={styles.products}>
                  {appointment.appointmentProducts.length === 0 ? (
                    <div className={styles.productRow}>
                      <i className={styles.inactive}>None</i>
                    </div>
                  ) : (
                    appointment.appointmentProducts.map(prod => (
                      <div key={prod.id} className={styles.productRow}>
                        <span className={styles.productName}>{prod.name}</span>
                        <span>{prod.notes}</span>
                        <span>{`${prod.amount} ${prod.unit}`}</span>
                      </div>
                    )
                  ))}
                </div>
              )}
            </section>
          )
        }}
      />
    )
  }
}

class ProductPlaceholder {
  constructor() {
    this.product_id = null
    this.notes = ""
    this.amount = ""
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      updateAppointment,
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(ProductsSection)
