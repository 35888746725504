import * as types from "./types"
import { apiCall } from "utils/rawApi"
import { getCurrentUser } from "./currentUser"

export function signinUser(email, password) {
  return dispatch => {

    const data = {
      auth: {
        email: email,
        password: password
      }
    }

    dispatch({ type: types.SIGNIN_REQUEST })
    return apiCall("post", "user_token", { data: data }).then(response => {

      localStorage.setItem("sessionToken", response.data.jwt)

      dispatch({ type: types.SIGNIN_SUCCESS })
      dispatch(getCurrentUser())
    }).catch(err => {
      // API returns 404 without a specific message
      const message = "Invalid Credentials"
      dispatch(signinError(message))
      throw err
    })
  }
}

function signinError(error) {
  // current returned error is not useful, so we will set our own
  return {
    type: types.SIGNIN_ERROR,
    data: error
  }
}

export function signoutUser() {
  return dispatch => {
    dispatch({ type: types.SIGNOUT_REQUEST })
    localStorage.removeItem("sessionToken")
    dispatch({ type: types.SIGNOUT_SUCCESS })
  }
}

export function clearSignInError() {
  return {
    type: types.CLEAR_SIGNIN_ERROR
  }
}
