import * as types from "actions/types"

const defaultState = {
  isLoading: false
}

export default function invoices(state = defaultState, action) {
  switch (action.type) {
    case types.GET_INVOICES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.data
      }
    case types.GET_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.CLEAR_INVOICES:
      return {
        ...state,
        data: []
      }
    default:
      return state
  }
}
