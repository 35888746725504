import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"
import PropTypes from "prop-types"

import { updateAppointment, cancelAppointment, getCalendarAppointments } from "actions"
import AppointmentFormSwitcher from "./FormSwitcher"

class EditAppointment extends Component {

  handleUpdate = values => {
    const { appointment, toggleEditing, updateAppointment, getCalendarAppointments } = this.props

    const selectedDate = moment(values.window_start).format("YYYY-MM-DD")
    const appointmentId = appointment.id

    return updateAppointment(appointmentId, values).then(() => {
      getCalendarAppointments(selectedDate)
      toggleEditing()
    })
  }

  handleCancel = reason => {
    this.props.cancelAppointment(this.props.appointment.id, reason)
      .then(this.props.toggleEditing)
  }

  render() {

    const { appointment } = this.props

    const appointmentServices = appointment.appointmentServices.map(s => {
      // Prevent <select> value from being null if this doesn't exist
      if (!s.salesSourceId) {
        s.salesSourceId = ""
      }
      return s
    })
    const date = moment(appointment.windowStart).format("MM/DD/YYYY")
    const time = new Date(appointment.windowStart)

    return (
      <AppointmentFormSwitcher
        isEditing
        handleSubmit={this.handleUpdate}
        handleCancel={this.handleCancel}
        initialValues={{
          account_id: String(appointment.account.id),
          location_id: String(appointment.location.id),
          contact_id: String(appointment.contact.id),
          appointment_services: appointmentServices,
          estimated_duration: appointment.estimatedDuration,
          technician_ids: [String(appointment.technician.id) || ""],
          instructions: appointment.instructions || "",
          customer_notes: appointment.customerNotes || "",
          date: date || "",
          time: time || "",
          fixed: appointment.fixed,
        }}
      />
    )
  }
}

EditAppointment.propTypes = {
  updateAppointment: PropTypes.func.isRequired,
  getCalendarAppointments: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  toggleEditing:  PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      updateAppointment,
      cancelAppointment,
      getCalendarAppointments,
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(EditAppointment)
