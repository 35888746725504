import { CALL_API } from "middleware/api"

const getCounties = () => ({
  [CALL_API]: {
    endpoint: "/counties",
    method: "get",
  }
})

/**
 * @param {String} start_on YYYY-MM-DD
 * @param {String} end_on YYYY-MM-DD
 * @param {Array<Number>} county_ids server returns all counties if none specified
 */
const getProductReports = (start_on, end_on, county_ids = []) => {
  const options = {}
  options.data = { start_on, end_on, county_ids }

  return {
    [CALL_API]: {
      endpoint: "/product_reports",
      method: "post",
      options,
    },
  }
}

export { getCounties, getProductReports }
