import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import AppointmentForm from "components/appointments/Form"
import TimeBlockForm from "components/appointments/timeBlocks/Form"
import RadioButton from "components/forms/RadioButton"

import styles from "./FormSwitcher.module.scss"

const FormSwitcher = (props) => {
  // Determine whether we're at /appointments/* or /time_blocks/*
  // and show the appropriate form
  const { location: { pathname } } = props
  let initialScreen = "appointment"
  if (pathname.includes("time_blocks")) {
    initialScreen = "timeBlock"
  }

  const [appOrBlock, setAppOrBlock] = useState(initialScreen)

  let headerContent
  if (initialScreen === "timeBlock") {
    // Editing a time block
    headerContent = <h3 style={{ marginTop: "5px" }}>Edit Time Block</h3>
  } else if (initialScreen === "appointment" && props.isEditing) {
    // Editing an appointment
    headerContent = <h3 style={{ marginTop: "5px" }}>Edit Appointment</h3>
  } else {
    // Creating a new appointment or time block, show radio buttons
    headerContent = (
      <>
        <h3>Schedule</h3>
        <RadioButton
          value="appointment"
          className={styles.radio}
          currentValue={appOrBlock}
          onChange={newVal => setAppOrBlock(newVal)}
        >
          Appointment
        </RadioButton>
        <RadioButton
          value="timeBlock"
          className={styles.radio}
          currentValue={appOrBlock}
          onChange={newVal => setAppOrBlock(newVal)}
        >
          Time Block
        </RadioButton>
      </>
    )
  }

  return (
    <>
      <header className={styles.header}>
        {headerContent}
      </header>

      {props.isEditing || appOrBlock === "appointment" ? (
        <AppointmentForm {...props} />
      ) : (
        <TimeBlockForm {...props} />
      )}
    </>
  )
}

export default withRouter(FormSwitcher)
