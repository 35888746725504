import React from "react"
import { Radio } from "@rmwc/radio"
import "@material/radio/dist/mdc.radio.css"
import "@material/form-field/dist/mdc.form-field.css"

const RadioButton = ({ value, currentValue, onChange, className, children }) => (
  <Radio
    value={value}
    checked={currentValue === value}
    onChange={e => onChange(e.target.value)}
    className={className}
  >
    {children}
  </Radio>
)

export default RadioButton
