import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateAppointment } from "actions"
import moment from "moment"
import classNames from "classnames/bind"
import Button from "components/forms/Button"
import styles from "./TechnicianLog.module.scss"

const cx = classNames.bind(styles)

const TechnicianLog = ({ appointment, editable, updateAppointment }) => {

  const [editing, toggleEditing] = useState(false)
  const [override, setOverride] = useState(null)

  const handleUpdate = () => {
    const seconds = override * 60
    const data = { time_override: seconds }
    updateAppointment(appointment.id, data).then(() => {
      toggleEditing(false)
    })
  }

  const overridden = Boolean(appointment.timeOverride)

  const timeSpentCalculated = parseInt(appointment.timeSpent/60)
  const timeSpent = overridden ? parseInt(appointment.timeOverride/60) : timeSpentCalculated

  return (
    <div className={styles.punches}>
      <h5>Technician Log</h5>
      {appointment.punches.length > 0
        ? <table>
            <tbody>
              {appointment.punches.map(punch => (
                <tr key={punch.id}>
                  <td className={cx("punchEvent", punch.event)}>{punch.event}</td>
                  <td className={styles.punchTime}>{moment(punch.createdAt).format("L LTS")}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  {overridden &&
                    <div className={styles.muted}>
                      Total Time
                    </div>
                  }
                  Total Time
                  {overridden && <span> (Overridden)</span>}
                </th>
                <th className={styles.totalTime}>
                {overridden &&
                    <div className={styles.muted}>
                      {timeSpentCalculated} minutes
                    </div>
                  }
                  <div className={styles.totalTimeForm}>
                    {editing
                      ? <input
                          type="number"
                          name="timeOverride"
                          onChange={e => setOverride(e.target.value)}
                        />
                      : timeSpent
                    }
                    <span> minutes</span>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        : <p><i className={styles.inactive}>No entries</i></p>
      }
      {editable &&
        <div className={styles.override}>
          {editing
            ? <>
                <Button
                  small
                  kind="success"
                  onClick={() => handleUpdate(!editing)}
                >
                  Save
                </Button>
                <Button
                  small
                  kind="link"
                  onClick={() => toggleEditing(!editing)}
                >
                  Cancel
                </Button>
              </>
            : <Button
                small
                kind="primary"
                onClick={() => toggleEditing(!editing)}
              >
                Override Total Time
              </Button>
          }
        </div>
      }
    </div>
  )
}

TechnicianLog.propTypes = {
  appointment: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      updateAppointment,
    }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TechnicianLog)
