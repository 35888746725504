import React from "react"
import { TextField } from "@rmwc/textfield"
import PropTypes from "prop-types"

import "@material/textfield/dist/mdc.textfield.css"
import "@material/floating-label/dist/mdc.floating-label.css"
import "@material/notched-outline/dist/mdc.notched-outline.css"
import "@material/line-ripple/dist/mdc.line-ripple.css"
import "./TextInput.scss"

const TextInput = ({
  className,
  id,
  placeholder,
  textarea,
  label,
  type,
  onChange,
  onClick,
  invalid = false,
  outlined = true,
  errorText,
  ...other
}) => {
  const textInputProps = {
    id,
    onChange: evt => {
      if (!other.disabled) {
        onChange(evt)
      }
    },
    onClick: evt => {
      if (!other.disabled) {
        onClick(evt)
      }
    },
    placeholder,
    label,
    type,
    invalid,
  }

  return (
    <TextField
      {...other}
      {...textInputProps}
      textarea={textarea}
      outlined={outlined}
      className={className}
      style={textarea ? null : { height: "38px" }}
      invalid={invalid || typeof errorText === "string"}
      helpText={typeof errorText === "string" && {
        persistent: true,
        children: errorText
      }}
    />
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textarea: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Automatically set to `true` if errorText is provided */
  invalid: PropTypes.bool,

  errorText: PropTypes.string,
  helperText: PropTypes.node,
}

TextInput.defaultProps = {
  id: null,
  textarea: false,
  disabled: false,
  type: "text",
  onChange: () => {},
  onClick: () => {},
  invalid: false,
}

export default TextInput
