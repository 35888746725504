import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  authenticated: false,
}

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case types.SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      }
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
        authenticated: true
      }
    case types.SIGNIN_ERROR:
      return {
        ...state,
        errorMessage: action.data,
        isLoading: false,
        authenticated: false
      }
    case types.SIGNOUT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
        authenticated: false
      }
    case types.CLEAR_SIGNIN_ERROR:
      return {
        ...state,
        errorMessage: '',
      }
    default:
      return state
  }
}
