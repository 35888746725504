import React from "react"
import classNames from "classnames/bind"
import styles from "./TimeConstraints.module.scss"

const LocationTimeConstraints = ({ timeConstraints }) => {

  const cx = classNames.bind(styles)

  const meridians = ["am", "pm"]
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday"]

  const body = timeConstraints && meridians.map(meridian => {
    
    const fields = days.map(day => 
      <td key={day} className={cx({ available: timeConstraints[day] && timeConstraints[day][meridian]})}>
        { timeConstraints[day] && timeConstraints[day][meridian] && <span>&#10003;</span> }
      </td>
    )
    
    return (
      <tr key={meridian}>
        <th>{meridian.toUpperCase()}</th>
        {fields}
      </tr>
    )
  })

  return (
    <table className={styles.constraintsTable}>
      <thead>
        <tr>
          <td className={styles.blank}></td>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
        </tr>
      </thead>
      <tbody>
        {body}
      </tbody>
    </table>
  )
}

export default LocationTimeConstraints
