import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"
import get from "lodash/get"

import Header from "components/Header"
import Invoices from "components/billing/Invoices"

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env

class BillingRoute extends Component {

  toggleSubNav = option => {
    this.props.history.push(`/billing/${option}`)
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const invoiceId = get(rowInfo, "original.id")
    if (invoiceId) {
      window.open(
        `${REACT_APP_STRIPE_DASHBOARD_URL}/invoices/${invoiceId}`,
        "_blank"
      )
    }
  }

  render() {
    const {
      match: { params: { option } },
    } = this.props

    const navOptions = [
      "invoices",
    ]

    return (
      <>
        <Header>
          <h1>Billing</h1>
        </Header>

        <Switch>
          <Route exact path="/billing/invoices" render={() => (
            <Invoices
              handleRowClick={this.handleRowClick}
              subNavOptions={{
                options: navOptions,
                active: option,
                handleClick: this.toggleSubNav,
              }}
            />
          )} />
        </Switch>
      </>
    )
  }
}

export default BillingRoute
