import * as types from "./types"
import { CALL_API } from "middleware/api"
import { apiCall } from "utils/rawApi"

const createTimeBlock = values => {
  let options = {}
  options.data = { time_block: values }

  return {
    [CALL_API]: {
      endpoint: "/time_blocks",
      method: "post",
      options,
      successType: types.CREATE_OBJECT_SUCCESS,
    },
  }
}

const getCalendarTimeBlocks = (start, end) => {

  const options = {}
  options.params = { start: start, end: end }

  const successType = types.PAGINATED_OBJECTS_SUCCESS

  return {
    [CALL_API]: {
      endpoint: "/time_blocks/calendar",
      method: "get",
      options,
      successType
    },
  }
}

const updateTimeBlock = (id, values) => {
  const options = {}
  options.data = { time_block: values }

  const successType = types.UPDATE_OBJECT_SUCCESS

  return {
    [CALL_API]: {
      endpoint: `/time_blocks/${id}`,
      method: "patch",
      options,
      successType
    },
  }
}

const deleteTimeBlock = timeBlockId => {
  return dispatch => {
    return apiCall("delete", `/time_blocks/${timeBlockId}`).then(response => {
      dispatch({
        objectId: timeBlockId,
        objectType: "timeBlock",
        type: types.DELETE_OBJECT_SUCCESS,
      })
    }).catch(error => {
      throw error
    })
  }
}

export {
  createTimeBlock,
  getCalendarTimeBlocks,
  updateTimeBlock,
  deleteTimeBlock,
}
