import React from "react"
import classNames from "classnames"

const EventWrapper = props => {

  const color = "#" + props.event.color

  const wrapperClass = classNames("eventWrapper", { fixed: props.event.fixed, block: props.event.block })

  return (
    <div className={wrapperClass} style={{ backgroundColor: color }}>
      {props.children}
    </div>
  )
}

export default EventWrapper
