import React from "react"
import TextInput from "components/forms/TextInput"

const FormikTextInput = props => (
  <TextInput
    className={props.className}
    id={props.id}
    type={props.type}
    name={props.field.name}
    value={props.field.value}
    onChange={props.field.onChange}
    placeholder={props.placeholder}
    label={props.label}
    invalid={props.invalid}
    outlined={props.outlined}
    errorText={props.errorText}
    {...props}
  />
)

export default FormikTextInput
