import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getProducts } from "actions"
import build from "redux-object"

import SubNav from "components/SubNav"
import Content from "components/Content"
import NewProduct from "components/products/New"
import EditProduct from "components/products/Edit"
import ProductsList from "components/products/List"
import Button from "components/forms/Button"
import Icon from "components/Icon"

class SettingsProductsRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newModalOpen: false,
      editModalOpen: false,
      clickedProductId: null,
    }
  }

  componentDidMount() {
    this.props.getProducts()
  }

  handleRowClick = (event, rowInfo) => {
    event.preventDefault()
    const productId = rowInfo.original.id
    this.setState({
      editModalOpen: true,
      clickedProductId: productId,
    })
  }

  closeModals = () => {
    this.setState({
      newModalOpen: false,
      editModalOpen: false,
      clickedProductId: null,
    })
  }

  render() {
    const { products, loading, subNavOptions } = this.props
    const { newModalOpen, editModalOpen, clickedProductId } = this.state

    return (
      <>
        <SubNav {...subNavOptions}>
          <Button
            kind="primary"
            onClick={() => this.setState({ newModalOpen: true })}
          >
            New Product
            <Icon name="plus" width="12" height="12" />
          </Button>
        </SubNav>

        <Content>
          <ProductsList
            products={products}
            loading={loading}
            handleRowClick={this.handleRowClick}
          />
        </Content>

        {newModalOpen && <NewProduct handleClose={this.closeModals} />}
        {editModalOpen &&
          <EditProduct
            handleClose={this.closeModals}
            productId={clickedProductId}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  if(state.data.meta[`/products`]) {
    const products = (state.data.meta[`/products`].data || [])
      .map(object => build(state.data, 'product', object.id))
    const loading = state.data.meta[`/products`].loading
    return { products, loading }
  }

  return { products: [], loading: true }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      getProducts,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsProductsRoute)
