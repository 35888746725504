import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updatePassword } from "actions/passwords"
import queryString from "query-string"
import AuthLayout from "components/AuthLayout"
import Button from "components/forms/Button"
import Form from "components/forms/Form"
import FormItem from "components/forms/FormItem"
import TextInput from "components/forms/TextInput"
import ErrorMessage from "components/ErrorMessage"
import styles from "./ResetPassword.module.scss"

class ResetPasswordRoute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      password_confirmation: "",
      complete: false
    }
  }

  getRedirectPath = () => {
    return "/appointments"
  }

  handleSubmit = e => {
    e.preventDefault()
    const params = {}
    params.password = this.state.password
    params.password_confirmation = this.state.password_confirmation
    params.token = this.props.match.params.token
    params.email = queryString.parse(this.props.location.search).email
    this.props.updatePassword(params).then(response => {
      if(response.data.technician) {
        this.setState({ complete: true })
      }
    })
  }

  onChange = e => {
    const target = e.target
    this.setState({
      [target.name]: target.value
    })
  }

  render() {

    const { errorMessage, authenticated } = this.props
    const { complete, password, password_confirmation } = this.state

    return (
      authenticated
      ?
        <Redirect to={{
          pathname: this.getRedirectPath(), state: {
            from: this.props.location
          }
        }}/>
      :  <AuthLayout>
          {errorMessage && <ErrorMessage text={errorMessage} />}
          <br />
          {complete
          ? <div className={styles.success}>
              <p>Your password has been reset. Please install or open the Accurate Technician app on your Android device.</p>
            </div>
          : <Form onSubmit={this.handleSubmit}>
              <h3>Set Your Password</h3>
              <FormItem>
                <TextInput
                  className={styles.input}
                  id="password"
                  name="password"
                  value={password}
                  type="password"
                  onChange={this.onChange}
                  label="New Password"
                />
              </FormItem>
              <FormItem>
                <TextInput
                  className={styles.input}
                  id="password_confirmation"
                  name="password_confirmation"
                  value={password_confirmation}
                  type="password"
                  onChange={this.onChange}
                  label="Confirm Password"
                />
              </FormItem>
              <Button
                className="btn--block btn--large"
                type="submit"
                kind="primary"
                disabled={this.props.isLoading}
                large
              >
                Set Password
              </Button>
            </Form>
          }
        </AuthLayout>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.passwords.isLoading,
    errorMessage: state.passwords.errorMessage,
    authenticated: state.auth.authenticated,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      updatePassword,
    }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordRoute)
