import * as types from "actions/types"

const defaultState = {
  isLoading: false,
  authenticated: false,
}

export default function passwords(state = defaultState, action) {
  switch (action.type) {
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      }
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
      }
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: action.error,
        isLoading: false,
      }
    default:
      return state
  }
}
