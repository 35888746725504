import React from "react"
import { Formik, Field, Form } from "formik"

import FormikTextInput from "components/forms/FormikTextInput"
import Label from "components/forms/Label"
import Button from "components/forms/Button"
import FormikCheckbox from "components/forms/FormikCheckbox"
import Select from "components/forms/FormikSelect"
import PropTypes from "prop-types"
import FormRow from "components/forms/Row"
import queue from "utils/snackbarQueue"

import styles from "./Form.module.scss"

const ROLES = {
  manager: { label: "Manager", value: "Manager" },
  technician: { label: "Technician", value: "Technician" },
  admin: { label: "Admin", value: "Admin" },
}

class UserForm extends React.Component {
  submitForm = (values, actions) => {
    const { editMode, handleSubmit } = this.props
    actions.setSubmitting(true)
    handleSubmit(values)
      .then(() => {
        actions.setSubmitting(false)
        queue.notify({
          body: editMode
            ? `Updated user ${values.first_name} ${values.last_name}.`
            : `Invitation emailed to ${values.first_name} ${values.last_name}.`,
          timeout: 5000,
          leading: true,
        })
      })
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error submitting user' })
      })
  }

  render() {
    const { editMode, isTechnician, initialValues } = this.props

    const roleOptions = []
    if (editMode) {
      if (initialValues.role === "Technician") {
        roleOptions.push(ROLES.technician)
      } else {
        roleOptions.push(...[
          ROLES.manager,
          ROLES.admin,
        ])
      }
    } else {
      roleOptions.push(...Object.values(ROLES))
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submitForm}
        render={({ values, errors, status, touched, isSubmitting }) => (
          <Form className={styles.userForm}>
            <FormRow className={styles.formRow}>
              <Field
                component={FormikTextInput}
                id="first_name"
                type="text"
                name="first_name"
                label="First Name"
                invalid={errors.hasOwnProperty("first_name")
                  || errors.hasOwnProperty("last_name")}
              />

              <Field
                component={FormikTextInput}
                id="last_name"
                type="text"
                name="last_name"
                label="Last Name"
                errorText={errors.first_name || errors.last_name ? "A name is required" : null}
              />
            </FormRow>

            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="email"
              type="text"
              name="email"
              label="Email Address"
              errorText={errors.email ? "An email address is required" : null}
            />

            <Field
              component={FormikTextInput}
              className={styles.textInput}
              id="phone"
              type="tel"
              name="phone"
              label="Phone Number"
              errorText={errors.phone ? "A phone number is required" : null}
            />

            <Label htmlFor="unit">Role</Label>
            <Field
              component={Select}
              id="role"
              name="role"
              placeholder="Select a role..."
              disabled={initialValues.role === "Technician"}
              options={roleOptions}
            />

            {isTechnician &&
              <div className={styles.technician}>
                <h5>Technician Specific</h5>
                <div className={styles.commission}>
                  <Field
                    component={FormikTextInput}
                    className={styles.textInput}
                    type="number"
                    id="production_commission"
                    name="technician_profile.production_commission"
                    label="Production Commission"
                  />
                  <span>%</span>
                </div>
              </div>
            }

            {editMode &&
              <Field
                wrapperClassName={styles.deactivateButton}
                component={FormikCheckbox}
                id="deactivated"
                name="deactivated"
                labelText="Deactivated"
              />
            }

            <Button type="submit" className="btn--block btn--large" disabled={isSubmitting}>
              {editMode ? "Update User" : "Invite New User"}
            </Button>
          </Form>
        )}
      />
    )
  }
}

UserForm.defaultProps = {
  editMode: false,
  isTechnician: false
}

UserForm.propTypes = {
  editMode: PropTypes.bool,
  isTechnician: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }).isRequired
}

export default UserForm
