import React from "react"
import chrono from "chrono-node"
import DatePicker from "react-datepicker"
import classNames from "classnames/bind"

import "react-datepicker/dist/react-datepicker.css"
import styles from "./FormikTimePicker.module.scss"
const cx = classNames.bind(styles)

const guessPMRefiner = new chrono.Refiner()
guessPMRefiner.refine = (text, results, opt) => {  
  // If there is no AM/PM (meridiem) specified,
  // let all time between 12:00 - 5:00 be PM (12.00 - 17.00)
  results.forEach(result => {
    const startHour = result.start.get("hour")
    if (!result.start.isCertain("meridiem") &&
      (startHour === 12 || (startHour >= 1 && startHour <= 5))) {

      result.start.assign("meridiem", 1)
      result.start.assign("hour", result.start.get("hour") + 12)
    }
  })
  return results
}

const custom = new chrono.Chrono()
custom.refiners.push(guessPMRefiner)

/** Reformats times to match what DatePicker expects */
const reformatTime = input => {
  const result = custom.parseDate(input)
  if (result) {
    return new Date(result.toUTCString())
  }
  return new Date(1970, 0, 1, input)
}

const FormikTimePicker = props => {
  const { field, form, wrapperClassName } = props

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <i className={cx("material-icons", styles.icon)}>
        access_time
      </i>

      <DatePicker
        id={props.id}
        name={field.name}
        selected={field.value}
        onChange={selectedTime => form.setFieldValue(field.name, selectedTime)}
        onBlur={e => form.setFieldValue(field.name, reformatTime(e.target.value))}
        placeholderText={props.placeholder}
        className={styles.timePicker}
        popperClassName={cx(styles.popper)}
        autoComplete="off"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
        timeCaption="Time"
      />
    </div>
  )
}

export default FormikTimePicker
