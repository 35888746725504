import React from "react"
import classNames from "classnames/bind"
import styles from "./Status.module.scss"

const cx = classNames.bind(styles)

const InvoiceStatus = ({ status }) => ( 
  <span className={cx("status", status)}>{status}</span> 
)

export default InvoiceStatus