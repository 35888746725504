import React from "react"
import { Formik, Field, Form } from "formik"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import build from "redux-object"
import get from "lodash/get"
import classNames from "classnames/bind"

import { getOrganizations, getCommissionSources } from "actions"
import { getBillingLocation, getBillingContact } from "utils/accounts"
import Modal from "components/Modal"
import Button from "components/forms/Button"
import FormikSelect from "components/forms/FormikSelect"
import FormikCheckbox from "components/forms/FormikCheckbox"
import FormikTextInput from "components/forms/FormikTextInput"

import styles from "./Billing.module.scss"
import Label from "components/forms/Label";
const cx = classNames.bind(styles)

class BillingModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orgSelected: get(props.account, "organization.id", null)
    }
  }

  componentDidMount() {
    this.props.getOrganizations()
    this.props.getCommissionSources()
  }

  handleSubmit = (values, actions) => {
    const { updateAccount, account, handleClose } = this.props
    actions.setSubmitting(true)

    const accountLocation = account.accountLocations.find(al => al.billing)
    const accountContact = account.accountContacts.find(ac => {
      return ac.contact && String(ac.contact.id) === String(values.contact)
    })

    const toSubmit = {
      id: account.id,
      organization_id: values.organizationId,
      monthly_invoicing: values.monthlyInvoicing,
      lead_source_id: values.leadSourceId,

      account_locations: [{
        id: accountLocation.id,
        primary: false,
        billing: true,

        location: {
          id: accountLocation.location.id,
          street1: values.street1,
          street2: values.street2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
        },
      }],

      account_contacts: [{
        id: accountContact.id,
        billing: true,
      }],
    }

    updateAccount(toSubmit)
      .then(() => actions.setSubmitting(false))
      .then(handleClose)
      .catch(e => {
        if (e.response) {
          // HTTP error
          actions.setErrors(e.response.data)
        } else {
          // JS error
          console.error(e.message)
        }
        actions.setSubmitting(false)
        actions.setStatus({ msg: 'Error updating billing info' })
      })
  }

  handleOrganizationChange = (org, setFieldValue) => {

    let street1, street2, city, state, zipcode

    if(org){
      const { organizations } = this.props
      const selectedOrg = organizations.find(o => o.id === org.value)
      street1 = selectedOrg.street1
      street2 = selectedOrg.street2
      city = selectedOrg.city
      state = selectedOrg.state
      zipcode = selectedOrg.zipcode
      this.setState({ orgSelected: true })
    } else {
      this.setState({ orgSelected: false })
    }
    
    setFieldValue("street1", street1 || "")
    setFieldValue("street2", street2 || "")
    setFieldValue("city", city || "")
    setFieldValue("state", state || "")
    setFieldValue("zipcode", zipcode || "")
  }

  render() {
    const {
      account,
      organizations,
      commissionSources,
      loadingLeadSources,
      handleClose
    } = this.props

    const { orgSelected } = this.state
    const billingLocation = getBillingLocation(account)
    const billingContact = getBillingContact(account)

    const organizationId = orgSelected

    const organizationOptions = organizations.map(s => ({
      label: s.name,
      value: s.id,
    }))

    const contactOptions = account.contacts.map(c => ({
      label: c.name, value: String(c.id)
    }))

    const leadSourceId = get(account, "leadSource.id", "")

    const leadSourceOptions = commissionSources
      .filter(s => !s.discardedAt || s.id === leadSourceId)
      .map(s => ({
        label: s.name,
        value: s.id,
      }))

    const initialValues = {
      organizationId: organizationId,
      contact: String(billingContact.id),
      street1: billingLocation.street1,
      street2: billingLocation.street2 || "",
      city: billingLocation.city,
      state: billingLocation.state,
      zipcode: billingLocation.zipcode,
      monthlyInvoicing: account.monthlyInvoicing,
      leadSourceId: leadSourceId,
    }

    const errPrefix = "account_locations.location"

    const addressDisabled = Boolean(orgSelected)

    return (
      <Modal
        className={styles.modal}
        title={`Edit Billing Information`}
        handleClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={({ values, errors, status, touched, isSubmitting }) => (
            <Form className={styles.billingForm}>

              {account.hoaName &&
                <>
                  <label className={styles.label}>Organization</label>
                  <Field
                    component={FormikSelect}
                    className={styles.selectInput}
                    id="organization"
                    icon="domain"
                    name="organizationId"
                    placeholder="Organization (optional)"
                    options={organizationOptions}
                    isClearable={true}
                    handleChangeCustom={this.handleOrganizationChange}
                  />
                </>
              }

              <label className={styles.label}>Billing Contact</label>
              <Field
                component={FormikSelect}
                id="contact"
                name="contact"
                className={styles.contactSelect}
                placeholder="Select a Contact"
                options={contactOptions}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="street1"
                type="text"
                name="street1"
                label="Address 1"
                errorText={errors[`${errPrefix}.street1`] ? "An address is required" : null}
                disabled={addressDisabled}
              />

              <Field
                component={FormikTextInput}
                className={styles.textInput}
                id="street2"
                type="text"
                name="street2"
                label="Address 2"
                disabled={addressDisabled}
              />

              <div className={styles.cityStateZip}>
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="city"
                  type="text"
                  name="city"
                  label="City"
                  invalid={errors.hasOwnProperty(`${errPrefix}.city`)}
                  disabled={addressDisabled}
                />
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="state"
                  type="text"
                  name="state"
                  label="State"
                  invalid={errors.hasOwnProperty(`${errPrefix}.state`)}
                  disabled={addressDisabled}
                />
                <Field
                  component={FormikTextInput}
                  className={styles.textInput}
                  id="zipcode"
                  type="text"
                  name="zipcode"
                  label="ZIP Code"
                  invalid={errors.hasOwnProperty(`${errPrefix}.zipcode`)}
                  disabled={addressDisabled}
                />
              </div>
              {errors.hasOwnProperty(`${errPrefix}.city`)
                || errors.hasOwnProperty(`${errPrefix}.state`)
                || errors.hasOwnProperty(`${errPrefix}.zipcode`) ?
                  <div className={styles.errorText}>
                    City, State, and ZIP Code are required
                  </div>
              : null}

              <Field
                component={FormikCheckbox}
                wrapperClassName={styles.monthlyBilling}
                id="monthlyInvoicing"
                name="monthlyInvoicing"
                labelText="Monthly Billing"
              />

              <div className={styles.leadSource}>
                <Label htmlFor="leadSourceId">Lead Source</Label>
                <Field
                  component={FormikSelect}
                  className={styles.leadSourceSelect}
                  id="leadSourceId"
                  name="leadSourceId"
                  placeholder="Select a lead source"
                  options={leadSourceOptions}
                  disabled={loadingLeadSources}
                />
              </div>

              <Button
                type="submit"
                className={cx(styles.btn, "btn--block", "btn--large")}
                disabled={isSubmitting}
              >
                Update Billing Information
              </Button>
            </Form>
          )}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  let organizations = []
  let loadingOrgs = true
  let commissionSources = []
  let loadingLeadSources = true

  if (state.data.meta["/organizations"]) {
    organizations = (state.data.meta["/organizations"].data || [])
      .map(object => build(state.data, "organization", object.id))
      loadingOrgs = state.data.meta["/organizations"].loading
  }

  if (state.data.meta["/commission_sources"]) {
    commissionSources = (state.data.meta["/commission_sources"].data || [])
      .map(object => build(state.data, "commissionSource", object.id))
    loadingLeadSources = state.data.meta["/commission_sources"].loading
  }

  // Add default option for "Misc / Accurate" (value = a blank string)
  commissionSources.unshift({
    id: "",
    sales: false,
    name: "Accurate",
    category: "Miscellaneous",
    type: "commissionSource",
    discardedAt: null
  })

  return { organizations, loadingOrgs, commissionSources, loadingLeadSources }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getOrganizations, getCommissionSources }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BillingModal)
